import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'cancel-update-dialog-box.component',
  templateUrl: 'cancel-update-dialog-box.component.html',
  styleUrls: [
    '../confirmation-dialog-box/confirmation-dialog-box.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CancelUpdateDialogBoxComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CancelUpdateDialogBoxComponent>
  ) {}

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
