<header role="banner">
  <div
    tabindex="0"
    aria-labelledby="entete du portail"
    class="app-header header-shadow"
  >
    <div class="app-header__logo">
      <div
        tabindex="0"
        [routerLink]="['/']"
        id="ENTETE-LOGO"
        aria-labelledby="retour à l'accueil"
        class="logo-src"
      >
        <img
          src="../../../../../assets/images/Logo DINUM - HubEE_v2.1.svg"
          alt="logo-portail"
        />
      </div>
      <div class="header__pane ml-auto">
        <div>
          <button
            tabindex="0"
            aria-label="open or close menu"
            tabindex="0"
            role="button"
            aria-label="menu"
            type="button"
            (click)="toggleSidebar()"
            [ngClass]="{ 'is-active': isSidebarOpen }"
            id="ENTETE-BT-MENU"
            class="hamburger close-sidebar-btn hamburger--elastic"
            data-class="closed-sidebar"
            title="Fermer le menu"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button
          aria-label="ouvrir ou fermer le menu"
          role="button"
          aria-label="menu"
          type="button"
          (click)="toggleSidebar()"
          [ngClass]="{ 'is-active': !isSidebarOpen }"
          class="hamburger hamburger--elastic mobile-toggle-nav"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div
      class="app-header__content"
      [ngClass]="
        displayedSearchBar ? 'available_searchbar' : 'unavailable_searchbar'
      "
    >
      <div
        *ngxPermissionsOnly="['ConsultationTD', 'GestionTD', 'AdminTD']"
        class="app-header-left"
      >
        <div [ngClass]="{ active: isSearchBarOpen }" class="search-wrapper">
          <div id="ENTETE-RECHERCHE" class="input-holder">
            <label class="rf-label" for="search-input--lg-input">{{
              "labels.subfolder.header.search.searchLabel" | translate
            }}</label>
            <input
              tabindex="0"
              role="search"
              type="search"
              id="search-input--lg-input"
              (keydown.enter)="onElementSubmitted($event.target.value)"
              class="search-input"
              placeholder="Numéro de dossier ou nom de la personne concernée"
              (ngModelChange)="checkValue($event)"
              [(ngModel)]="searchedSubfolder"
            />
            <button
              aria-label="Rechercher"
              role="button"
              (keydown.enter)="onSearch()"
              (click)="toggleSearchBar()"
              class="search-icon"
              title="Rechercher"
              id="ENTETE-RECHERCHE-BT"
            >
              <span (click)="onSearch()"></span>
            </button>
          </div>
          <button
            tabindex="-1"
            aria-label="fermer la barre de recherche"
            role="button"
            (click)="toggleSearchBar()"
            class="close"
            title="Fermer"
          ></button>
        </div>
      </div>
      <div class="app-header-right user-menu">
        <div class="widget-content p-0">
          <div class="widget-content-wrapper">
            <div class="widget-content-left" *ngIf="givenName">
              <div class="btn-group show">
                <a
                  tabindex="0"
                  aria-label="menu utilisateur"
                  title="Compte utilisateur"
                  (keydown.enter)="menuTrigger.openMenu()"
                  #menuTrigger="matMenuTrigger"
                  [matMenuTriggerFor]="menu"
                  class="p-0 btn"
                  id="ENTETE-BT-GESTION-COMPTE"
                >
                  <i
                    style="margin-top: -7px"
                    mat-button
                    style="margin-top: 7px"
                    class="metismenu-icon fas fa-user-circle"
                  ></i>
                  <i
                    style="margin-top: 7px"
                    mat-button
                    class="fa fa-angle-down"
                  ></i>
                </a>
              </div>
            </div>
            <div class="widget-content-left header-user-info">
              <ng-container *ngIf="givenName">
                <a
                  (keydown.enter)="menuTrigger.openMenu()"
                  #menuTrigger="matMenuTrigger"
                  [matMenuTriggerFor]="menu"
                >
                  <div style="cursor: pointer">
                    <div tabindex="0" class="widget-heading">
                      <span id="ENTETE-PRENOM-USER">{{ firstName }} </span>
                      <span id="ENTETE-NOM-USER">{{ lastName }}</span>
                    </div>
                    <div
                      id="ENTETE-TYPE-USER"
                      tabindex="0"
                      class="widget-subheading user-type"
                    >
                      {{ userType }}
                    </div>
                  </div>
                </a>
              </ng-container>
              <mat-menu tabindex="0" #menu="matMenu">
                <div class="user-infos-menu">
                  <div tabindex="0" class="widget-heading">{{ givenName }}</div>
                  <div tabindex="0" class="widget-subheading user-type">
                    {{ userType }}
                  </div>
                </div>
                <div tabindex="0" role="menu" class="menu-width">
                  <button
                    tabindex="0"
                    aria-label="naviguer vers la gestion de mon compte"
                    role="button"
                    (click)="navigateToMyAccount()"
                    mat-menu-item
                    id="ENTETE-MENU-USER"
                  >
                    {{ "labels.subfolder.header.account.account" | translate }}
                  </button>
                  <!-- <button
                    tabindex="0"
                    aria-label="naviguer vers les préferences de mon compte"
                    role="button"
                    mat-menu-item
                  >
                    {{
                      "labels.subfolder.header.account.preferences" | translate
                    }}
                  </button>
                  <button
                    tabindex="0"
                    aria-label="naviguer vers la gestion de l'accessibilité de mon compte"
                    role="button"
                    mat-menu-item
                  >
                    {{
                      "labels.subfolder.header.account.accessibility"
                        | translate
                    }}
                  </button> -->
                  <button
                    tabindex="0"
                    id="ENTETE-DECONNEXION-USER"
                    aria-label="se deconnecter depuis mon compte"
                    role="button"
                    mat-menu-item
                    (click)="logout()"
                  >
                    {{
                      "labels.subfolder.header.account.disconnection"
                        | translate
                    }}
                  </button>
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
