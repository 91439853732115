import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { User } from 'src/app/shared/models/user';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { UsersService } from '../../services/users.service';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';
import { ActivatedRoute, Router } from '@angular/router';
import {
  admLocDefaultRolesList,
  admMetDefaultRolesList,
  admNatDefaultRolesList,
  agtDefaultRolesList,
  delTecDefaultRolesList,
  UserRole,
  UserRoleLabels,
  userRolesList,
  userRolesListAdminLocal,
  userRolesListMaxBussinessAdmin,
  userRolesListMaxNationalAdmin,
  userRolesListMaxTechDel,
  userRolesListAgent,
} from '../../models/userRole';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { TextService } from '../../../../shared/service/text.service';
import { map, startWith } from 'rxjs/operators';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  UserType,
  userTypeList,
  userTypeListAdministration,
  userTypeListAdministrationForBusinessAdmUser,
  userTypeListForAgtAndAdmLoc,
  userTypeListForTechDel,
} from '../../models/userType';
import { ErrorStateMatcher } from '@angular/material/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Process, ProcessResult } from 'src/app/shared/models/process';
import { Organization } from 'src/app/shared/models/organization';
import { MatDialog } from '@angular/material/dialog';
import { UpdateUserOrganizationDialogBoxComponent } from '../../modals/update-user-organization-dialog-box/update-user-organization-dialog-box.component';
import { TranslateService } from '@ngx-translate/core';
import { UserDeletionConfirmationDialogBoxComponent } from '../../modals/user-deletion-confirmation-dialog-box/user-deletion-confirmation-dialog-box.component';
import { UsersResult } from '../../models/usersResult';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  providers: [TextService],
  encapsulation: ViewEncapsulation.None,
})
export class UserDetailsComponent implements OnInit {
  @ViewChild('processInput') processInput: ElementRef<HTMLInputElement>;
  @ViewChild('roleInput') roleInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('chipListRoles') chipListRoles;
  @ViewChild('chipListProcesses') chipListProcesses;
  idUser = this.route.snapshot.paramMap.get('id');
  user: User;
  updatedUser: User;
  newUser: User = {
    title: undefined,
    firstName: undefined,
    lastName: undefined,
    userID: undefined,
  };
  oldUser: User = {
    title: undefined,
    firstName: undefined,
    lastName: undefined,
    userID: undefined,
    login: undefined,
    email: undefined,
  };
  isUsersAdminPage: boolean;
  isUsersPage: boolean;
  userTypes = userTypeList;
  roles: UserRole[];
  userRoles: string[];
  processPermissions: string[];
  processPermission: string;
  processPermissionFormControl = new FormControl();
  roleFormControl = new FormControl();
  allRoles: string[] = [
    'GestionTD',
    'ConsultationTD',
    'GestionUtilisateurs',
    'ConsultationUtilisateurs',
    'GestionAbonnements',
    'ConsultationAbonnements',
  ];
  selectedTitle = '';
  selectedIndicatif2 = '';
  selectedIndicatif1 = '';
  selectedUserType = '';
  matcher = new ErrorStateMatcher();
  selectable = true;
  removable = true;
  visible = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  rolesLabels: UserRoleLabels[];
  allRolesLabels: string[] = [];
  filteredProcessPermissions: Observable<string[]>;
  filteredRoles: Observable<string[]>;
  hidden = false;
  updateHidden = true;
  updateHiddenRolesSection = true;
  accountStatus = false;
  isDatapassAccountValue: string;
  titleList: string[] = ['M', 'Mme'];
  userTypeList: string[] = [];
  updatedUserForm: FormGroup;
  standardRoles: UserRole[] = [];
  isPersonalInformationsModifying = false;
  isRolesAndAccessModifying = false;
  isRolesListEmpthy = false;
  isProcessesListEmpthy = false;
  isUserAgent: boolean;
  standardProcessList: Process[] = [];
  allProcesses: string[] = [];
  isConnectedUserBusinessAdmin: boolean;
  isOrganizationEmpty: boolean = false;
  editUserEmail: boolean = false;
  updateUserEmailFormGroup: FormGroup;
  oldEmailAddress: string;
  organizationsList: Organization[] = [];
  canUserEditEmail: boolean = false;
  isNotMe: boolean;
  isUpdateEmailLoadingBehaviorSubject: BehaviorSubject<boolean>;
  currentUserLogin: string;
  displayLoader: boolean;
  isAccountActivateLoading: boolean = false;
  isAccountDeleteLoading: boolean = false;
  usersFromOrganization: User[] = [];
  isUserUnlockAccountLoading: boolean;
  isUserAccountLocked: boolean;
  isAdmNat: boolean = false;
  isAdmMet: boolean = false;

  constructor(
    private usersService: UsersService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private textService: TextService,
    private formBuilder: FormBuilder,
    private oauthService: OAuthService,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.organizationsList =
        this.router.getCurrentNavigation().extras.state.DataFromTable;
    }
  }

  ngOnInit(): void {
    this.isAdmNat = this.isAdminNat();
    this.isAdmMet = this.isAdminMet();
    this.editUserEmail = false;
    this.canUserEditEmail = this.isUserAdmNatOrAdmMet();
    this.isUpdateEmailLoadingBehaviorSubject = new BehaviorSubject<boolean>(
      false
    );
    this.initiateForm();
    this.currentUserLogin = this.getCurrentLogin();
    window.scrollTo(0, 0);
    this.checkPageContent();
    this.isUpdateEmailLoading.subscribe((value) => {
      this.displayLoader = value;
      value
        ? this.updateUserEmailFormGroup.get('emailFormControl').disable()
        : this.updateUserEmailFormGroup.get('emailFormControl').enable();
    });
  }

  private isAdminNat(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims && claims.user_type === 'ADM_NAT';
  }

  private isAdminMet(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims && claims.user_type === 'ADM_MET';
  }

  get isUpdateEmailLoading() {
    return this.isUpdateEmailLoadingBehaviorSubject.asObservable();
  }

  setIsUpdateEmailLoading(value: boolean) {
    this.isUpdateEmailLoadingBehaviorSubject.next(value);
  }

  isUserAdmNatOrAdmMet() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims) {
      return claims.user_type === 'ADM_NAT' || claims.user_type === 'ADM_MET';
    }
    return false;
  }

  public async checkIfUserEmailIsUsed(email: string): Promise<boolean> {
    email = email.toLowerCase();
    return await this.usersService
      .getUserEmailUsedStateAdminScreen(email)
      .then((isEmailUsed: boolean) => {
        return isEmailUsed;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public initiateAutocompleteUserRoles(userType: string): void {
    const rolesList = this.getAvailableRoles(userType);
    this.allRolesLabels = rolesList.map((user) => user.name);
    this.filteredRoles = this.roleFormControl.valueChanges.pipe(
      startWith(null),
      map((role: string | null) =>
        role ? this._filterRole(role) : this.allRolesLabels.slice()
      )
    );
    let tempUsertypeList = this.initiateUserTypeListValuesByConnectedUser();
    if (userType === 'DEL_TEC') {
      tempUsertypeList = tempUsertypeList.filter(
        (type) => type.code != 'ADM_LOC'
      );
    } else {
      tempUsertypeList = tempUsertypeList.filter(
        (type) => type.code != 'DEL_TEC'
      );
    }
    this.userTypeList = tempUsertypeList.map((type) => type.name);
  }

  private getDefaultRoleList(userType: string): UserRoleLabels[] {
    let defaultRoles = [];
    if (this.standardRoles) {
      switch (userType) {
        case 'ADM_NAT': {
          defaultRoles = admNatDefaultRolesList;
          break;
        }
        case 'ADM_MET': {
          defaultRoles = admMetDefaultRolesList;
          break;
        }
        case 'ADM_LOC': {
          defaultRoles = admLocDefaultRolesList;
          break;
        }
        case 'DEL_TEC': {
          defaultRoles = delTecDefaultRolesList;
          break;
        }
        case 'AGT': {
          defaultRoles = agtDefaultRolesList;
          break;
        }
        default: {
          defaultRoles = [];
          break;
        }
      }
    }

    return defaultRoles;
  }

  public manageSelectedRolesByUserType(userType: string): void {
    const rolesList = this.getDefaultRoleList(userType);
    this.userRoles = rolesList.map((role) => role.name);
    this.createUserRolesObject();
  }

  public initiateAutocompleteProcesssList(list: Process[]): void {
    this.filteredProcessPermissions =
      this.processPermissionFormControl.valueChanges.pipe(
        startWith(null),
        map((process: string | null) =>
          process ? this._filter(process) : list.map((pr) => pr.name).slice()
        )
      );
  }

  public checkPageContent(): void {
    this.isUsersAdminPage = this.router.url.includes('/users/admin');
    this.isUsersPage = !this.isUsersAdminPage;

    //TODO: return from backend groups only allowed from usertype
    if (this.isUsersAdminPage) {
      this.fetchStandardUsersRolesAdministration();
      this.getUser(this.idUser, true).then(async () => {
        this.fetchProcessesAdmin();
      });
    } else {
      this.fetchStandardUsersRoles();
      this.getUser(this.idUser, false).then(async () => {
        this.fetchProcesses();
      });
    }
  }

  public initiateUserTypeListValuesByConnectedUser(): UserType[] {
    const claims: any = this.oauthService.getIdentityClaims();
    switch (claims.user_type) {
      case 'ADM_NAT': {
        return userTypeListAdministration;
      }
      case 'ADM_MET': {
        return userTypeListAdministrationForBusinessAdmUser;
      }
      case 'ADM_LOC': {
        return userTypeListForAgtAndAdmLoc;
      }
      case 'DEL_TEC': {
        return userTypeListForTechDel;
      }
      case 'AGT': {
        return userTypeListForAgtAndAdmLoc;
      }
      default: {
        return [];
      }
    }
  }

  public initiateForm(): void {
    this.updatedUserForm = new FormGroup({
      firstNameFormControl: new FormControl(this.oldUser.firstName, [
        Validators.required,
      ]),
      userTypeFormControl: new FormControl(this.oldUser.userType, [
        Validators.required,
      ]),
      userRolesFormControl: new FormControl([]),
      lastNameFormControl: new FormControl(this.oldUser.lastName, [
        Validators.required,
      ]),
      titleFormControl: new FormControl(this.oldUser.title, [
        Validators.required,
      ]),
      functionFormControl: new FormControl(this.oldUser.function),
      phoneNumberFormControl: new FormControl(this.oldUser.phoneNumber, [
        Validators.required,
        Validators.pattern('^[0-9]{10,20}$|^\\+[0-9]{10,20}$'),
      ]),
      mobilePhoneNumberFormControl: new FormControl(
        this.oldUser.mobilePhoneNumber,
        [Validators.pattern('^[0-9]{10,20}$|^\\+[0-9]{10,20}$')]
      ),
    });
    this.updatedUserForm.markAllAsTouched();
    this.updateUserEmailFormGroup = new FormGroup({
      emailFormControl: new FormControl(
        { value: this.oldUser.email, disabled: false },
        [Validators.email, Validators.required]
      ),
    });
  }

  public initiateFormValues(): void {
    this.isUserAgent = this.oldUser.userType === 'AGT';
    this.updatedUserForm
      .get('userTypeFormControl')
      .setValue(this.decodeUserTypes(this.oldUser.userType));
    if (this.oldUser.userType === 'DEL_TEC') {
      this.updatedUserForm.get('userTypeFormControl').disable();
    }
    this.updatedUserForm
      .get('firstNameFormControl')
      .setValue(this.oldUser.firstName);
    this.updatedUserForm
      .get('lastNameFormControl')
      .setValue(this.oldUser.lastName);
    this.updatedUserForm.get('titleFormControl').setValue(this.oldUser.title);
    this.updatedUserForm
      .get('functionFormControl')
      .setValue(this.oldUser.function);
    this.updatedUserForm
      .get('phoneNumberFormControl')
      .setValue(this.oldUser.phoneNumber);
    this.updatedUserForm
      .get('mobilePhoneNumberFormControl')
      .setValue(
        this.oldUser.mobilePhoneNumber == null ||
          this.oldUser.mobilePhoneNumber == 'null'
          ? ''
          : this.oldUser.mobilePhoneNumber
      );
    this.updateUserEmailFormGroup
      .get('emailFormControl')
      .setValue(this.oldUser.email);
    if (this.isOrganizationEmpty) {
      this.updatedUserForm.disable();
    }
  }

  public onTitleChanged(event: any): void {
    this.updatedUser.title = this.updatedUserForm.get('titleFormControl').value;
    this.selectedTitle = event.value;
    this.isPersonalInformationsModifying = true;
  }

  public onFirstNameChanged(event: any): void {
    this.updatedUser.firstName = this.updatedUserForm.get(
      'firstNameFormControl'
    ).value;
    this.isPersonalInformationsModifying = true;
  }
  public onLastNameChanged(event: any): void {
    this.updatedUser.lastName = this.updatedUserForm.get(
      'lastNameFormControl'
    ).value;
    this.updatedUser.lastName = this.updatedUser.lastName.toUpperCase();
    this.isPersonalInformationsModifying = true;
  }
  public onFunctionChanged(event: any): void {
    this.updatedUser.function = this.updatedUserForm.get(
      'functionFormControl'
    ).value;
    this.isPersonalInformationsModifying = true;
  }
  public onPhoneNumberChanged(event: any): void {
    this.updatedUser.phoneNumber = this.updatedUserForm.get(
      'phoneNumberFormControl'
    ).value;
    this.isPersonalInformationsModifying = true;
  }
  public onMobileNumberChanged(event: any): void {
    this.updatedUser.mobilePhoneNumber = this.updatedUserForm.get(
      'mobilePhoneNumberFormControl'
    ).value;
    this.isPersonalInformationsModifying = true;
  }

  public onUserTypeChanged(event: any): void {
    this.updatedUser.userType = this.encodeUserTypes(
      this.updatedUserForm.get('userTypeFormControl').value
    );
    if (this.updatedUser.userType !== 'AGT') {
      this.isProcessesListEmpthy = false;
      this.isUserAgent = false;
    } else {
      this.isUserAgent = true;
      this.changeDetectorRef.detectChanges();
      if (this.processPermissions && this.processPermissions.length < 1) {
        this.isProcessesListEmpthy = true;
        this.chipListProcesses.errorState = true;
      }
    }
    this.setNullRoleAndProcessPermissionsInpusField();
    this.cleanRoleAndProcessPermissionsInpusField();
    this.initiateAutocompleteUserRoles(this.updatedUser.userType);
    this.isRolesAndAccessModifying = true;
    this.manageSelectedRolesByUserType(this.updatedUser.userType);
  }

  public openSnackbar(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    this.snackBar.open(message, undefined, config);
  }

  public openSnackbarError(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 4000;
    config.panelClass = ['red-snackbar'];
    this.snackBar.open(message, undefined, config);
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    // Add our process
    if ((value || '').trim()) {
      this.processPermissions.push(value.trim());
      this.userRoles.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.processPermissionFormControl.setValue(null);
    this.roleFormControl.setValue(null);
  }

  /**
   * Removes process permission from UI and list.
   *
   * @param process - Process to remove
   */
  remove(process: string): void {
    const index = this.processPermissions.indexOf(process);
    if (index >= 0) {
      this.processPermissions.splice(index, 1);
    }
    if (this.processPermissions.length < 1) {
      this.isProcessesListEmpthy = true;
      this.chipListProcesses.errorState = true;
    }
    this.isRolesAndAccessModifying = true;
    this.updatedUser.processPermissions = this.encodeProcessPermissions(
      this.processPermissions
    );
  }

  /**
   * Removes role from UI and list.
   *
   * @param role - Role to remove
   */
  removeRole(role: string): void {
    const index = this.userRoles.indexOf(role);
    if (index >= 0) {
      this.userRoles.splice(index, 1);
    }
    if (this.userRoles.length < 1) {
      this.isRolesListEmpthy = true;
      this.chipListRoles.errorState = true;
    }
    this.isRolesAndAccessModifying = true;
    this.createUserRolesObject();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.viewValue !== '') {
      this.isProcessesListEmpthy = false;
      this.chipListProcesses.errorState = false;
      this.isRolesAndAccessModifying = true;
      this.processPermissions.push(event.option.viewValue);
      this.updatedUser.processPermissions = this.encodeProcessPermissions(
        this.processPermissions
      );
      this.setNullRoleAndProcessPermissionsInpusField();
      this.cleanRoleAndProcessPermissionsInpusField();
    }

    this.processInput.nativeElement.blur();
  }

  public setNullRoleAndProcessPermissionsInpusField(): void {
    this.processPermissionFormControl.setValue(null);
    this.roleFormControl.setValue(null);
  }

  public cleanRoleAndProcessPermissionsInpusField(): void {
    if (this.processInput) {
      this.processInput.nativeElement.value = '';
    }
    if (this.roleInput) {
      this.roleInput.nativeElement.value = '';
    }
  }

  selectedRole(event: MatAutocompleteSelectedEvent): void {
    if (event.option.viewValue !== '') {
      this.isRolesListEmpthy = false;
      this.chipListRoles.errorState = false;
      this.isRolesAndAccessModifying = true;
      this.userRoles.push(event.option.viewValue);
      this.setNullRoleAndProcessPermissionsInpusField();
      this.cleanRoleAndProcessPermissionsInpusField();
      this.createUserRolesObject();
    }
    this.roleInput.nativeElement.blur();
  }

  public createUserRolesObject(): void {
    this.updatedUser.userRoles = [];
    let userRolesKeys: string[];
    userRolesKeys = userRolesList
      .filter((role) => this.userRoles.includes(role.name))
      .map((n) => n.code);
    userRolesKeys.forEach((userRole) => {
      this.standardRoles.forEach((standardRole) => {
        if (standardRole.display === userRole) {
          this.updatedUser.userRoles.push(standardRole);
        }
      });
    });
  }

  isEmptyUserRoleList() {
    let res = false;
    if (this.filteredRoles) {
      this.filteredRoles.subscribe((value) => {
        res = value.every((role) => this.userRoles.includes(role));
      });
    }

    return res;
  }

  isEmptyProcessPermissionsList() {
    let res = false;
    if (this.filteredProcessPermissions) {
      this.filteredProcessPermissions.subscribe((value) => {
        res = value.every((role) => this.processPermissions.includes(role));
      });
    }

    return res;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allProcesses.filter((process) =>
      process.toLowerCase().startsWith(filterValue)
    );
  }

  private _filterRole(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allRolesLabels.filter((role) =>
      role.toLowerCase().startsWith(filterValue)
    );
  }

  public async getUser(id: string, admin: boolean): Promise<void | User> {
    let tmpUser: Promise<void | User>;
    if (!admin) {
      tmpUser = this.usersService.getUserById(id);
    } else {
      tmpUser = this.usersService.getUserByIdAdministration(id);
    }
    this.user = await tmpUser
      .then((userResult: User) => {
        if (userResult) {
          this.isUserAgent = userResult.userType === 'AGT';
          this.roles = userResult.userRoles;
          const wantedRoles: string[] = this.roles.map((el) => el.display);
          this.userRoles = userRolesList
            .filter((role) => wantedRoles.includes(role.code))
            .map((n) => n.name);
          this.isRolesListEmpthy = this.userRoles.length < 1;
          this.hidden = userResult.activated;
          this.accountStatus = userResult.activated;
          this.isDatapassAccountValue = userResult.datapassAccount
            ? 'oui'
            : 'non';
          this.isOrganizationEmpty = this.hasValidOrganization(
            userResult.organization
          );
          this.oldUser = userResult;
          this.isNotMe =
            userResult.login && userResult.login !== this.currentUserLogin;
          this.updatedUser = this.oldUser;
          this.isUserAccountLocked = userResult.accountLocked;
          this.oldEmailAddress = userResult.email;
          this.initiateFormValues();
          this.initiateAutocompleteUserRoles(userResult.userType);
          this.initiateUsersFromOrganization();
        }
        return userResult;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public isDefaultRole(role: string): boolean {
    let isDefault = false;
    if (
      !(
        this.user.userType === 'ADM_LOC' &&
        role.toString() === 'Gestion des télédossiers'
      )
    ) {
      this.getDefaultRoleList(this.user.userType).forEach((e) => {
        if (role.toString() === e.name) {
          isDefault = true;
        }
      });
    }
    return isDefault;
  }

  private initiateUsersFromOrganization() {
    const { type, siret, branchCode } = this.oldUser.organization;
    let userFilterUrl = this.usersService.createOrganizationFilterUrl(
      type,
      siret,
      branchCode
    );

    const promise = this.isUsersAdminPage
      ? this.usersService.getUsersByOrganizationAdministration(userFilterUrl)
      : this.usersService.getUsersByOrganization(userFilterUrl);

    promise
      .then((users: UsersResult) => {
        if (users?.users) {
          this.usersFromOrganization = users.users;
        }
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  private getCurrentLogin() {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims ? claims.sub : '';
  }

  private hasValidOrganization(organization: Organization): boolean {
    return (
      [undefined, null, ''].includes(organization.type) ||
      [undefined, null, ''].includes(organization.siret) ||
      [undefined, null, ''].includes(organization.branchCode)
    );
  }

  goBack(): void {
    window.history.back();
  }

  patchUser(activateAccount: boolean) {
    const data = {
      activated: activateAccount,
    };
    if (this.isUsersAdminPage) {
      return this.usersService.patchUserAdminInformations(
        this.user.userID,
        data
      );
    } else {
      return this.usersService.patchUserInformations(this.user.userID, data);
    }
  }

  unlockAccount() {
    if (!this.isUserUnlockAccountLoading) {
      this.updatedUser.accountLocked = false;
      this.isUserUnlockAccountLoading = true;
      this.unlockUserAccount();
    }
  }

  private unlockUserAccount() {
    let tmp: Promise<void | User>;
    if (this.isUsersAdminPage) {
      tmp = this.usersService.unlockUserAdmin(
        this.user.userID,
        this.updatedUser
      );
    } else {
      tmp = this.usersService.unlockUser(this.user.userID, this.updatedUser);
    }
    return tmp
      .then((updatedUser: User) => {
        this.isUserUnlockAccountLoading = false;
        this.openSnackbar(
          this.textService.getText('labels.users.snackbar.accountUpdated')
        );
        this.setNullRoleAndProcessPermissionsInpusField();
        this.cleanRoleAndProcessPermissionsInpusField();
        this.isPersonalInformationsModifying = false;
        this.isRolesAndAccessModifying = false;
        this.ngOnInit();
        return updatedUser;
      })
      .catch((error) => {
        this.isUserUnlockAccountLoading = false;
        this.openSnackbarError(
          this.textService.getText('labels.users.snackbar.errorAccount')
        );
        throw new ApplicationException(error);
      });
  }

  accountActivate(activateAccount: boolean): void {
    this.isAccountActivateLoading = true;
    this.patchUser(activateAccount).subscribe(
      () => {
        if (activateAccount) {
          this.openSnackbar(
            this.textService.getText('labels.users.snackbar.activateAccount')
          );
        } else {
          this.openSnackbar(
            this.textService.getText('labels.users.snackbar.disactivateAccount')
          );
        }
        this.hidden = activateAccount;
        this.accountStatus = activateAccount;
        this.isAccountActivateLoading = false;
      },
      (error) => {
        this.isAccountActivateLoading = false;
        this.openSnackbar(
          this.textService.getText('labels.users.snackbar.errorAccount')
        );
        throw new ApplicationException(error);
      }
    );
  }

  public onCancelUpdating(section: string): void {
    this.isUsersAdminPage
      ? this.restoreUserInfoAdministration(this.user.userID.toString(), section)
      : this.restoreUserInfo(this.user.userID.toString(), section);
  }

  public async restoreUserInfo(id: string, section: string): Promise<void> {
    this.user = await this.usersService
      .getUserById(id)
      .then((userResult: User) => {
        if (userResult) {
          switch (section) {
            case 'PersonalInformations':
              this.isPersonalInformationsModifying = false;
              this.updatedUserForm
                .get('firstNameFormControl')
                .setValue(userResult.firstName);
              this.updatedUserForm
                .get('lastNameFormControl')
                .setValue(userResult.lastName);
              this.updatedUserForm
                .get('titleFormControl')
                .setValue(userResult.title);
              this.updatedUserForm
                .get('functionFormControl')
                .setValue(userResult.function);
              this.updatedUserForm
                .get('phoneNumberFormControl')
                .setValue(userResult.phoneNumber);
              this.updatedUserForm
                .get('mobilePhoneNumberFormControl')
                .setValue(userResult.mobilePhoneNumber);
              break;
            case 'RolesAndAccess':
              this.isRolesAndAccessModifying = false;
              this.updatedUserForm
                .get('userTypeFormControl')
                .setValue(this.decodeUserTypes(userResult.userType));
              this.isUserAgent = userResult.userType === 'AGT';
              this.roles = userResult.userRoles;
              const wantedRoles: string[] = this.roles.map((el) => el.display);
              this.userRoles = userRolesList
                .filter((role) => wantedRoles.includes(role.code))
                .map((n) => n.name);
              this.processPermissions = this.decodeProcessPermissions(
                userResult.processPermissions
              );
              break;
          }
        }
        this.initiateAutocompleteUserRoles(userResult.userType);
        return userResult;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public async restoreUserInfoAdministration(
    id: string,
    section: string
  ): Promise<void> {
    this.user = await this.usersService
      .getUserByIdAdministration(id)
      .then((userResult: User) => {
        if (userResult) {
          switch (section) {
            case 'PersonalInformations':
              this.isPersonalInformationsModifying = false;
              this.updatedUserForm
                .get('firstNameFormControl')
                .setValue(userResult.firstName);
              this.updatedUserForm
                .get('lastNameFormControl')
                .setValue(userResult.lastName);
              this.updatedUserForm
                .get('titleFormControl')
                .setValue(userResult.title);
              this.updatedUserForm
                .get('functionFormControl')
                .setValue(userResult.function);
              this.updatedUserForm
                .get('phoneNumberFormControl')
                .setValue(userResult.phoneNumber);
              this.updatedUserForm
                .get('mobilePhoneNumberFormControl')
                .setValue(userResult.mobilePhoneNumber);
              break;
            case 'RolesAndAccess':
              this.isRolesAndAccessModifying = false;
              this.updatedUserForm
                .get('userTypeFormControl')
                .setValue(this.decodeUserTypes(userResult.userType));
              this.isUserAgent = userResult.userType === 'AGT';
              this.roles = userResult.userRoles;
              const wantedRoles: string[] = this.roles.map((el) => el.display);
              this.userRoles = userRolesList
                .filter((role) => wantedRoles.includes(role.code))
                .map((n) => n.name);
              this.isRolesListEmpthy = this.userRoles.length < 1;
              this.chipListRoles.errorState = this.isRolesListEmpthy;
              this.initiateAutocompleteUserRoles(userResult.userType);
              this.oldUser = userResult;
              this.updatedUser = JSON.parse(JSON.stringify(this.oldUser));
              this.processPermissions = this.decodeProcessPermissions(
                userResult.processPermissions
              );
              break;
          }
        }
        return userResult;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public encodeProcessPermissions(decodedPermissions: string[]): string[] {
    let encodeProcessPermissions = [];
    encodeProcessPermissions = this.standardProcessList
      .filter((process) => decodedPermissions.includes(process.name))
      .map((process) => process.code);
    return encodeProcessPermissions;
  }

  public decodeProcessPermissions(encodedProcesses: string[]): string[] {
    let decodedPermissions = [];
    if (encodedProcesses) {
      decodedPermissions = this.standardProcessList
        .filter((process) => encodedProcesses.includes(process.code))
        .map((process) => process.name);
    }

    return decodedPermissions;
  }

  public decodeUserTypes(userType): string {
    let decodedValues = '';
    this.userTypes.forEach((type) => {
      if (userType === type.code) {
        decodedValues = type.name;
      }
    });
    return decodedValues;
  }

  public encodeUserTypes(userType): string {
    let encodedValues = '';
    this.userTypes.forEach((type) => {
      if (userType === type.name) {
        encodedValues = type.code;
      }
    });
    return encodedValues;
  }

  public async fetchProcesses(): Promise<void> {
    await this.usersService
      .getProcesses(
        this.user.organization.type,
        this.user.organization.siret,
        this.user.organization.branchCode
      )
      .then((processes: Process[]) => {
        this.standardProcessList = processes;
        this.allProcesses = [];
        this.standardProcessList.forEach((process) => {
          this.allProcesses.push(process.name);
        });
        if (this.user) {
          this.processPermissions = this.decodeProcessPermissions(
            this.user.processPermissions
          );
        }
        this.initiateAutocompleteProcesssList(this.standardProcessList);
      });
  }

  public fetchProcessesAdmin(): void {
    this.usersService
      .getProcessesAdmin(
        this.user.organization.type,
        this.user.organization.siret,
        this.user.organization.branchCode
      )
      .subscribe((response: ProcessResult) => {
        this.standardProcessList = response.processes;
        this.allProcesses = [];
        response.processes.forEach((process) => {
          this.allProcesses.push(process.name);
        });
        if (this.user) {
          this.processPermissions = this.decodeProcessPermissions(
            this.user.processPermissions
          );
        }
        this.initiateAutocompleteProcesssList(response.processes);
      });
  }

  public async updateUserInformations(): Promise<User> {
    this.updatedUser.activated = this.accountStatus;

    this.processPermissionFormControl.setValue(null);
    if (this.updatedUser.userType !== 'AGT') {
      this.updatedUser.processPermissions = null;
    }

    let tmp: Promise<void | User>;
    if (this.isUsersAdminPage) {
      tmp = this.usersService.updateUserInformationsAdministration(
        this.user.userID,
        this.updatedUser
      );
    } else {
      tmp = this.usersService.updateUserInformations(
        this.user.userID,
        this.updatedUser
      );
    }
    return tmp
      .then((updatedUser: User) => {
        this.isUserUnlockAccountLoading = false;
        this.openSnackbar(
          this.textService.getText('labels.users.snackbar.accountUpdated')
        );
        this.setNullRoleAndProcessPermissionsInpusField();
        this.cleanRoleAndProcessPermissionsInpusField();
        this.isPersonalInformationsModifying = false;
        this.isRolesAndAccessModifying = false;
        this.ngOnInit();
        return updatedUser;
      })
      .catch((error) => {
        this.isUserUnlockAccountLoading = false;
        this.openSnackbarError(
          this.textService.getText('labels.users.snackbar.errorAccount')
        );
        throw new ApplicationException(error);
      });
  }

  public getRolesDefaultList(rolesList: UserRoleLabels[]): UserRole[] {
    const defaultRoles = [];
    const userRolesKeys = rolesList.map((n) => n.code);
    userRolesKeys.forEach((userRole) => {
      this.standardRoles.forEach((standardRole) => {
        if (standardRole.display === userRole) {
          defaultRoles.push(standardRole);
        }
      });
    });
    return defaultRoles;
  }

  public getAvailableRoles(userType: string): UserRoleLabels[] {
    if (this.standardRoles) {
      switch (userType) {
        case 'ADM_NAT': {
          return userRolesListMaxNationalAdmin;
        }
        case 'ADM_MET': {
          return userRolesListMaxBussinessAdmin;
        }
        case 'ADM_LOC': {
          return userRolesListAdminLocal;
        }
        case 'DEL_TEC': {
          return userRolesListMaxTechDel;
        }
        case 'AGT': {
          return userRolesListAgent;
        }
        default: {
          return [];
        }
      }
    }
  }

  public async fetchStandardUsersRoles(): Promise<void> {
    await this.usersService
      .getUsersRoles()
      .then((roles: UserRole[]) => {
        this.standardRoles = roles;
        return roles;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public async fetchStandardUsersRolesAdministration(): Promise<void> {
    await this.usersService
      .getUsersRolesAdministration()
      .then((roles: UserRole[]) => {
        this.standardRoles = roles;
        return roles;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public openUpdateUserOrganization() {
    const dialogRef = this.dialog.open(
      UpdateUserOrganizationDialogBoxComponent,
      {
        data: {
          currentOrganization: this.user.organization,
          currentTypeUser: this.user.userType,
          organizationsList: this.organizationsList,
        },
      }
    );

    dialogRef.afterClosed().subscribe((res) => {
      /* La dialog box renvoie 'cancel' si l'utilisateur
       * a cliqué sur le bouton Annuler, undefined si l'utilisateur
       * a cliqué en dehors de la boîte de dialog, ou l'organisation qu'il
       * a choisi s'il a clique sur Enregistrer les modifications
       */
      if (res != undefined && res !== 'cancel') {
        this.updatedUser.organization = res;
        this.updateUserInformations();
      }
    });
  }

  public updateUserEmail() {
    if (
      this.updateUserEmailFormGroup.valid &&
      this.updateUserEmailFormGroup.get('emailFormControl').value !=
        this.updatedUser.email
    ) {
      this.setIsUpdateEmailLoading(true);
      this.checkIfUserEmailIsUsed(
        this.updateUserEmailFormGroup.get('emailFormControl').value
      )
        .then((isEmailUsed) => {
          if (isEmailUsed) {
            this.setIsUpdateEmailLoading(false);
            this.updateUserEmailFormGroup
              .get('emailFormControl')
              .setErrors({ alreadyExists: true });
          } else {
            this.updateUserEmailFormGroup
              .get('emailFormControl')
              .setErrors({ alreadyExists: null });
            this.updateUserLogin();
          }
        })
        .catch((error) => {
          this.setIsUpdateEmailLoading(false);
          throw new ApplicationException(error);
        });
    }
  }

  public resetFieldUserEmail() {
    this.updateUserEmailFormGroup
      .get('emailFormControl')
      .setValue(this.oldUser.email);
    this.editUserEmail = false;
  }

  public updateUserLogin() {
    this.deleteUserPromise(false)
      .then(() => {
        this.createUser();
      })
      .catch((error) => {
        this.openSnackbar(
          this.textService.getText('labels.users.snackbar.errorMail')
        );
        this.ngOnInit();
        throw new ApplicationException(error);
      });
  }

  public deleteUserPromise(sendMail: boolean): Promise<void | User> {
    const user: User = Object.assign({}, this.oldUser);
    user.email = this.oldEmailAddress;
    return this.isUsersAdminPage
      ? this.usersService.deleteUserAdmin(user.userID, sendMail)
      : this.usersService.deleteUser(user.userID, sendMail);
  }

  public deleteUser() {
    const dialogRef = this.dialog.open(
      UserDeletionConfirmationDialogBoxComponent,
      {
        autoFocus: false,
        disableClose: true,
        data: {
          username: this.oldUser.login,
        },
      }
    );

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data === 'confirm') {
        this.isAccountDeleteLoading = true;
        this.deleteUserPromise(true)
          .then(() => {
            this.isAccountDeleteLoading = false;
            this.router.navigate(
              this.isUsersAdminPage ? ['/users/admin'] : ['/users']
            );
            this.openSnackbar(
              this.textService.getTextWithArgs(
                'labels.users.snackbar.userDeleteSuccess',
                { username: this.oldEmailAddress }
              )
            );
          })
          .catch((err) => {
            this.isAccountDeleteLoading = false;
            this.openSnackbar(
              this.textService.getText('labels.users.snackbar.errorDeleteUser')
            );
            this.ngOnInit();
            throw new ApplicationException(err);
          });
      }
    });
  }

  /**
   * Returns true if :
   *  - the user has the role GestionUtilisateurs or AdminUtilisateurs and
   *  - the user is not the current user and
   *  - there is at least 2 users of type ADM_LOC in the organization of the user beeing deleted
   */
  public canDeleteUser(): boolean {
    return (
      this.hasGestionUtilisateurOrAdminUtilisateurs() &&
      this.isNotMe &&
      this.isMoreThanOneAdmLoc()
    );
  }

  public isMoreThanOneAdmLoc(): boolean {
    // Pas besoin de tester s'il existe un autre ADM_LOC
    if (this.oldUser.userType != 'ADM_LOC') {
      return true;
    }

    return (
      this.usersFromOrganization.filter((user) => user.userType == 'ADM_LOC')
        .length > 1
    );
  }

  public hasGestionUtilisateurOrAdminUtilisateurs(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();
    if (!claims) {
      return false;
    }
    return (
      claims.groups.indexOf('GestionUtilisateurs') !== -1 ||
      claims.groups.indexOf('AdminUtilisateurs') !== -1
    );
  }

  public createUser() {
    const user: User = Object.assign({}, this.oldUser);
    user.email = this.updateUserEmailFormGroup
      .get('emailFormControl')
      .value.toLowerCase();
    user.login = this.updateUserEmailFormGroup
      .get('emailFormControl')
      .value.toLowerCase();

    user.activated = true;
    user.accountLocked = false;

    let tmp: Promise<void | User> = this.isUsersAdminPage
      ? this.usersService.createNewUserAdministration(user)
      : this.usersService.createNewUser(user);
    return tmp
      .then((newUser: User) => {
        this.openSnackbar(
          this.textService.getText('labels.users.snackbar.accountUpdated')
        );
        let redirectUrl = this.isUsersAdminPage ? '/users/admin' : '/users';
        redirectUrl += '/' + newUser.userID;
        this.router.navigate([redirectUrl]);
        this.idUser = newUser.userID.toString();
        this.ngOnInit();
        return newUser;
      })
      .catch((error) => {
        this.setIsUpdateEmailLoading(false);
        this.ngOnInit();
        throw new ApplicationException(error);
      });
  }

  public onUserEmailChanged(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.updateUserEmail();
    }
  }
}
