<div tabindex="0" aria-labelledby="boxTitle" class="modal-content-user-admin">
  <div class="header-section">
    <div class="header">
      <i id="ADMIN-BD-ADD-USER-ICO" class="fas fa-user-plus"></i>
      <h5 mat-dialog-title>
        <div id="ADMIN-BD-ADD-USER-TITRE" class="title">
          {{ "labels.users.userDetails.title" | translate }}
        </div>
      </h5>
    </div>
    <div class="close-modal">
      <i
        id="ADMIN-BD-ADD-USER-BT-CLOSE"
        tabindex="-1"
        aria-label="fermer la fenetre"
        mat-dialog-close="cancel"
        class="fas fa-times"
      ></i>
    </div>
  </div>
  <div class="form-content">
    <form [formGroup]="newUserForm" (ngSubmit)="onSubmit()" class="new-user">
      <mat-dialog-content>
        <div class="type-group">
          <mat-form-field
            id="ADMIN-BD-ADD-USER-TYPE-ACTOR"
            class="user-type"
            appearance="fill"
          >
            <mat-label aria-label="choisir le type d'acteur">{{
              "labels.users.userDetails.userType" | translate
            }}</mat-label>
            <mat-select
              formControlName="userTypeFormControl"
              (selectionChange)="onUserTypeSelected($event)"
              [errorStateMatcher]="matcher"
            >
              <mat-option
                *ngFor="let userType of userTypeList"
                [selected]="userType === 'Agent'"
                [value]="userType"
                >{{ userType }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                newUserForm.get('userTypeFormControl').hasError('required')
              "
            >
              {{ "labels.users.addNew.requiredUserType" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="type-group">
          <mat-form-field
            id="admin-bd-add-user-orga"
            class="organizations-full-width"
          >
            <mat-label>{{
              "labels.users.addNew.organization" | translate
            }}</mat-label>
            <input
              id="ADMIN-BD-ADD-USER-ORGA"
              class="org-input"
              type="text"
              matInput
              [formControl]="organizationFormControl"
              [matAutocomplete]="auto"
            />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-error *ngIf="organizationFormControl.hasError('required')">
              {{ "labels.users.userDetails.required" | translate }}
            </mat-error>
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayFn"
              (optionSelected)="onOrganizationSelected($event.option.value)"
            >
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
                matTooltip="{{ option.branchCode + ' - ' + option.name }}"
                [matTooltipShowDelay]="300"
                matTooltipClass="organization-name-add-user-admin-tooltip"
                matTooltipPosition="after"
              >
                {{ option.branchCode }} - {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div
          *ngIf="newUserForm.get('userTypeFormControl').value === 'Agent'"
          class="process-permission-field"
        >
          <mat-form-field
            id="ADMIN-BD-ADD-USER-HABILITATIONS"
            class="process-permission-chip-list"
          >
            <mat-label aria-label="choisir les habilitations aux démarches">{{
              "labels.users.addNew.processPermission" | translate
            }}</mat-label>
            <mat-chip-list
              #chipList
              aria-label="La selection des habilitations"
            >
              <mat-chip
                *ngFor="let process of processesPermissions"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(process)"
              >
                {{ process }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                id="ADMIN-BD-ADD-USER-HABILITATIONS"
                placeholder="{{
                  'labels.users.addNew.processPermisionPlaceholder' | translate
                }}"
                #processInput
                [formControl]="processPermissionFormControl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              />
            </mat-chip-list>
            <mat-error>
              {{ "labels.users.userDetails.required" | translate }}
            </mat-error>
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selected($event)"
            >
              <mat-option *ngIf="isEmptyProcessPermissionsList()"></mat-option>
              <ng-container *ngFor="let process of userAllowedProcess">
                <mat-option
                  *ngIf="
                    isOrganizationChosen &&
                    !processesPermissions.includes(process.name)
                  "
                  [value]="process"
                >
                  {{ process.name }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="email">
          <mat-form-field
            id="ADMIN-BD-ADD-USER-EMAIL"
            class="email-field"
            appearance="outline"
          >
            <mat-label aria-label="entrez l'adresse d'email">{{
              "labels.users.userDetails.email" | translate
            }}</mat-label>
            <input
              id="ADMIN-BD-ADD-USER-EMAIL"
              matInput
              placeholder="Placeholder"
              name="email"
              type="email"
              formControlName="emailFormControl"
              [errorStateMatcher]="matcher"
              (focusout)="checkIfUserEmailIsUsed()"
            />
            <mat-icon matSuffix>alternate_email</mat-icon>
            <mat-error
              *ngIf="
                newUserForm.get('emailFormControl').hasError('email') &&
                !newUserForm.get('emailFormControl').hasError('required')
              "
            >
              {{ "labels.users.addNew.validEmail" | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                newUserForm.get('emailFormControl').hasError('isAlreadyUsed')
              "
            >
              {{ "labels.users.addNew.addUserExists" | translate }}
            </mat-error>
            <mat-error
              *ngIf="newUserForm.get('emailFormControl').hasError('required')"
            >
              {{ "labels.users.addNew.requiredEmail" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="civility-infos">
          <mat-form-field
            id="ADMIN-BD-ADD-USER-CIVILITE"
            class="title-field"
            appearance="fill"
          >
            <mat-label aria-label="choisissez la civilité">{{
              "labels.users.userDetails.civility" | translate
            }}</mat-label>
            <mat-select
              formControlName="titleFormControl"
              (selectionChange)="onTitleSelected($event)"
              [errorStateMatcher]="matcher"
            >
              <mat-option *ngFor="let title of titleList" [value]="title">{{
                title
              }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                newUserForm.get('userTypeFormControl').hasError('required')
              "
            >
              {{ "labels.users.addNew.requiredTitle" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            id="ADMIN-BD-ADD-USER-FIRSTNAME"
            class="first-name"
            appearance="outline"
          >
            <mat-label aria-label="entrez le prénom">{{
              "labels.users.userDetails.firstName" | translate
            }}</mat-label>
            <input
              id="ADMIN-BD-ADD-USER-FIRSTNAME"
              matInput
              placeholder="Placeholder"
              name="firstName"
              type="text"
              formControlName="firstNameFormControl"
              [errorStateMatcher]="matcher"
            />
            <mat-error
              *ngIf="
                newUserForm.get('firstNameFormControl').hasError('required')
              "
            >
              {{ "labels.users.addNew.requiredFirstName" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            id="ADMIN-BD-ADD-USER-LASTNAME"
            class="last-name"
            appearance="outline"
          >
            <mat-label aria-label="entrez le nom">{{
              "labels.users.userDetails.lastName" | translate
            }}</mat-label>
            <input
              id="ADMIN-BD-ADD-USER-LASTNAME"
              matInput
              placeholder="Placeholder"
              name="lastName"
              type="text"
              formControlName="lastNameFormControl"
              [errorStateMatcher]="matcher"
            />
            <mat-error
              *ngIf="
                newUserForm.get('lastNameFormControl').hasError('required')
              "
            >
              {{ "labels.users.addNew.requiredLastName" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="function">
          <mat-form-field
            id="ADMIN-BD-ADD-USER-FONCTION"
            class="function-field"
            appearance="outline"
          >
            <mat-label aria-label="entrez le fonction">{{
              "labels.users.userDetails.function" | translate
            }}</mat-label>
            <input
              id="ADMIN-BD-ADD-USER-FONCTION"
              formControlName="functionFormControl"
              matInput
              placeholder="Placeholder"
              name="function"
            />
          </mat-form-field>
        </div>
        <div class="phone-details">
          <mat-form-field
            id="ADMIN-BD-ADD-USER-TEL-PRINCIPALE"
            class="phone-field"
            appearance="outline"
          >
            <mat-label aria-label="entrez le numéro du téléphone principal'">{{
              "labels.users.userDetails.phoneNumber" | translate
            }}</mat-label>
            <input
              id="ADMIN-BD-ADD-USER-TEL-PRINCIPALE"
              matInput
              placeholder="Placeholder"
              name="phoneNumber"
              type="tel"
              formControlName="phoneNumberFormControl"
              [errorStateMatcher]="matcher"
            />
            <mat-icon matSuffix>phone</mat-icon>
            <mat-error
              *ngIf="
                newUserForm.get('phoneNumberFormControl').hasError('required')
              "
            >
              {{ "labels.users.addNew.requiredPhoneNumber" | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                !newUserForm
                  .get('phoneNumberFormControl')
                  .hasError('required') &&
                newUserForm.get('phoneNumberFormControl').hasError('pattern')
              "
            >
              {{ "labels.users.addNew.validPhoneNumber" | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            id="ADMIN-BD-ADD-USER-TEL-SECOND"
            class="phone-field"
            appearance="outline"
          >
            <mat-label aria-label="entrez le numéro du téléphone secondaire'">{{
              "labels.users.userDetails.mobilePhoneNumber" | translate
            }}</mat-label>
            <input
              id="ADMIN-BD-ADD-USER-TEL-SECOND"
              matInput
              placeholder="Placeholder"
              name="mobilePhoneNumber"
              type="tel"
              formControlName="mobilePhoneNumberFormControl"
              [errorStateMatcher]="matcher"
            />
            <mat-icon matSuffix>phone</mat-icon>
            <mat-error
              *ngIf="
                newUserForm
                  .get('mobilePhoneNumberFormControl')
                  .hasError('pattern')
              "
            >
              {{ "labels.users.addNew.validPhoneNumber" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="buttons">
          <button
            id="ADMIN-BD-ADD-USER-BT-ANNULER"
            aria-label="annuler la creation du compte"
            mat-dialog-close="cancel"
            mat-flat-button
            class="cancel-button"
          >
            {{ "labels.users.userDetails.cancelButton" | translate }}
          </button>
          <button
            id="ADMIN-BD-ADD-USER-BT-AJOUTER"
            aria-label="valider la creation du compte"
            type="submit"
            [disabled]="
              !newUserForm.valid ||
              isProcessesListEmpty ||
              !isOrganizationChosen
            "
            mat-flat-button
            class="confirm-button"
            (click)="dialogRef.close(newUser)"
          >
            {{ "labels.users.userDetails.confirmButton" | translate }}
          </button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
