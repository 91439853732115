export enum SearchOperation {
  CONTAINS = 'cn',
  NOT_CONTAINS = 'nc',
  EQUAL = 'eq',
  NOT_EQUAL = 'ne',
  STARTS_WITH = 'sw',
  NOT_STARTS_WITH = 'sn',
  ENDS_WITH = 'ew',
  NOT_ENDS_WITH = 'en',
  NULL = 'nu',
  NOT_NULL = 'nn',
  GREATER_THAN = 'gt',
  GREATER_THAN_EQUAL = 'ge',
  LESS_THAN = 'lt',
  LESS_THAN_EQUAL = 'le',
  IN = 'in',
  NOT_IN = 'ni',
  ANY = 'any',
  ALL = 'all',
}

export class SearchCriteria {
  filterKey: string;
  value: any;
  operation: SearchOperation;
}

export class SearchDto {
  searchCriteriaList: SearchCriteria[];
  dataOption: string;
}
