import {
  Component,
  Inject,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
  Input,
  OnChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CancelUpdateDialogBoxComponent } from 'src/app/features/subfolders/modals/cancel-update-dialog-box/cancel-update-dialog-box.component';
import {
  Organization,
  OrganizationsResponse,
} from 'src/app/shared/models/organization';
import { UsersService } from '../../services/users.service';
import {
  SearchCriteria,
  SearchDto,
  SearchOperation,
} from 'src/app/shared/models/search';
import { OrganizationAdminHttpService } from 'src/app/features/organization-admin/services/organizationAdminHttp.service';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-update-user-organization-dialog-box',
  templateUrl: './update-user-organization-dialog-box.component.html',
  styleUrls: ['./update-user-organization-dialog-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateUserOrganizationDialogBoxComponent
  implements OnInit, OnChanges
{
  organizationsList: Organization[] = [];
  selectedOrganization: Organization;
  updatedUserOrganizationForm: FormGroup;
  @Input() isUsersPage: boolean;
  @Input() isUsersAdminPage: boolean;
  filteredOptions: Observable<any[]>;
  userOrganizationFormControl = new FormControl();
  userType: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CancelUpdateDialogBoxComponent>,
    public userService: UsersService,
    private translate: TranslateService,
    private router: Router,
    private organizationService: OrganizationAdminHttpService
  ) {}

  ngOnInit(): void {
    this.userType = this.data.currentTypeUser;
    this.initForm();
    this.checkPageContent();
    this.initiateOrganizationsInFilter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organizationsList) {
      this.initiateOrganizationsInFilter();
    }
  }

  public checkPageContent(): void {
    this.isUsersAdminPage = this.router.url.includes('/users/admin');
    this.isUsersPage = !this.isUsersAdminPage;
  }

  initForm() {
    this.updatedUserOrganizationForm = new FormGroup({
      userOrganizationFormControl: new FormControl(
        {
          disabled: false,
          value: this.getOrganizationId(this.data.currentOrganization),
        },
        [Validators.required]
      ),
    });
    this.userOrganizationFormControl.setValue(this.data.currentOrganization);
  }

  /**
   * Renvoie l'id d'une organisation
   */
  getOrganizationId(organization: Organization): string {
    return this.isValidOrganization(organization)
      ? organization.type +
          '-' +
          organization.siret +
          '-' +
          organization.branchCode
      : 'Aucune';
  }

  isValidOrganization(organization: Organization): boolean {
    return (
      ![undefined, null].includes(organization) &&
      ![undefined, null].includes(organization.type) &&
      ![undefined, null].includes(organization.siret) &&
      ![undefined, null].includes(organization.branchCode)
    );
  }

  onUserOrganizationChanged(event: any) {
    var organization = event.option.value;
    this.selectedOrganization = organization;
    this.initiateOrganizationsInFilter();
  }

  public submitForm() {
    this.dialogRef.close(this.selectedOrganization);
  }

  public initiateOrganizationsInFilter(): void {
    const orgType = this.getOrganizationTypeByUserType(this.userType);

    this.filteredOptions = this.userOrganizationFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      map((value) => (typeof value === 'string' ? value : value.name)),
      switchMap((value: string) => {
        const criterias: SearchCriteria[] = [
          {
            filterKey: 'name',
            operation: SearchOperation.CONTAINS,
            value: value,
          },
          {
            filterKey: 'type',
            operation: SearchOperation.EQUAL,
            value: orgType,
          },
        ];

        const searchDto: SearchDto = {
          searchCriteriaList: criterias,
          dataOption: 'all',
        };

        return this.organizationService.search(searchDto, environment.maxResult);
      }),
      map((value) => value.organizations)
    );
  }

  displayFn(value) {
    if (typeof value === 'string') {
      return value;
    }
    return ![null, undefined, ''].includes(value.branchCode) &&
      ![null, undefined, ''].includes(value.siret) &&
      ![null, undefined, ''].includes(value.name)
      ? value.branchCode + ' - ' + value.name
      : 'Aucune';
  }

  public getOrganizationTypeByUserType(userType: string): string {
    switch (userType) {
      case 'ADM_NAT':
      case 'Administrateur national':
        return 'ADM_NAT';
      case 'ADM_MET':
      case 'Administrateur métier':
        return 'OSL';
      case 'AGT':
      case 'Agent':
      case 'ADM_LOC':
      case 'Administrateur local':
        return 'SI';
      case 'DEL_TEC':
      case 'Délégué technique':
        return 'EDT';
      default:
        return '';
    }
  }
}
