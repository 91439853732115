import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { userRolesList } from 'src/app/features/users/models/userRole';
import { userTypeList } from 'src/app/features/users/models/userType';

@Injectable()
export class TextService {
  userTypes = userTypeList;
  userRoles = userRolesList;

  constructor(public translateService: TranslateService) {}

  getText(textToGet: string): string {
    return this.getTextWithArgs(textToGet, null);
  }

  getTextWithArgs(textToGet: string, arg: Object): string {
    let text = textToGet;
    this.translateService.get(textToGet, arg).subscribe((value) => {
      text = value;
    });
    return text;
  }

  decodeUserType(code: string): string {
    let name = '';
    this.userTypes.forEach((userType) => {
      if (code === userType.code) {
        name = userType.name;
      }
    });
    return name;
  }

  encodeUserType(name: string): string {
    let code = '';
    this.userTypes.forEach((userType) => {
      if (name === userType.name) {
        code = userType.code;
      }
    });
    return code;
  }

  encodeUserRole(userRolesToEncode: string[]): string[] {
    const encodedUserRoles = [];
    userRolesToEncode.forEach((userRole) => {
      this.userRoles.forEach((role) => {
        if (userRole === role.name) {
          encodedUserRoles.push(role.code);
        }
      });
    });
    return encodedUserRoles;
  }

  decodeUserRole(userRolesToDecode: string[]): string[] {
    const decodedUserRoles = [];
    userRolesToDecode.forEach((userRole) => {
      this.userRoles.forEach((role) => {
        if (userRole === role.code) {
          decodedUserRoles.push(role.name);
        }
      });
    });
    return decodedUserRoles;
  }

  convertBooleanInText(value: boolean): string {
    let stringValue = '';
    switch (value) {
      case true:
        stringValue = 'Oui';
        break;
      case false:
        stringValue = 'Non';
        break;
      default:
        stringValue = 'NaN';
        break;
    }
    return stringValue;
  }
}
