import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextService } from 'src/app/shared/service/text.service';

@Component({
  selector: 'app-user-deletion-confirmation-dialog-box',
  templateUrl: './user-deletion-confirmation-dialog-box.component.html',
  styleUrls: ['./user-deletion-confirmation-dialog-box.component.scss'],
})
export class UserDeletionConfirmationDialogBoxComponent implements OnInit {
  dialogBoxMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private textService: TextService
  ) {}

  ngOnInit(): void {
    this.dialogBoxMessage = this.textService.getTextWithArgs(
      'labels.users.deleteUserDialogBox.title',
      { username: this.data.username }
    );
  }
}
