<div
  tabindex="0"
  role="alert"
  aria-labelledby="box-title"
  class="session-timeout-box-content"
>
  <div tabindex="0" aria-label="session expiré" class="session-timeout-message">
    <div tabindex="0" class="message">
      {{ "labels.session.timeout.message" | translate }}
    </div>
  </div>
  <button
    id="button-close-dialog"
    role="button"
    class="close-button"
    mat-button
    mat-dialog-close="cancel"
    (click)="close()"
  >
    {{ "labels.session.timeout.closeButton" | translate }}
  </button>
</div>
