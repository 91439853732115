/**
 * Erreur Application.
 * Attention celle-ci est accessible via error.originalError de l'interface Error.
 */
export class ApplicationException extends Error {
  /** Status de l'erreur. */
  private status: string;
  /** Donnée de l'erreur */
  private data: any;
  /** Code de l'erreur */
  private code: string;
  /**
   * Constructeur
   * @param msg de la request
   * @param  status de la request
   * @param code à passer
   */
  constructor(msg?: string, status?: string, data?: any, code?: string) {
    super(msg);
    this.name = ApplicationException.name;
    Object.setPrototypeOf(this, ApplicationException.prototype);
    this.status = status;
    this.data = data;
    this.code = code;
  }
}
