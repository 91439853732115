export class UsersFilters {
  pageNumber?: number;
  pageSize?: number;
  searched?: string;
  sortAttribute?: string;
  direction?: string;
  processPermission?: string;
  userRoles?: string;
  userType?: string;
  siret?: string;
  branchCode?: string;
  type?: string;
}
