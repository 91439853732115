<div
  tabindex="0"
  role="alert"
  aria-labelledby="box-title"
  class="confirmation-dialog-box-content"
>
  <div class="title-area" mat-dialog-title>
    <div class="title-right-side">
      <div>
        <i class="fas fa-exclamation-triangle"></i>
      </div>
      <h5>
        <div id="box-title" class="title">{{ data.cancelTitle }}</div>
      </h5>
    </div>
    <i
      tabindex="-1"
      aria-label="annuler la mise à jour"
      role="button"
      class="fas fa-times"
      mat-dialog-close="cancel"
    ></i>
  </div>
  <mat-dialog-actions>
    <button
      aria-label="annuler la mise à jour"
      role="button"
      class="cancel-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="cancel"
      (click)="cancel()"
    >
      {{ data.cancelButton }}
    </button>
    <button
      aria-label="confirmer la mise à jour"
      role="button"
      class="confirm-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="confirm"
      (click)="confirm()"

    >
      {{ data.confirmCancelButton }}
    </button>
  </mat-dialog-actions>
</div>
