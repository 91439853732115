import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';
import { Organization } from 'src/app/shared/models/organization';
import { Process } from 'src/app/shared/models/process';
import { User } from 'src/app/shared/models/user';
import { TextService } from 'src/app/shared/service/text.service';
import { UsersFilters } from '../../models/usersFilters';
import { UsersResult } from '../../models/usersResult';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
  providers: [TextService],
})
export class UsersPageComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filters: UsersFilters = new UsersFilters();
  usersResult: any;
  users: User[] = [];
  dataSource: any;
  processList: Process[] = [];
  userTypesList: string[] = [];
  userRolesList: string[] = [];
  usersWithCodesDatas: any;
  url: string;
  hasResults: boolean;
  processes: Process[] = [];
  isEmailUsed: boolean;
  isUsersAdminPage: boolean;
  isUsersPage: boolean;
  connectedUserUserType: string;
  isLoading = true;
  isUserResultTableReady = false;
  isProcessListReady = false;
  shouldDisplayUsersTable = false;
  displayPaginationMessage: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private textService: TextService,
    public snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private oauthService: OAuthService
  ) {
    const layoutChanges = this.breakpointObserver.observe([
      '(orientation: portrait)',
      '(max-width: 767px)',
    ]);

    layoutChanges.subscribe((result) => {
      if (result.breakpoints['(max-width: 767px)']) {
        this.switchToLandscapePage();
      }
    });
  }

  ngOnInit(): void {
    this.fetchProcesses();
    this.getResultsByQueryParams();
    this.checkPageContent();
    const claims: any = this.oauthService.getIdentityClaims();
    this.connectedUserUserType = claims.user_type;
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
    this.checkPageContent();
  }

  public checkPageContent(): void {
    this.isUsersAdminPage = this.router.url.includes('/users/admin');
    this.isUsersPage = !this.isUsersAdminPage;
  }

  getResultsByQueryParams(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.url = '';
      this.filters = this.usersService.getFilters(params);
      this.url = this.createUrlStringFromFilters(this.filters, this.url);
      this.fetchUsersByFilters(this.url);
    });
  }

  openSnackbar(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    this.snackBar.open(message, undefined, config);
  }

  public createUrlStringFromFilters(
    filters: UsersFilters,
    url: string
  ): string {
    if (url) {
      url += '&';
    }
    Object.keys(filters).forEach((el) => {
      if (filters[el]) {
        url += `&${el}=${filters[el]}`;
        url = this.treatUrlString(url);
      }
    });
    return url;
  }

  public treatUrlString(url: string): string {
    const regex = /[\[\]']|"/gm;
    const subst = ``;
    url = url.replace(regex, subst);
    return url;
  }

  public getProcessesFromQuery(processes: string): string {
    let processesUrlText = '';
    const selectedItemsArray = JSON.parse(processes);
    selectedItemsArray.forEach((process) => {
      process = encodeURI(process);
      processesUrlText += '&processPermission=' + process;
    });
    return processesUrlText;
  }

  public getUserRolesFromQuery(userRoles: string): string {
    let userRolesUrlText = '&userRoles=';
    const selectedItemsArray = JSON.parse(userRoles);
    selectedItemsArray.forEach((role) => {
      role = encodeURI(role);
      userRolesUrlText += role;
    });
    return userRolesUrlText;
  }

  private countFilter(): number {
    let count = 0;
    if (
      this.filters.processPermission != null &&
      this.filters.processPermission != ''
    ) {
      count++;
    }
    if (this.filters.userRoles != null && this.filters.userRoles != '') {
      count++;
    }
    if (this.filters.searched != null && this.filters.searched != '') {
      count++;
    }
    if (this.filters.userType != null && this.filters.userType != '') {
      count++;
    }
    if (
      (this.filters.branchCode != null && this.filters.branchCode != '') ||
      (this.filters.siret != null && this.filters.siret != '') ||
      (this.filters.type != null && this.filters.type != '')
    ) {
      count++;
    }
    return count;
  }

  /**
   * Affiche ou non la pagination.
   * La pagination est affiché uniquement si aucun filtre n'a été sélectionné
   */
  public displayPagination(): boolean {
    return this.countFilter() < 1;
  }

  public async fetchUsersByFilters(url: string): Promise<void> {
    this.isLoading = true;
    this.shouldDisplayUsersTable = false;
    await this.usersService
      .getUsersByFilters(url)
      .then((usersResult: UsersResult) => {
        if (usersResult) {
          this.isLoading = false;
          this.shouldDisplayUsersTable = true;
          this.usersResult = usersResult;
          this.usersWithCodesDatas = usersResult.users;
          this.users = usersResult.users;
          if (usersResult.total > 0) {
            this.users = this.processUsersDisplay(this.users);
            this.dataSource = new MatTableDataSource(this.users);
            this.userTypesList = this.getUserTypesList(this.users);
          }
        }
        /**
         * S'il n'y a pas de pagination
         * On affiche un message indiquant que tous les
         * resultats n'ont pas été remonté
         */
        this.displayPaginationMessage =
          usersResult && !this.displayPagination() && usersResult.total >= 100;

        return usersResult;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  private processUsersDisplay(users: User[]): User[] {
    users.forEach((user) => {
      this.initiateShowingRolesAndProcesses(user);
      this.convertBooleanToText(user);
      this.decodeProcessPermissions(user);
      this.decodeUserTypesAndRoles(user);
    });
    return users;
  }

  private convertBooleanToText(user: User) {
    user.isDatapassAccountValue = this.textService.convertBooleanInText(
      user.datapassAccount
    );
    user.activatedValue = this.textService.convertBooleanInText(user.activated);
  }

  public decodeProcessPermissions(user: User) {
    const decodedPermissions = this.processList
      .filter((process) => user.processPermissions.includes(process.code))
      .map((process) => process.name);
    user.processPermissions = decodedPermissions;
  }

  public initiateShowingRolesAndProcesses(user: User) {
    user.shownRolesLimit = 3;
    user.shownProcessesLimit = 3;
    user.showMoreRoles = false;
    user.showMoreProcesses = false;
  }

  private decodeUserTypesAndRoles(user: User): void {
    if (user.userType) {
      const newUserType = this.textService.decodeUserType(user.userType);
      user.userType = newUserType;
    }
  }

  public async fetchProcesses(): Promise<void> {
    await this.usersService
      .getProcesses()
      .then((processes: Process[]) => {
        this.processList = processes;
        return processes;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public getUserTypesList(usersList: User[]): string[] {
    let userTypesList = [];
    usersList.forEach((user) => {
      if (user.userType) {
        userTypesList.push(user.userType);
      }
    });
    userTypesList = this.getUniqueInList(userTypesList);
    return userTypesList;
  }

  public getUniqueInList(list) {
    const uniqueSet = new Set(list);
    return Array.from(uniqueSet);
  }

  private switchToLandscapePage(): void {
    this.router.navigate(['/to-landscape'], {
      relativeTo: this.route,
      queryParams: {
        from: 'users',
      },
      queryParamsHandling: 'merge',
    });
  }
}
