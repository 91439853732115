import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/shared/models/user';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import {
  admLocDefaultRolesList,
  agtDefaultRolesList,
  delTecDefaultRolesList,
  UserRole,
  UserRoleLabels,
} from '../../models/userRole';
import { userTypeList } from '../../models/userType';
import { UsersService } from '../../services/users.service';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';
import { Process } from 'src/app/shared/models/process';

@Component({
  selector: 'app-add-new-user-dialog-box',
  templateUrl: './add-new-user-dialog-box.component.html',
  styleUrls: ['./add-new-user-dialog-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddNewUserDialogBoxComponent implements OnInit {
  @ViewChild('processInput') processInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('chipList') chipList;

  newUser: User = new User();
  newUserForm: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  processPermissionFormControl = new FormControl();
  filteredProcessPermissions: Observable<string[]>;
  processPermisions: string[] = [];
  admLocRolesSet = admLocDefaultRolesList;
  matcher = new ErrorStateMatcher();
  titleList: string[] = ['M', 'Mme'];
  userTypeList: string[] = ['Agent', 'Administrateur local'];
  standardRoles: UserRole[] = [];
  processList: Process[] = [];
  isEmailUsed = false;
  isProcessesListEmpthy = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UsersService,
    public dialogRef: MatDialogRef<AddNewUserDialogBoxComponent>,
    private formBuilder: FormBuilder
  ) {
    this.filteredProcessPermissions =
      this.processPermissionFormControl.valueChanges.pipe(
        startWith(null),
        map((process: string | null) =>
          process
            ? this._filter(process)
            : this.data.processList.map((process) => process.name).slice()
        )
      );
  }

  ngOnInit(): void {
    this.fetchUsersRoles();
    this.initiateForm();
    this.initiateDefaultValues();
    this.processList = this.data.processList;
  }

  public initiateDefaultValues(): void {
    this.newUserForm.get('userTypeFormControl').setValue(this.userTypeList[0]);
  }
  public initiateForm(): void {
    this.newUserForm = this.formBuilder.group({
      emailFormControl: ['', [Validators.required, Validators.email]],
      firstNameFormControl: new FormControl('', [Validators.required]),
      userTypeFormControl: new FormControl('', [Validators.required]),
      lastNameFormControl: new FormControl('', Validators.required),
      titleFormControl: new FormControl('', [Validators.required]),
      functionFormControl: new FormControl(),
      phoneNumberFormControl: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{10,20}$|^\\+[0-9]{10,20}$'),
      ]),
      mobilePhoneNumberFormControl: new FormControl('', [
        Validators.pattern('^[0-9]{10,20}$|^\\+[0-9]{10,20}$'),
      ]),
    });
  }

  public onUserTypeSelected(event: any): void {
    this.newUser.userType = event.value;
    this.checkEmpthyProcessPermissions();
  }

  public checkEmpthyProcessPermissions(): void {
    if (
      this.encodeUserType(this.newUserForm.get('userTypeFormControl').value) !==
      'AGT'
    ) {
      this.newUser.processPermissions = [];
      this.processPermisions = [];
      this.isProcessesListEmpthy = false;
    } else {
      if (this.processPermisions && this.processPermisions.length < 1) {
        this.isProcessesListEmpthy = true;
        setTimeout(() => {
          if (this.chipList) {
            this.chipList.errorState = true;
          }
        }, 1000);
      }
    }
  }
  public onTitleSelected(event: any): void {
    this.newUser.title = event.value;
    // forced check of processPermissions
    this.checkEmpthyProcessPermissions();
  }

  public onSubmit(): void {
    this.newUser.email = this.newUserForm.get('emailFormControl').value;
    this.newUser.email = this.newUser.email.toLowerCase();
    this.newUser.login = this.newUser.email;
    this.newUser.firstName = this.newUserForm.get('firstNameFormControl').value;
    this.newUser.lastName = this.newUserForm.get('lastNameFormControl').value;
    this.newUser.function = this.newUserForm.get('functionFormControl').value;
    this.newUser.phoneNumber = this.newUserForm.get(
      'phoneNumberFormControl'
    ).value;
    this.newUser.mobilePhoneNumber = this.newUserForm.get(
      'mobilePhoneNumberFormControl'
    ).value;
    this.newUser.userType = this.encodeUserType(
      this.newUserForm.get('userTypeFormControl').value
    );
    this.newUser.activated = true;
    this.newUser.datapassAccount = false;
    if (this.newUser.userType !== 'AGT') {
      this.newUser.processPermissions = null;
      this.processPermisions = null;
      this.isProcessesListEmpthy = false;
    } else {
      this.newUser.processPermissions = this.encodeProcessPermissions(
        this.processPermisions
      );
    }
    this.newUser.userRoles = this.setDefaultRoles(this.newUser.userType);
    if (!this.isEmailUsed) {
      this.dialogRef.close(this.newUser);
    }
  }

  public encodeUserType(chosedUserType: string): string {
    let encodedUserType = '';
    userTypeList.forEach((userType) => {
      if (chosedUserType === userType.name) {
        encodedUserType = userType.code;
      }
    });
    return encodedUserType;
  }

  public encodeProcessPermissions(decodedPermissions: string[]): string[] {
    let encodeProcessPermissions = [];
    encodeProcessPermissions = this.data.processList
      .filter((process) => decodedPermissions.includes(process.name))
      .map((process) => process.code);
    return encodeProcessPermissions;
  }

  public setDefaultRoles(userType: string): UserRole[] {
    let defaultRoles = [];
    if (this.standardRoles) {
      switch (userType) {
        case 'ADM_LOC': {
          defaultRoles = this.getRolesDefaultList(admLocDefaultRolesList);
          break;
        }
        case 'DEL_TEC': {
          defaultRoles = this.getRolesDefaultList(delTecDefaultRolesList);
          break;
        }
        case 'AGT': {
          defaultRoles = this.getRolesDefaultList(agtDefaultRolesList);
          break;
        }
        default: {
          defaultRoles = [];
          break;
        }
      }
    }
    return defaultRoles;
  }
  public getRolesDefaultList(rolesList: UserRoleLabels[]): UserRole[] {
    const defaultRoles = [];
    const userRolesKeys = rolesList.map((n) => n.code);
    userRolesKeys.forEach((userRole) => {
      this.standardRoles.forEach((standardRole) => {
        if (standardRole.display === userRole) {
          defaultRoles.push(standardRole);
        }
      });
    });
    return defaultRoles;
  }
  public async fetchUsersRoles(): Promise<void> {
    await this.usersService
      .getUsersRoles()
      .then((roles: UserRole[]) => {
        this.standardRoles = roles;
        return roles;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public async checkIfUserEmailIsUsed(): Promise<void> {
    if (this.newUserForm.get('emailFormControl').errors == null) {
      let email = this.newUserForm.get('emailFormControl').value;
      email = email.toLowerCase();
      await this.usersService
        .getUserEmailUsedState(email)
        .then((isEmailUsed: boolean) => {
          const emailValid = this.newUserForm.controls.emailFormControl;

          this.isEmailUsed = isEmailUsed;
          if (!this.isEmailUsed) {
            emailValid.updateValueAndValidity();
          } else {
            this.newUserForm.controls.emailFormControl.setErrors({
              isAlreadyUsed: true,
            });
          }
          return isEmailUsed;
        })
        .catch((error) => {
          throw new ApplicationException(error);
        });
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    // Add our process
    if ((value || '').trim()) {
      this.processPermisions.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.isProcessesListEmpthy = false;
    this.processPermissionFormControl.setValue(null);
  }

  remove(process: string): void {
    const index = this.processPermisions.indexOf(process);

    if (index >= 0) {
      this.processPermisions.splice(index, 1);
    }
    if (this.processPermisions.length < 1) {
      this.isProcessesListEmpthy = true;
      this.chipList.errorState = true;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.processPermisions.push(event.option.viewValue);
    this.processInput.nativeElement.value = '';
    this.isProcessesListEmpthy = false;
    this.chipList.errorState = false;
    this.processPermissionFormControl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.data.processList
      .map((process) => process.name)
      .filter((process) => process.toLowerCase().indexOf(filterValue) === 0);
  }
}
