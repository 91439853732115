<button
  mat-button
  class="closeButton"
  (click)="cancel()"
  mat-dialog-close
  mat-icon-button
  aria-label="Fermer la fenetre"
>
  <mat-icon>close</mat-icon>
</button>
<div class="loader">Loading...</div>
