import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from 'src/app/shared/models/organization';
import { User } from 'src/app/shared/models/user';
import { PreferencesService } from 'src/app/shared/service/preferences.service';
import { TextService } from 'src/app/shared/service/text.service';
import { userRolesList } from '../../models/userRole';
import { UsersResult } from '../../models/usersResult';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  providers: [TextService],
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() usersResult: UsersResult;
  @Input() isUsersAdminPage: boolean;
  @Input() isConnectedUserBusinessAdmin: boolean;
  @Input() organizationsList: Organization[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() Loading: 'start';
  @Input() clicked: false;
  @Input() displayPagination: boolean;
  @Output()
  public export: EventEmitter<void> = new EventEmitter();

  data: any;
  totalUsersNumber = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 50, 100];
  displayedColumns: string[] = [];
  showFirstLastButtons = true;
  isSeeAllProcessesClicked = false;
  seeMoreOrLessProcessesText = 'Voir tout';
  isSeeAllRolesClicked = false;
  seeMoreOrLessRolesText = 'Voir tout';
  users: User[];
  id: string;
  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private preferencesService: PreferencesService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setPaginationIds();
    }, 0);
  }

  private setPaginationIds(): void {
    const pagination = document.querySelector(
      this.isUsersAdminPage ? '#users-admin-paginator' : '#users-paginator'
    );
    if (pagination) {
      pagination
        .querySelector('.mat-paginator-navigation-previous')
        .setAttribute(
          'id',
          this.isUsersAdminPage
            ? 'ADMIN-LIST-USER-PAGES-BT-PREC'
            : 'LIST-USER-PAGES-BT-PREC'
        );
      pagination
        .querySelector('.mat-paginator-navigation-next')
        .setAttribute(
          'id',
          this.isUsersAdminPage
            ? 'ADMIN-LIST-USER-PAGES-BT-SUIV'
            : 'LIST-USER-PAGES-BT-SUIV'
        );
      pagination
        .querySelector('.mat-paginator-range-label')
        .setAttribute(
          'id',
          this.isUsersAdminPage
            ? 'ADMIN-LIST-USER-PAGES-NUM-TOTAL'
            : 'LIST-USER-PAGES-NUM-TOTAL'
        );
      pagination
        .querySelector('.mat-paginator-page-size-select')
        .setAttribute(
          'id',
          this.isUsersAdminPage
            ? 'ADMIN-LIST-USER-PAGES-NUM'
            : 'LIST-USER-PAGES-NUM'
        );
      pagination
        .querySelector('.mat-paginator-page-size')
        .setAttribute(
          'id',
          this.isUsersAdminPage
            ? 'ADMIN-LIST-USER-PAGES-NB-SELECT'
            : 'LIST-USER-PAGES-NB-SELECT'
        );
      pagination
        .querySelector('.mat-paginator-navigation-first')
        .setAttribute(
          'id',
          this.isUsersAdminPage
            ? 'ADMIN-LIST-USER-PAGES-BT-PREMIER'
            : 'LIST-USER-PAGES-BT-PREMIER'
        );
      pagination
        .querySelector('.mat-paginator-navigation-last')
        .setAttribute(
          'id',
          this.isUsersAdminPage
            ? 'ADMIN-LIST-USER-PAGES-BT-DERNIER'
            : 'LIST-USER-PAGES-BT-DERNIER'
        );
    }
  }

  ngOnInit(): void {
    this.setPageNumberAndSizeFromUrl();
    this.displayedColumns = this.isUsersAdminPage
      ? [
          'Actif',
          'Organisation',
          'Nom de famille',
          'Prénom',
          "Type d'acteur",
          'Rôles',
          'Habilitations',
          'hi',
        ]
      : [
          'Actif',
          'Nom de famille',
          'Prénom',
          "Type d'acteur",
          'Rôles',
          'Habilitations',
          'hi',
        ];
  }

  ngOnChanges(): void {
    if (this.usersResult) {
      this.users = this.usersResult.users;
      this.totalUsersNumber = this.usersResult.total;
    }
    this.changeDetectorRef.detectChanges();
  }

  hasOrganization(user: User) {
    if(user.organization != undefined){
     return (
        ![null, undefined, ''].includes(user.organization.branchCode) &&
        ![null, undefined, ''].includes(user.organization.name) &&
        ![null, undefined, ''].includes(user.organization.type)
      );
    }
  }

  public setPageNumberAndSizeFromUrl(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.pageIndex = +params.get('pageNumber');
      if (+params.get('pageSize') === 0) {
        this.pageSize = +this.preferencesService.getUserPagination();
      } else {
        this.pageSize = +params.get('pageSize');
      }
    });
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        pageNumber: this.pageIndex,
        pageSize: this.pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  openUserDetails(user: User): void {
    this.id = user.userID.toString();
    const path = this.isUsersAdminPage ? '/users/admin' : '/users';
    this.router.navigate([path, this.id], {
      state: { DataFromTable: this.organizationsList },
    });
  }

  public seeMoreProcessesToggle(user: User): void {
    user.showMoreProcesses = !user.showMoreProcesses;
    user.shownProcessesLimit = user.showMoreProcesses
      ? user.processPermissions.length
      : 3;
  }

  public seeMoreRolesToggle(user: User): void {
    user.showMoreRoles = !user.showMoreRoles;
    user.shownRolesLimit = user.showMoreRoles ? user.userRoles.length : 3;
  }

  public decodeUserRoles(userRoleCode: string): string {
    let roleLabel;
    userRolesList.forEach((role) => {
      if (userRoleCode === role.code) {
        roleLabel = role.name;
      }
    });
    return roleLabel;
  }
  public handlePageEvent(event: PageEvent): void {
    this.totalUsersNumber = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        pageNumber: this.pageIndex,
        pageSize: this.pageSize,
      },
      queryParamsHandling: 'merge',
    });
    window.scrollTo(0, 0);
  }

  /**
   * Returns organization name and type according to user oganization tryptic.
   *
   * @param orgType - organization type
   * @param siret - organization siret
   * @param branchCode - organization branch code
   * @returns organization name and organization type
   */
  public matchOrganizationWithUserTryptic(
    orgType: string,
    siret: string,
    branchCode: string
  ): string {
    let organizationName = '';
    this.organizationsList.forEach((organization) => {
      if (
        orgType === organization.type &&
        siret === organization.siret &&
        branchCode === organization.branchCode
      ) {
        organizationName = organization.name;
        organizationName += ' (' + organization.type + ')';
      }
    });
    return organizationName;
  }
}
