export function chaineEnBooleen(value): boolean {
  switch (value.toLowerCase().trim()) {
    case true:
    case 1:
      return true;
    case false:
    case 0:
    case null:
      return false;
    default:
      return Boolean(false);
  }
}

export function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  
  return function (a, b) {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}

export function dynamicDescSort(property) {
  return function (a, b) {
    return b[property].localeCompare(a[property]);
  };
}

export function capitalize(str: string): string {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
}
