import {
  Component,
  OnInit,
  ElementRef,
  Inject,
  ViewChild,
  ViewEncapsulation,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/shared/models/user';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UsersService } from '../../services/users.service';
import * as XLSX from 'xlsx';
import { ExcelDownloaderService } from 'src/app/features/users/services/excelDownloader.service';

import { MatAutocomplete } from '@angular/material/autocomplete';
import { Attachment } from '../../../../shared/models/attachment';

import { admLocDefaultRolesList, UserRole } from '../../models/userRole';
import { Process } from 'src/app/shared/models/process';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-add-excel-users-dialog-box',
  templateUrl: './add-excel-users-dialog-box.component.html',
  styleUrls: ['./add-excel-users-dialog-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddExcelUsersDialogBoxComponent implements OnInit {
  @ViewChild('processInput') processInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('chipList') chipList;

  @Input() attachment: Attachment;

  @Input() subfolder;
  @Output() CancelUploadButtonClickedEvent: EventEmitter<boolean> =
    new EventEmitter();
  @Output() ConfirmUploadButtonClickedEvent: EventEmitter<any> =
    new EventEmitter();

  newUser: User = new User();
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredProcessPermissions: Observable<string[]>;
  processPermisions: string[] = [];
  admLocRolesSet = admLocDefaultRolesList;
  matcher = new ErrorStateMatcher();
  titleList: string[] = ['M', 'Mme'];
  standardRoles: UserRole[] = [];
  processList: Process[] = [];
  isEmailUsed = false;
  isProcessesListEmpthy = true;
  isErrorOnServer = false;

  files: any = [];
  isFormatAccepted = false;
  attachmentsFormatsEnabled: string;
  attachmentsMaxSizeEnabled: string;
  spinnerEnabled = true;

  textToDisplay: string;

  enabledFormatsExcel = [];
  EnabledAttachmentsFormatsExcel = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  EnabledAttachmentsFormatsExcelExtension = ['xlsx'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddExcelUsersDialogBoxComponent>,
    private excelDownloaderService: ExcelDownloaderService,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    this.processList = this.data.processList;
    this.files = this.data.attachment[0];
    this.onChange(
      this.data.attachment[0],
      this.data.file,
      this.data.fileTarget
    );
    this.spinnerEnabled = true;
    this.isErrorOnServer = false;
  }

  public initiateDefaultExcelValues(): void {
    this.files = [];
  }

  public onCancelUploadButtonExcelClicked(): void {
    this.initiateDefaultExcelValues();
  }

  public onConfirmUploadButtonClicked(): void {
    if (this.attachment.size > 0) {
      this.ConfirmUploadButtonClickedEvent.emit(this.files);
    } else {
      this.CancelUploadButtonClickedEvent.emit();
    }
  }

  /**
   * @returns a boolean who say if the files is an Excel files or not
   */
  public isAttachmentsFormatsAccepted(): boolean {
    let FilesAccepted;
    this.EnabledAttachmentsFormatsExcel.forEach(
      (typeMimeAccepted, indexMime) => {
        this.EnabledAttachmentsFormatsExcelExtension.forEach(
          (typeAccepted, indexType) => {
            if (
              this.files.mimeType == typeMimeAccepted ||
              this.files.type == typeAccepted
            ) {
              return (FilesAccepted = true);
            } else {
              FilesAccepted = false;
            }
          }
        );
      }
    );
    return FilesAccepted;
  }

  public onChange(attachment, file, fileTarget): void {
    let data, header;

    const target: DataTransfer = <DataTransfer>fileTarget.target;
    if (this.isAttachmentsFormatsAccepted) {
      let currentDate = new Date();
      const dValue = formatDate(currentDate, 'ddMMyyyy', 'fr-FR');
      this.spinnerEnabled = true;
      const reader: FileReader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: rABS ? 'binary' : 'array',
        });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        data = XLSX.utils.sheet_to_json(ws);
        if (this.isValidateheaderProcessing(data)) {
          this.usersService
            .createImportUserAdministration(fileTarget)
            .then((resp) => {
              this.excelDownloaderService.downloadExcel(
                resp,
                'Rapport_import_utilisateurs_' +
                  dValue +
                  '_' +
                  attachment.fileName
              );
              this.cancel();
              this.spinnerEnabled = false;
            })
            .catch((error) => {
              this.isErrorOnServer = true;
              this.spinnerEnabled = false;
              if (error.status == 226) {
                this.textToDisplay =
                  "Echec de l'importation ! Plusieurs emails identiques trouvés";
              } else {
                this.textToDisplay =
                  "Echec de l'importation ! Une erreur du côté serveur s'est produite.";
              }
            });
        } else {
          this.spinnerEnabled = false;
          this.textToDisplay =
            'Certaines entêtes sont manquantes, veuillez vérifier le fichier';
        }
      };
      reader.readAsBinaryString(fileTarget);
    }
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  public isValidateheaderProcessing(data): boolean {
    let keys;
    let isCompliant = true;
    let arrayOfHeader = [
      'Email',
      'Nom',
      'Prénom',
      'Fonction',
      'Guichet / Code INSEE',
      'Département',
      'SIRET',
      "Type d'acteur",
      'Civilité',
      'Téléphone principal',
      'Habilitations',
    ];
    let arrayHeaderTransform = this.transformArrayForControl(arrayOfHeader);
    keys = Object.keys(data[0]);
    let arrayDataTransform = this.transformArrayForControl(keys);
    arrayDataTransform.forEach((lines) => {
      if (arrayHeaderTransform.indexOf(lines) === -1) {
        return (isCompliant = false);
      }
    });
    return isCompliant;
  }

  public transformArrayForControl(array) {
    let arrayFormat = [];
    array.forEach((element) => {
      arrayFormat.push(encodeURIComponent(element.toLowerCase()));
    });
    return arrayFormat;
  }
}
