import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { CsvDownloaderService } from 'src/app/features/subscription/services/csvDownloader.service';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';
import {
  Organization,
  OrganizationsResponse,
} from 'src/app/shared/models/organization';
import { Process, ProcessResult } from 'src/app/shared/models/process';
import { User } from 'src/app/shared/models/user';
import { TextService } from 'src/app/shared/service/text.service';
import { UsersFilters } from '../../models/usersFilters';
import { UsersResult } from '../../models/usersResult';
import { UsersService } from '../../services/users.service';
import { UsersCsvConverterService } from '../../services/usersCsvConverter.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-users-admin-page',
  templateUrl: './users-admin-page.component.html',
  styleUrls: ['./users-admin-page.component.scss'],
  providers: [TextService],
})
export class UsersAdminPageComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  filters: UsersFilters = new UsersFilters();
  isUsersAdminPage: boolean;
  isUsersPage: boolean;
  usersResult: any;
  users: User[] = [];
  dataSource: any;
  processesList: Process[] = [];
  userTypesList: string[] = [];
  userRolesList: string[] = [];
  usersWithCodesDatas: any;
  url: string;
  hasResults: boolean;
  processes: Process[] = [];
  organizationsList: Organization[] = [];
  isConnectedUserBusinessAdmin: boolean;
  connectedUserUserType: string;
  isLoading = true;
  shouldDisplayUsersTable = false;
  Loading: string;
  clicked: boolean;
  displayPaginationMessage: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private textService: TextService,
    public snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private oauthService: OAuthService,
    private csvDownloaderService: CsvDownloaderService,
    private usersCsvConverterService: UsersCsvConverterService,
    private translate: TranslateService
  ) {
    const layoutChanges = this.breakpointObserver.observe([
      '(orientation: portrait)',
      '(max-width: 767px)',
    ]);

    layoutChanges.subscribe((result) => {
      if (result.breakpoints['(max-width: 767px)']) {
        this.switchToLandscapePage();
      }
    });
  }

  ngOnInit(): void {
    this.fetchProcesses();
    this.getResultsByQueryParams();
    this.checkPageContent();
    const claims: any = this.oauthService.getIdentityClaims();
    this.isConnectedUserBusinessAdmin = claims.user_type === 'ADM_NAT';
    this.connectedUserUserType = claims.user_type;
    this.Loading = 'start';
  }

  ngOnChanges(): void {
    this.checkPageContent();
    this.router.events.subscribe((ev) => {
      this.checkPageContent();
    });
  }

  public checkPageContent(): void {
    this.isUsersAdminPage = this.router.url.includes('/users/admin');
    this.isUsersPage = !this.isUsersAdminPage;
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }
  export(): void {
    this.Loading = 'loading';
    const filters = JSON.parse(JSON.stringify(this.filters));
    filters.pageNumber = 0;
    filters.pageSize = this.displayPaginationMessage
      ? -1 // -1 signifie qu'il faut tout recuperer cote back
      : this.usersResult.total;
    const url = this.createUrlStringFromFilters(filters, '');
      this.usersService
        .getUsersByFiltersAdministrationExport(url)
        .then(async (usersResult) => {
          if (usersResult) {
            const csv = this.usersCsvConverterService.toCsv(
              JSON.parse(JSON.stringify(usersResult.users))
            );
            this.csvDownloaderService.download(csv, 'export-utilisateurs.csv');

            this.Loading = 'success';
            setTimeout(() => {
              this.Loading = 'start';
              this.clicked = false;
            }, 1000);
          } else {
            this.Loading = 'error';
            setTimeout(() => {
              this.clicked = false;
              this.Loading = 'start';
            }, 1000);
            this.openSnackbar(
              this.translate.instant(
                'labels.subfolder.details.attachments.snackbar.downloadFail'
              )
            );
          }
        })
        .catch((e) => {
          this.Loading = 'error';
          setTimeout(() => {
            this.Loading = 'start';
            this.clicked = false;
          }, 2000);
        });
  }

  getResultsByQueryParams(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.url = '';
      this.filters = this.usersService.getFilters(params);
      this.url = this.createUrlStringFromFilters(this.filters, this.url);
      this.fetchUsersByFilters(this.url);
    });
  }

  public createUrlStringFromFilters(
    filters: UsersFilters,
    url: string
  ): string {
    if (url) {
      url += '&';
    }
    Object.keys(filters).forEach((el) => {
      if (filters[el]) {
        url += `&${el}=${filters[el]}`;
        url = this.treatUrlString(url);
      }
    });
    return url;
  }

  openSnackbar(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    this.snackBar.open(message, undefined, config);
  }
  public treatUrlString(url: string): string {
    const regex = /[\[\]']|"/gm;
    const subst = ``;
    url = url.replace(regex, subst);
    return url;
  }

  public getProcessesFromQuery(processes: string): string {
    let processesUrlText = '';
    const selectedItemsArray = JSON.parse(processes);
    selectedItemsArray.forEach((process) => {
      process = encodeURI(process);
      processesUrlText += '&processPermission=' + process;
    });
    return processesUrlText;
  }

  public getUserRolesFromQuery(userRoles: string): string {
    let userRolesUrlText = '&userRoles=';
    const selectedItemsArray = JSON.parse(userRoles);
    selectedItemsArray.forEach((role) => {
      role = encodeURI(role);
      userRolesUrlText += role;
    });
    return userRolesUrlText;
  }

  private countFilter(): number {
    let count = 0;
    if (
      this.filters.processPermission != null &&
      this.filters.processPermission != ''
    ) {
      count++;
    }
    if (this.filters.userRoles != null && this.filters.userRoles != '') {
      count++;
    }
    if (this.filters.searched != null && this.filters.searched != '') {
      count++;
    }
    if (this.filters.userType != null && this.filters.userType != '') {
      count++;
    }
    if (
      (this.filters.branchCode != null && this.filters.branchCode != '') ||
      (this.filters.siret != null && this.filters.siret != '') ||
      (this.filters.type != null && this.filters.type != '')
    ) {
      count++;
    }
    return count;
  }

  /**
   * Affiche ou non la pagination.
   * La pagination est affiché uniquement si aucun filtre n'a été sélectionné
   */
  public displayPagination(): boolean {
    return this.countFilter() < 1;
  }

  public async fetchUsersByFilters(url: string): Promise<void> {
    this.isLoading = true;
    this.shouldDisplayUsersTable = false;
    await this.usersService
      .getUsersByFiltersAdministration(url)
      .then((usersResult: UsersResult) => {
        if (usersResult) {
          this.usersResult = usersResult;
          this.users = usersResult.users;
          if (usersResult.total > 0) {
            this.users = this.processUsersDisplay(this.users);
            this.userTypesList = this.getUserTypesList(this.users);
            this.dataSource = new MatTableDataSource(this.users);
          }
          this.isLoading = false;
          this.shouldDisplayUsersTable = true;
        }
        /**
         * S'il n'y a pas de pagination
         * On affiche un message indiquant que tous les
         * resultats n'ont pas été remonté
         */
        this.displayPaginationMessage =
          usersResult && !this.displayPagination() && usersResult.total >= 100;

        return usersResult;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  private processUsersDisplay(users: User[]): User[] {
    users.forEach((user) => {
      this.initiateShowingRolesAndProcesses(user);
      this.convertBooleanToText(user);
      this.decodeProcessPermissions(user);
      this.decodeUserTypesAndRoles(user);
    });
    return users;
  }

  private convertBooleanToText(user: User) {
    user.isDatapassAccountValue = this.textService.convertBooleanInText(
      user.datapassAccount
    );
    user.activatedValue = this.textService.convertBooleanInText(user.activated);
  }

  public decodeProcessPermissions(user: User) {
    const decodedPermissions = this.processesList
      .filter((process) => user.processPermissions.includes(process.code))
      .map((process) => process.name);
    user.processPermissions = decodedPermissions;
  }

  private decodeUserTypesAndRoles(user: User): void {
    if (user.userType) {
      const newUserType = this.textService.decodeUserType(user.userType);
      user.userType = newUserType;
    }
  }

  /**
   * Retrieves all processes according to user privileges.
   */
  public fetchProcesses(): void {
    this.usersService
      .getProcessesAdmin()
      .subscribe((response: ProcessResult) => {
        this.processesList = response.processes;
      });
  }

  public getUserTypesList(usersList: User[]): string[] {
    let userTypesList = [];
    usersList.forEach((user) => {
      if (user.userType) {
        userTypesList.push(user.userType);
      }
    });
    userTypesList = this.getUniqueInList(userTypesList);
    return userTypesList;
  }

  public getUniqueInList(list) {
    const uniqueSet = new Set(list);
    return Array.from(uniqueSet);
  }

  public initiateShowingRolesAndProcesses(user: User) {
    user.shownRolesLimit = 3;
    user.shownProcessesLimit = 3;
    user.showMoreRoles = false;
    user.showMoreProcesses = false;
  }

  private switchToLandscapePage(): void {
    this.router.navigate(['/to-landscape'], {
      relativeTo: this.route,
      queryParams: {
        from: 'adminusers',
      },
      queryParamsHandling: 'merge',
    });
  }
}
