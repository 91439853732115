import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/shared/models/user';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import {
  admLocDefaultRolesList,
  admMetDefaultRolesList,
  admNatDefaultRolesList,
  agtDefaultRolesList,
  delTecDefaultRolesList,
  UserRole,
  UserRoleLabels,
} from '../../models/userRole';
import {
  UserType,
  userTypeList,
  userTypeListAdministration,
  userTypeListAdministrationForBusinessAdmUser,
  userTypeListForAgtAndAdmLoc,
  userTypeListForTechDel,
} from '../../models/userType';
import { UsersService } from '../../services/users.service';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';
import { Process, ProcessResult } from 'src/app/shared/models/process';
import {
  Organization,
  OrganizationFilter,
} from 'src/app/shared/models/organization';
import { OAuthService } from 'angular-oauth2-oidc';
import { OrganizationHttpService } from 'src/app/features/subscription/services/organizationHttp.service';
import { OrganizationAdminHttpService } from 'src/app/features/organization-admin/services/organizationAdminHttp.service';
import {
  SearchCriteria,
  SearchDto,
  SearchOperation,
} from 'src/app/shared/models/search';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-add-new-user-admin-dialog-box',
  templateUrl: './add-new-user-admin-dialog-box.component.html',
  styleUrls: ['./add-new-user-admin-dialog-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddNewUserAdminDialogBoxComponent implements OnInit {
  @ViewChild('processInput') processInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('chipList') chipList;

  newUser: User = new User();
  newUserForm: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  processPermissionFormControl = new FormControl();
  allProcesses: Observable<string[]>;
  processesPermissions: string[] = [];
  admLocRolesSet = admLocDefaultRolesList;
  matcher = new ErrorStateMatcher();
  titleList: string[] = ['M', 'Mme'];
  userTypeList: string[] = [];
  standardRoles: UserRole[] = [];
  isEmailUsed = false;
  isProcessesListEmpty = true;
  organizationsList: Organization[] = [];
  organizationFormControl = new FormControl('', [Validators.required]);
  filteredOptions: Observable<any[]>;
  isOrganizationChosen = false;
  userAllowedProcess: Process[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UsersService,
    private oauthService: OAuthService,
    public dialogRef: MatDialogRef<AddNewUserAdminDialogBoxComponent>,
    private organizationsService: OrganizationAdminHttpService,
    private formBuilder: FormBuilder
  ) {
    this.allProcesses = this.processPermissionFormControl.valueChanges.pipe(
      startWith(null),
      map((process: string | null) =>
        process
          ? this._filter(process)
          : this.data.processList.map((pr) => pr.name).slice()
      )
    );
  }

  ngOnInit(): void {
    this.fetchUsersRolesAdministration();
    this.userTypeList = this.initiateUserTypeOptions(
      this.data.connectedUserUserType
    )
      .filter((ut) => this.isAdminNat() || ut.code !== 'DEL_TEC')
      .map((ut) => ut.name);
    this.initiateForm();
    this.initiateDefaultValues();
    this.organizationsList = this.data.organizationsList;
    this.initiateOrganizationsInFilter();
  }

  private isAdminNat() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims) {
      return claims.user_type === 'ADM_NAT';
    }
    return false;
  }

  public getOrganizationTypeByUserType(userType: string): string {
    switch (userType) {
      case 'Administrateur national':
        return 'ADM_NAT';
      case 'Administrateur métier':
        return 'OSL';
      case 'Agent':
      case 'Administrateur local':
        return 'SI';
      case 'Délégué technique':
        return 'EDT';
      default:
        return '';
    }
  }

  public initiateOrganizationsInFilter(): void {
    const orgType = this.getOrganizationTypeByUserType(
      this.newUserForm.get('userTypeFormControl').value
    );
    this.filteredOptions = this.organizationFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      map((value: any) => (typeof value === 'string' ? value : value.name)),
      switchMap((value: string) => {
        const criterias: SearchCriteria[] = [
          {
            filterKey: 'name',
            operation: SearchOperation.CONTAINS,
            value: value,
          },
          {
            filterKey: 'type',
            operation: SearchOperation.EQUAL,
            value: orgType,
          },
        ];

        const searchDto: SearchDto = {
          searchCriteriaList: criterias,
          dataOption: 'all',
        };
        return this.organizationsService.search(searchDto, environment.maxResult);
      }),
      map((value) => value.organizations)
    );
  }

  public filterOrganizationsByUserType(userType: string): Organization[] {
    switch (userType) {
      case 'Administrateur national': {
        return this.organizationsList.filter(
          (organization) => organization.type === 'ADM_NAT'
        );
      }
      case 'Administrateur métier': {
        return this.organizationsList.filter(
          (organization) => organization.type === 'OSL'
        );
      }
      case 'Agent':
      case 'Administrateur local': {
        return this.organizationsList.filter(
          (organization) => organization.type === 'SI'
        );
      }
      case 'Délégué technique': {
        return this.organizationsList.filter(
          (organization) => organization.type === 'EDT'
        );
      }
      default: {
        return [];
      }
    }
  }

  private _filterOrg(name: string, orgList: Organization[]): Organization[] {
    const filterValue = name.toLowerCase();
    return this.filtreTexte(orgList, filterValue);
  }

  /**
   *
   * Lit un tableau et retourne un tableau qui possède la chaine de caractère entrée.
   *
   * @param arr Tableau qui doit être filtré
   * @param requete Texte qui doit se retrouver dans le tableau
   * @returns Un nouveau tableau avec toute les données comportant la chaine de caractère de requete.
   */
  public filtreTexte(arr, requete) {
    return arr.filter(
      (option) =>
        option.name.toLowerCase().indexOf(requete.toLowerCase()) !== -1
    );
  }

  displayFn(org: Organization): string {
    return typeof org === 'string' ? org : org.branchCode + ' - ' + org.name;
  }

  public onOrganizationSelected(event: any): void {
    this.newUser.organization = event;
    this.isOrganizationChosen = true;
    this.processesPermissions = [];
    this.processPermissionFormControl.setValue(null);
    this.fetchOrganizationAllowedProcesses();
    this.checkEmptyProcessPermissions();
  }

  public initiateUserTypeOptions(connectedUserUserType: string): UserType[] {
    switch (connectedUserUserType) {
      case 'ADM_NAT': {
        return userTypeListAdministration;
      }
      case 'ADM_MET': {
        return userTypeListAdministrationForBusinessAdmUser;
      }
      case 'ADM_LOC': {
        return userTypeListForAgtAndAdmLoc;
      }
      case 'DEL_TEC': {
        return userTypeListForTechDel;
      }
      case 'AGT': {
        return userTypeListForAgtAndAdmLoc;
      }
      default: {
        return [];
      }
    }
  }

  public initiateDefaultValues(): void {
    this.newUserForm.get('userTypeFormControl').setValue('Agent');
  }

  public initiateForm(): void {
    this.newUserForm = this.formBuilder.group({
      emailFormControl: ['', [Validators.required, Validators.email]],
      firstNameFormControl: new FormControl('', [Validators.required]),
      userTypeFormControl: new FormControl('Agent', [Validators.required]),
      lastNameFormControl: new FormControl('', Validators.required),
      titleFormControl: new FormControl('', [Validators.required]),
      functionFormControl: new FormControl(),
      phoneNumberFormControl: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{10,20}$|^\\+[0-9]{10,20}$'),
      ]),
      mobilePhoneNumberFormControl: new FormControl('', [
        Validators.pattern('^[0-9]{10,20}$|^\\+[0-9]{10,20}$'),
      ]),
    });
  }

  public onUserTypeSelected(event: any): void {
    this.newUser.userType = event.value;
    this.processesPermissions = [];
    this.processPermissionFormControl.setValue(null);
    this.checkEmptyProcessPermissions();
    this.organizationFormControl.setValue('');
    this.initiateOrganizationsInFilter();
  }

  public checkEmptyProcessPermissions(): void {
    if (
      this.encodeUserType(this.newUserForm.get('userTypeFormControl').value) !==
      'AGT'
    ) {
      this.newUser.processPermissions = [];
      this.processesPermissions = [];
      this.isProcessesListEmpty = false;
    } else {
      if (this.processesPermissions.length < 1) {
        this.isProcessesListEmpty = true;
        setTimeout(() => {
          if (this.chipList) {
            this.chipList.errorState = true;
          }
        }, 1000);
      }
    }
  }
  public onTitleSelected(event: any): void {
    this.newUser.title = event.value;
    // forced check of processPermissions
    this.checkEmptyProcessPermissions();
  }

  public onSubmit(): void {
    this.newUser.email = this.newUserForm.get('emailFormControl').value;
    this.newUser.email = this.newUser.email.toLowerCase();
    this.newUser.login = this.newUser.email;
    this.newUser.firstName = this.newUserForm.get('firstNameFormControl').value;
    this.newUser.lastName = this.newUserForm.get('lastNameFormControl').value;
    this.newUser.function = this.newUserForm.get('functionFormControl').value;
    this.newUser.phoneNumber = this.newUserForm.get(
      'phoneNumberFormControl'
    ).value;
    this.newUser.mobilePhoneNumber = this.newUserForm.get(
      'mobilePhoneNumberFormControl'
    ).value;
    this.newUser.userType = this.encodeUserType(
      this.newUserForm.get('userTypeFormControl').value
    );
    this.newUser.activated = true;
    this.newUser.datapassAccount = false;
    if (this.newUser.userType !== 'AGT') {
      this.newUser.processPermissions = null;
      this.processesPermissions = null;
      this.isProcessesListEmpty = false;
    } else {
      this.newUser.processPermissions = this.encodeProcessPermissions(
        this.processesPermissions
      );
    }
    this.newUser.userRoles = this.setDefaultRoles(this.newUser.userType);
    if (!this.isEmailUsed) {
      this.dialogRef.close(this.newUser);
    }
  }

  isEmptyProcessPermissionsList() {
    let res = false;
    if (this.userAllowedProcess) {
      res = this.userAllowedProcess.every((process) =>
        this.processesPermissions.includes(process.name)
      );
    }

    return res;
  }

  public encodeUserType(chosedUserType: string): string {
    let encodedUserType = '';
    userTypeList.forEach((userType) => {
      if (chosedUserType === userType.name) {
        encodedUserType = userType.code;
      }
    });
    return encodedUserType;
  }

  public encodeProcessPermissions(decodedPermissions: string[]): string[] {
    let encodeProcessPermissions = [];
    encodeProcessPermissions = this.data.processList
      .filter((process: Process) => decodedPermissions.includes(process.name))
      .map((process: Process) => process.code);
    return encodeProcessPermissions;
  }

  public getRolesDefaultList(rolesList: UserRoleLabels[]): UserRole[] {
    const defaultRoles = [];
    const userRolesKeys = rolesList.map((n) => n.code);
    userRolesKeys.forEach((userRole) => {
      this.standardRoles.forEach((standardRole) => {
        if (standardRole.display === userRole) {
          defaultRoles.push(standardRole);
        }
      });
    });
    return defaultRoles;
  }
  public setDefaultRoles(userType: string): UserRole[] {
    let defaultRoles = [];
    if (this.standardRoles) {
      switch (userType) {
        case 'ADM_NAT': {
          defaultRoles = this.getRolesDefaultList(admNatDefaultRolesList);
          break;
        }
        case 'ADM_MET': {
          defaultRoles = this.getRolesDefaultList(admMetDefaultRolesList);
          break;
        }
        case 'ADM_LOC': {
          defaultRoles = this.getRolesDefaultList(admLocDefaultRolesList);
          break;
        }
        case 'DEL_TEC': {
          defaultRoles = this.getRolesDefaultList(delTecDefaultRolesList);
          break;
        }
        case 'AGT': {
          defaultRoles = this.getRolesDefaultList(agtDefaultRolesList);
          break;
        }
        default: {
          defaultRoles = [];
          break;
        }
      }
    }
    return defaultRoles;
  }

  public async fetchUsersRolesAdministration(): Promise<void> {
    await this.usersService
      .getUsersRolesAdministration()
      .then((roles: UserRole[]) => {
        this.standardRoles = roles;
        return roles;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public async checkIfUserEmailIsUsed(): Promise<void> {
    if (this.newUserForm.get('emailFormControl').errors == null) {
      let email = this.newUserForm.get('emailFormControl').value;
      email = email.toLowerCase();
      await this.usersService
        .getUserEmailUsedStateAdminScreen(email)
        .then((isEmailUsed: boolean) => {
          const emailValid = this.newUserForm.controls.emailFormControl;

          this.isEmailUsed = isEmailUsed;
          if (!this.isEmailUsed) {
            emailValid.updateValueAndValidity();
          } else {
            this.newUserForm.controls.emailFormControl.setErrors({
              isAlreadyUsed: true,
            });
          }
          return isEmailUsed;
        })
        .catch((error) => {
          throw new ApplicationException(error);
        });
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our process
    if ((value || '').trim()) {
      this.processesPermissions.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.isProcessesListEmpty = false;
    this.processPermissionFormControl.setValue(null);
  }

  remove(process: string): void {
    const index = this.processesPermissions.indexOf(process);

    if (index >= 0) {
      this.processesPermissions.splice(index, 1);
    }
    if (this.processesPermissions.length < 1) {
      this.isProcessesListEmpty = true;
      this.chipList.errorState = true;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.viewValue != '') {
      this.processesPermissions.push(event.option.viewValue);
      this.processInput.nativeElement.value = '';
      this.isProcessesListEmpty = false;
      this.chipList.errorState = false;
      this.processPermissionFormControl.setValue(null);
    }
    this.processInput.nativeElement.blur();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.data.processList
      .map((process) => process.name)
      .filter((process) => process.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * Retrieves all processes linked to new user selected organization.
   */
  private fetchOrganizationAllowedProcesses(): void {
    this.usersService
      .getProcessesAdmin(
        this.newUser.organization.type,
        this.newUser.organization.siret,
        this.newUser.organization.branchCode
      )
      .subscribe((response: ProcessResult) => {
        this.userAllowedProcess = response.processes;
      });
  }
}
