import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';
import { Organization } from 'src/app/shared/models/organization';
import { Process } from 'src/app/shared/models/process';
import { User } from 'src/app/shared/models/user';
import { TextService } from 'src/app/shared/service/text.service';
import { AddNewUserAdminDialogBoxComponent } from '../../modals/add-new-user-admin-dialog-box/add-new-user-admin-dialog-box.component';
import { AddNewUserDialogBoxComponent } from '../../modals/add-new-user-dialog-box/add-new-user-dialog-box.component';
import { UsersService } from '../../services/users.service';

import { Attachment } from '../../../../shared/models/attachment';
import { SnackbarService } from 'src/app/shared/service/snackbar.service';
import { AddExcelUsersDialogBoxComponent } from '../../modals/add-excel-users-dialog-box/add-excel-users-dialog-box.component';

@Component({
  selector: 'app-users-banner',
  templateUrl: './users-banner.component.html',
  styleUrls: ['./users-banner.component.scss'],
  providers: [TextService],
})
export class UsersBannerComponent {
  @Output() DownloadButtonClickedEvent: EventEmitter<boolean> =
    new EventEmitter();
  @Input() processList: Process[] = [];
  @Input() isUsersAdminPage = false;
  @Input() isConnectedUserBusinessAdmin: boolean;
  @Input() connectedUserUserType: string;

  bannerTitle = '';
  bannerSubtitle = '';
  @Input() isLoading = true;

  attachment: Attachment[] = [];
  files: any = [];
  isFormatAccepted = false;
  attachmentsFormatsEnabled: string;
  attachmentsMaxSizeEnabled: string;

  @Input() subfolder;
  @Input() isUploadingFromUpdatingBox;
  @Output() CancelUploadButtonClickedEvent: EventEmitter<boolean> =
    new EventEmitter();
  @Output() ConfirmUploadButtonClickedEvent: EventEmitter<any> =
    new EventEmitter();

  @ViewChild('fileInput', { static: false }) fileInput: any;

  constructor(
    private usersService: UsersService,
    private dialog: MatDialog,
    private textService: TextService,
    public snackBar: MatSnackBar,
    private snackbar: SnackbarService
  ) {}

  public onAddNewUser(): void {
    const dialogRef = this.dialog.open(AddNewUserDialogBoxComponent, {
      data: {
        processList: this.processList,
        isMultipleSelection: false,
        subfolder: 'this.subfolder',
        nextStatus: 'changingStatusWhenDownload',
        title: this.textService.getText(
          'labels.subfolder.confirmationDownloadDialogBox.title'
        ),
        messageOfReception: this.textService.getText(
          'labels.subfolder.confirmationDownloadDialogBox.messageOfReception'
        ),
        cancelButton: this.textService.getText(
          'labels.subfolder.confirmationDownloadDialogBox.cancelButton'
        ),
        confirmButtonOfReception: this.textService.getText(
          'labels.subfolder.confirmationDownloadDialogBox.confirmButtonOfReception'
        ),
      },
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== 'cancel') {
        this.addNewUser(result);
      }
    });
  }

  public onAddNewUserAdmin(): void {
    const dialogRef = this.dialog.open(AddNewUserAdminDialogBoxComponent, {
      data: {
        connectedUserUserType: this.connectedUserUserType,
        isConnectedUserBusinessAdmin: this.isConnectedUserBusinessAdmin,
        isUsersAdminPage: this.isUsersAdminPage,
        processList: this.processList,
        isMultipleSelection: false,
        subfolder: 'this.subfolder',
        nextStatus: 'changingStatusWhenDownload',
        title: this.textService.getText(
          'labels.subfolder.confirmationDownloadDialogBox.title'
        ),
        messageOfReception: this.textService.getText(
          'labels.subfolder.confirmationDownloadDialogBox.messageOfReception'
        ),
        cancelButton: this.textService.getText(
          'labels.subfolder.confirmationDownloadDialogBox.cancelButton'
        ),
        confirmButtonOfReception: this.textService.getText(
          'labels.subfolder.confirmationDownloadDialogBox.confirmButtonOfReception'
        ),
      },
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== 'cancel') {
        this.addNewUserAdministration(result);
      }
    });
  }

  /**
   * Add a new user.
   *
   * @param newUser - New user data
   */
  public async addNewUser(newUser: User): Promise<void> {
    await this.usersService
      .createNewUser(newUser)
      .then((user: User) => {
        if (user) {
          this.refreshPage();
          this.openSnackbar(
            this.textService.getText('labels.users.header.addUserSuccess')
          );
        }
        return user;
      })
      .catch((error) => {
        const parsedError = JSON.parse(JSON.stringify(error));
        if (
          parsedError &&
          parsedError.data.error &&
          parsedError.data.error.message.includes(
            'Cannot connect to middleware to add member to group'
          )
        ) {
          this.openSnackbarWithDuration(
            this.textService.getText('labels.users.header.addUserRolesFail'),
            5000
          );
        } else {
          this.openSnackbar(
            this.textService.getText('labels.users.header.addUserFail')
          );
        }

        throw new ApplicationException(error);
      });
  }

  /**
   * appeler le service pour créer un nouveau utilisateur
   * @param newUser l'objet user
   */
  public async addNewUserAdministration(newUser: User): Promise<void> {
    await this.usersService
      .createNewUserAdministration(newUser)
      .then((user: User) => {
        if (user) {
          this.refreshPage();
          this.openSnackbar(
            this.textService.getText('labels.users.header.addUserSuccess')
          );
        }
        return user;
      })
      .catch((error) => {
        this.openSnackbar(
          this.textService.getText('labels.users.header.addUserFail')
        );
        throw new ApplicationException(error);
      });
  }

  refreshPage(): void {
    window.location.reload();
  }

  openSnackbar(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    this.snackBar.open(message, undefined, config);
  }

  openSnackbarWithDuration(message: string, duration: number): void {
    const config = new MatSnackBarConfig();
    config.duration = duration;
    this.snackBar.open(message, undefined, config);
  }

  /**
   * function call in the template.
   * Send file Data to the Modal
   * @param file File receive
   */
  public uploadFile(fullFile, file, fileTarget): void {
    this.attachment = [];
    if (file[0].type !== '') {
      if (
        file[0].type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const a: Attachment = {
          id: 0,
          fileName: file[0].name,
          mimeType: file[0].type,
          size: file[0].size,
          type: file[0].name.substring(file[0].name.lastIndexOf('.') + 1),
        };
        this.attachment.push(a);
        this.files.push(file);
        const target: DataTransfer = <DataTransfer>file[0];
        this.onAddNewExcelofUsersAdmin(this.attachment, fullFile, target);
        this.clearInputFile();
      } else {
        this.snackbar.openSnackbar(
          'labels.subfolder.details.attachments.snackbar.uploadFailedInvalidFormatExcel'
        );
      }
    } else {
      this.snackbar.openSnackbar(
        'labels.subfolder.details.attachments.snackbar.uploadFailedInvalidFormatExcel'
      );
    }
  }

  public onAddNewExcelofUsersAdmin(attachment, file, fileTarget): void {
    const dialogExcelRef = this.dialog.open(AddExcelUsersDialogBoxComponent, {
      data: {
        attachment: attachment,
        file: file,
        fileTarget: fileTarget,
      },
    });
    dialogExcelRef.afterClosed().subscribe((result) => {
      if (result == 'cancel') {
        this.clearInputFile();
      }
    });
  }

  public clearInputFile(): void {
    this.fileInput.nativeElement.value = '';
  }
}
