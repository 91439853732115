import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TextService } from './text.service';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private textService: TextService, public snackBar: MatSnackBar) {}

  public openSnackbar(messageKey: string): void {
    const message = this.textService.getText(messageKey);
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    this.snackBar.open(message, undefined, config);
  }

  public openSnackbarWithArgs(messageKey: string, args: any): void {
    const message = this.textService.getTextWithArgs(messageKey, args);
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    this.snackBar.open(message, undefined, config);
  }

  public dataHasChanged(): void {
    this.openSnackbar('labels.subfolder.snackbar.errors.changedDataError');
  }

  public errorOnStatusUpdate(status: number) {
    let statusText = this.textService.getText('subfolder.status.' + status);
    this.openSnackbarWithArgs(
      'labels.subfolder.snackbar.errors.errorOnStatusUpdate',
      { status: statusText }
    );
  }

  public downloadFileSucceeded(): void {
    this.openSnackbar(
      'labels.subfolder.details.attachments.snackbar.downloadSuccess'
    );
  }

  public downloadFailedDetailsPage(): void {
    this.openSnackbar(
      'labels.subfolder.details.attachments.snackbar.downloadFail'
    );
  }

  public updateStatusSuccess(): void {
    this.openSnackbar('labels.subfolder.snackbar.success.changeStatusSuccess');
  }

  public unknownError(): void {
    this.openSnackbar(
      'labels.subfolder.details.header.snackbar.changeStatError'
    );
  }

  public clientIdCopied(): void {
    this.openSnackbar('labels.subscriptions.details.snackbar.copyClientId');
  }

  public clientSecretCopied(): void {
    this.openSnackbar('labels.subscriptions.details.snackbar.copyClientSecret');
  }

  public organizationEnabled(): void {
    this.openSnackbar('labels.admin.organisations.details.update.enabled');
  }

  public organizationDisabled(): void {
    this.openSnackbar('labels.admin.organisations.details.update.disabled');
  }

  public organizationError(): void {
    this.openSnackbar('labels.admin.organisations.details.update.error');
  }

  public organizationUpdateSuccess(): void {
    this.openSnackbar('labels.admin.organisations.details.update.success');
  }

  public organizationRenewClientSecretSuccess(): void {
    this.openSnackbar(
      'labels.subscriptions.details.snackbar.renewSuccessClientSecret'
    );
  }

  public organizationRenewClientSecretError(): void {
    this.openSnackbar(
      'labels.subscriptions.details.snackbar.renewFailureClientSecret'
    );
  }

  public downloadFail(): void {
    this.openSnackbar('labels.subfolder.listView.snackbar.downloadFail');
  }

  public handleStatusUpdateError(error: HttpErrorResponse): void {
    if (error.status === 409) {
      this.dataHasChanged();
    } else {
      this.unknownError();
    }
  }
}
