<div tabindex="0" aria-labelledby="boxTitle" class="modal-content-user">
  <div class="header-section">
    <div class="header">
        <i class="fas fa-file-excel"></i>
      <h5 mat-dialog-title>
        <div id="boxTitle" class="title">
         {{ "labels.users.addNew.addExcelUsers" | translate }}
        </div>
      </h5>
    </div>
     <div class="close-modal">
      <i
        tabindex="-1"
        aria-label="fermer la fenetre"
        mat-dialog-close="cancel"
        class="fas fa-times"
      ></i>
    </div> 
  </div>
  <div class="content">
      <div class="titleExcelfile">
        <div *ngIf="(isAttachmentsFormatsAccepted())" id="format-error-message" >
          <i *ngIf="spinnerEnabled" class="loaderSpinnerExcelImport"></i>
          <div *ngIf="!spinnerEnabled">
          </div>
          <div *ngIf="(isErrorOnServer)">
            {{textToDisplay}}
          </div>
          <div *ngIf="(!isErrorOnServer)">
            {{textToDisplay}}
          </div>
        </div>

       
        <div *ngIf="(!isAttachmentsFormatsAccepted())" id="format-error-message" >
        {{
          "labels.subfolder.details.attachments.errorUploadingAttachmentFormatMessagePartExcel"
            | translate
        }}
        {{
          "labels.subfolder.details.attachments.errorUploadingAttachmentFormatMessagePart3"
            | translate
        }}
        <div *ngIf="(!isAttachmentsFormatsAccepted())" id="format-error-message" >
          {{
            "labels.subfolder.details.attachments.errorUploadingAttachmentFormatMessagePartExcel"
              | translate
          }}
          {{
            "labels.subfolder.details.attachments.errorUploadingAttachmentFormatMessagePart3"
              | translate
          }}

        
          
        
      </div>

  </div>
</div>

