import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { User } from 'src/app/shared/models/user';

@Injectable({
  providedIn: 'root',
})
export class UsersCsvConverterService {
  constructor(private datePipe: DatePipe) {}

  public toCsv(users: Array<User>): string {
    return this.header() + '\r\n' + this.body(users);
  }

  private header(): string {
    const headers: Array<string> = [
      "Type d'organisation",
      "CompanyRegister de l'organisation",
      "BranchCode de l'organisation",
      "Nom de l'organisation",
      'User ID',
      'Login',
      'Civilité',
      'Fonction',
      'Nom',
      'Prénom',
      'Email',
      'Téléphone principal',
      'Téléphone secondaire',
      "Type d'utilisateur",
      'Habilitations démarches',
      'Rôles',
      'Statut',
      'Compte verrouillé',
      'N° Datapass',
      'Date de creation',
      'Date de désactivation',
      'Date de modification',
    ];
    return headers.join(';');
  }

  private body(users: Array<User>): string {
    return users.map((sub) => this.itemToCsv(sub)).join('\r\n');
  }

  private itemToCsv(user: User): string {
    let values: Array<string> = [];
    let dayMonthYear: Array<string> = [];
    values.push(user.organization?.type);

    values.push(user.organization?.siret);
    values.push(user.organization?.branchCode);
    values.push(user.organization?.name);
    values.push(user.userID.toString());
    values.push(user.login);
    values.push(user.title);
    values.push(user.function);
    values.push(user.lastName);
    values.push(user.firstName);
    values.push(user.email);
    values.push(user.phoneNumber);
    values.push(user.mobilePhoneNumber);
    values.push(user.userType);
    // Habilitations démarches
    let processPermissions = '';
    user.processPermissions.forEach((permission) => {
      processPermissions += permission + ' & ';
    });
    processPermissions = processPermissions.substring(
      0,
      processPermissions.length - 2
    );
    values.push(processPermissions);
    // fin Habilitations démarches
    // Rôles utilisateur
    let userRoles = '';
    user.userRoles.forEach((userRole) => {
      userRoles += userRole.display + ' & ';
    });
    userRoles = userRoles.substring(0, userRoles.length - 2);
    values.push(userRoles);
    // Fin rôles utilisateur

    values.push(user.activated ? 'activé' : 'désactivé');

    values.push(user.accountLocked ? 'Oui' : 'Non');

    values.push(user.datapassAccount ? 'Oui' : 'Non');

    // Date de création
    if (user.creationTimestamp != null) {
      dayMonthYear = user.creationTimestamp.split('T');
      values.push(dayMonthYear[0]);
    } else {
      values.push(user.creationTimestamp);
    }
    // Fin date de création

    // Date de modification
    if (user.activated) {
      values.push('');
    } else {
      if (user.updateTimestamp) {
        dayMonthYear = user.updateTimestamp.split('T');
        values.push(dayMonthYear[0]);
      } else {
        values.push('');
      }
    }
    if (user.updateTimestamp != null) {
      dayMonthYear = user.updateTimestamp.split('T');
      values.push(dayMonthYear[0]);
    } else {
      values.push(user.updateTimestamp);
    }
    // Fin date de modification
    return values.join(';');
  }
}
