export interface UserType {
  code: string;
  name: string;
}

export const userTypeList: UserType[] = [
  { code: 'ADM_NAT', name: 'Administrateur national' },
  { code: 'ADM_MET', name: 'Administrateur métier' },
  { code: 'ADM_LOC', name: 'Administrateur local' },
  { code: 'DEL_TEC', name: 'Délégué technique' },
  { code: 'AGT', name: 'Agent' },
  // { code: 'INV', name: 'Visiteur' },
];

export const userTypeFilterList: UserType[] = [
  { code: 'ADM_LOC', name: 'Administrateur local' },
  { code: 'AGT', name: 'Agent' },
];

export const userTypeListForTechDel: UserType[] = [
  { code: 'DEL_TEC', name: 'Délégué technique' },
  { code: 'ADM_LOC', name: 'Administrateur local' },
  { code: 'AGT', name: 'Agent' },
];

export const userTypeListForAgtAndAdmLoc: UserType[] = [
  { code: 'ADM_LOC', name: 'Administrateur local' },
  { code: 'AGT', name: 'Agent' },
];

export const userTypeListAdministration: UserType[] = [
  { code: 'ADM_NAT', name: 'Administrateur national' },
  { code: 'ADM_MET', name: 'Administrateur métier' },
  { code: 'ADM_LOC', name: 'Administrateur local' },
  { code: 'DEL_TEC', name: 'Délégué technique' },
  { code: 'AGT', name: 'Agent' },
];
export const userTypeListAdministrationForBusinessAdmUser: UserType[] = [
  { code: 'ADM_MET', name: 'Administrateur métier' },
  { code: 'ADM_LOC', name: 'Administrateur local' },
  { code: 'DEL_TEC', name: 'Délégué technique' },
  { code: 'AGT', name: 'Agent' },
];
