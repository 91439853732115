<div class="detailed-page-container user-detailed-page-container">
  <section class="section__page-header rf-container-fluid">
    <div class="head rf-grid-row">
      <div>
        <a
          id="{{
            isUsersAdminPage
              ? 'ADMIN-CONSULT-USER-RETOUR'
              : 'CONSULT-USER-RETOUR'
          }}"
          aria-labelledby="retour"
          title="retour"
          class="goback rf-col-xs-6"
          (click)="goBack()"
          ><i aria-hidden="true" class="fa fa-chevron-left"></i>
          {{ "labels.users.userDetails.usergoBack" | translate }}</a
        >
      </div>
    </div>
    <!-- ICON DROITE -->
    <div class="content rf-grid-row rf-grid-row--top">
      <div class="left rf-col-xs-3 rf-col-md-2 icon">
        <i
          aria-hidden="true"
          id="{{
            isUsersAdminPage
              ? 'ADMIN-CONSULT-USER-ICO-ECR'
              : 'CONSULT-USER-ICO-ECR'
          }}"
          class="fas fa-users-cog iconsize"
        ></i>
      </div>
      <div class="center rf-col-xs-8 rf-col-md-6 rf-col-lg-7" *ngIf="user">
        <div class="rf-grid-row rf-grid-row--center">
          <div class="box1 rf-col-12">
            <p class="process userAccount">
              <span
                id="{{
                  isUsersAdminPage
                    ? 'ADMIN-CONSULT-USER-TITRE-ECR'
                    : 'CONSULT-USER-TITRE-ECR'
                }}"
                class="label accountuserlabel"
                >{{ "labels.users.header.userAccount" | translate }}</span
              ><span
                id="{{
                  isUsersAdminPage
                    ? 'ADMIN-CONSULT-USER-FIRSTNAME'
                    : 'CONSULT-USER-FIRSTNAME'
                }}"
                class="process--value bold"
                >{{ user.firstName + " " }}</span
              >
              <span
                id="{{
                  isUsersAdminPage
                    ? 'ADMIN-CONSULT-USER-LASTNAME'
                    : 'CONSULT-USER-LASTNAME'
                }}"
                class="process--value bold"
                >{{ user.lastName }}</span
              >
            </p>
            <p class="requester">
              <span
                id="{{
                  isUsersAdminPage
                    ? 'ADMIN-CONSULT-USER-SOUS-TITRE-ECR'
                    : 'CONSULT-USER-SOUS-TITRE-ECR'
                }}"
                class="label"
                >{{ "labels.users.header.manageAccount" | translate }}</span
              >
            </p>
            <p
              id="{{
                isUsersAdminPage ? 'ADMIN-CONSULT-USER-SI' : 'CONSULT-USER-SI'
              }}"
              *ngIf="user.organization.name"
              class="si"
            >
              <span class="label">{{
                user.organization.type === "SI"
                  ? ("labels.users.header.SI" | translate)
                  : ("labels.users.header.organization" | translate)
              }}</span
              ><span class="subfolder-info">{{
                user.organization.name +
                  " (N° SIRET : " +
                  user.organization.siret +
                  ")"
              }}</span>
              <i
                aria-hidden="true"
                *ngIf="isUsersAdminPage"
                class="edit-pen fas fa-pen"
                (click)="openUpdateUserOrganization()"
              ></i>
            </p>
            <p *ngIf="isOrganizationEmpty" class="si">
              <span class="label">{{
                "labels.users.header.organization" | translate
              }}</span
              ><span class="subfolder-info"> - </span>
              <i
                aria-hidden="true"
                *ngIf="isUsersAdminPage"
                class="edit-pen fas fa-pen"
                (click)="openUpdateUserOrganization()"
              ></i>
            </p>
          </div>
          <div
            id="{{
              isUsersAdminPage
                ? 'ADMIN-CONSULT-USER-LOGIN'
                : 'CONSULT-USER-LOGIN'
            }}"
            class="box2 rf-col-12"
          >
            <span class="label">{{
              "labels.users.header.email" | translate
            }}</span
            ><span *ngIf="!editUserEmail" class="subfolder-info">{{
              user.email
            }}</span>
            <ng-container [formGroup]="updateUserEmailFormGroup">
              <mat-form-field
                class="editUserEmailField"
                *ngIf="editUserEmail && !isOrganizationEmpty"
                appearance="fill"
              >
                <mat-label aria-label="Entrez un email"
                  >{{ "labels.users.header.email" | translate }}
                </mat-label>
                <input
                  id="{{
                    isUsersAdminPage
                      ? 'ADMIN-CONSULT-USER-LOGIN'
                      : 'CONSULT-USER-LOGIN'
                  }}"
                  matInput
                  [disabled]="isOrganizationEmpty"
                  placeholder="Email"
                  type="email"
                  (keyup)="onUserEmailChanged($event)"
                  formControlName="emailFormControl"
                />
                <button
                  class="reset-email"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Fermer"
                  (click)="resetFieldUserEmail()"
                  *ngIf="!displayLoader"
                >
                  <mat-icon>close</mat-icon>
                </button>
                <button
                  class="validate-email"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Valider"
                  (click)="updateUserEmail()"
                  *ngIf="!displayLoader"
                >
                  <mat-icon>done</mat-icon>
                </button>
                <i
                  aria-hidden="true"
                  matSuffix
                  *ngIf="displayLoader"
                  class="fas fa-spinner fa-spin"
                ></i>
                <mat-error
                  *ngIf="
                    updateUserEmailFormGroup
                      .get('emailFormControl')
                      .hasError('alreadyExists')
                  "
                >
                  {{ "labels.users.header.emailAlreadyExists" | translate }}
                </mat-error>
                <mat-error
                  *ngIf="
                    updateUserEmailFormGroup
                      .get('emailFormControl')
                      .hasError('required')
                  "
                >
                  {{ "labels.users.header.requiredEmail" | translate }}
                </mat-error>
                <mat-error
                  *ngIf="
                    updateUserEmailFormGroup
                      .get('emailFormControl')
                      .hasError('email')
                  "
                >
                  {{ "labels.users.header.invalidEmail" | translate }}
                </mat-error>
              </mat-form-field>
            </ng-container>
            <i
              aria-hidden="true"
              *ngIf="
                isUsersAdminPage &&
                canUserEditEmail &&
                !editUserEmail &&
                isNotMe
              "
              class="edit-pen fas fa-pen"
              (click)="editUserEmail = true"
            ></i>
          </div>
          <div
            id="{{
              isUsersAdminPage
                ? 'ADMIN-CONSULT-USER-DATAPASS'
                : 'CONSULT-USER-DATAPASS'
            }}"
            class="box3 rf-col-12"
          >
            <span class="label">{{
              "labels.users.header.datapassAccount" | translate
            }}</span
            ><span class="subfolder-info">{{ isDatapassAccountValue }}</span>
          </div>
        </div>
      </div>
      <div class="button-wrapper rf-col-xs-3 rf-col-md-2">
        <div>
          <button
            id="{{
              isUsersAdminPage
                ? 'ADMIN-CONSULT-USER-BT-DISABLE'
                : 'CONSULT-USER-BT-DISABLE'
            }}"
            aria-label="activer le compte"
            role="button"
            class="accountActive main-button bold activateButton"
            mat-stroked-button
            [disabled]="isOrganizationEmpty || isAccountActivateLoading"
            *ngIf="!hidden"
            (click)="accountActivate(true)"
          >
            <i
              *ngIf="!isAccountActivateLoading"
              class="fa fa-check-circle"
              aria-hidden="true"
            ></i>
            <i
              aria-hidden="true"
              matSuffix
              *ngIf="isAccountActivateLoading"
              class="fas fa-spinner fa-spin"
            ></i>
            <span class="button-label">
              {{ "labels.users.header.accountActivate" | translate }}</span
            >
          </button>
        </div>
        <div>
          <button
            id="{{
              isUsersAdminPage
                ? 'ADMIN-CONSULT-USER-BT-ENABLE'
                : 'CONSULT-USER-BT-ENABLE'
            }}"
            aria-label="désactiver le compte"
            role="button"
            class="disableAccount main-button bold activateButton"
            mat-stroked-button
            [disabled]="isOrganizationEmpty || isAccountActivateLoading"
            *ngIf="hidden"
            (click)="accountActivate(false)"
          >
            <i
              *ngIf="!isAccountActivateLoading"
              class="fa fa-ban fa-7x"
              aria-hidden="true"
            ></i>
            <i
              aria-hidden="true"
              matSuffix
              *ngIf="isAccountActivateLoading"
              class="fas fa-spinner fa-spin"
            ></i>
            <span class="button-label">
              {{ "labels.users.header.disableAccount" | translate }}</span
            >
          </button>
        </div>
        <div *ngIf="isUsersAdminPage && (isAdmNat || isAdmMet)">
          <button
            id="{{
              isUsersAdminPage
                ? 'ADMIN-CONSULT-USER-BT-DELETE'
                : 'CONSULT-USER-BT-DELETE'
            }}"
            aria-label="supprimer le compte"
            role="button"
            class="deleteUser main-button bold activateButton"
            mat-stroked-button
            [disabled]="isAccountDeleteLoading"
            *ngIf="canDeleteUser()"
            (click)="deleteUser()"
          >
            <i
              *ngIf="!isAccountDeleteLoading"
              class="fa fa-trash"
              aria-hidden="true"
            ></i>
            <i
              aria-hidden="true"
              matSuffix
              *ngIf="isAccountDeleteLoading"
              class="fas fa-spinner fa-spin"
            ></i>
            <span class="button-label">
              {{ "labels.users.header.deleteAccount" | translate }}</span
            >
          </button>
        </div>
        <div>
          <button
            *ngIf="isUsersAdminPage && isUserAccountLocked && isAdmNat"
            id="{{
              isUsersAdminPage
                ? 'ADMIN-CONSULT-USER-BT-UNLOCK'
                : 'CONSULT-USER-BT-UNLOCK'
            }}"
            aria-label="Déverrouiller le compte"
            role="button"
            class="mt-4 main-button bold unlockAccount"
            mat-stroked-button
            [disabled]="isUserUnlockAccountLoading"
            (click)="unlockAccount()"
          >
            <div *ngIf="isUserUnlockAccountLoading; else elseBlock">
              <i class="fas fa-spinner fa-spin" aria-hidden="true"></i>
            </div>
            <ng-template #elseBlock>
              <i class="fa fa-unlock fa-7x" aria-hidden="true"></i>
            </ng-template>
            <span class="button-label">
              {{ "labels.users.header.unlockAccount" | translate }}</span
            >
          </button>
        </div>
      </div>
    </div>
  </section>

  <!-- SECOND SECTION -->
  <form
    class="new-user"
    [formGroup]="updatedUserForm"
    (ngSubmit)="updateUserInformations()"
  >
    <section class="section__page-content">
      <!-- FORMS SECTION -->
      <section class="section__attachments">
        <mat-card class="rf-container-fluid" *ngIf="user">
          <p
            *ngIf="isOrganizationEmpty"
            [translate]="
              'labels.users.privateInformations.errorEmptyOrganization'
            "
            class="error"
          ></p>
          <mat-card-title
            id="{{
              isUsersAdminPage
                ? 'ADMIN-CONSULT-USER-TITRE-SECTION-INFOS'
                : 'CONSULT-USER-TITRE-SECTION-INFOS'
            }}"
            class="bold title"
            >{{ "labels.users.privateInformations.title" | translate }}
          </mat-card-title>
          <mat-card-content class="rf-grid-row">
            <div>
              <div class="form-content">
                <div class="civility-infos">
                  <mat-form-field
                    id="{{
                      isUsersAdminPage
                        ? 'ADMIN-CONSULT-USER-CIVILITE'
                        : 'CONSULT-USER-CIVILITE'
                    }}"
                    class="title-field"
                    appearance="fill"
                  >
                    <mat-label
                      id="title-field"
                      aria-label="choisissez la civilité"
                      >{{ "labels.users.userDetails.civility" | translate }}
                    </mat-label>
                    <mat-select
                      [disabled]="isOrganizationEmpty"
                      (selectionChange)="onTitleChanged($event)"
                      formControlName="titleFormControl"
                      [errorStateMatcher]="matcher"
                    >
                      <mat-option
                        *ngFor="let title of titleList"
                        [value]="title"
                        [disabled]="isOrganizationEmpty"
                        >{{ title }}</mat-option
                      >
                    </mat-select>
                    <mat-error
                      *ngIf="
                        updatedUserForm
                          .get('titleFormControl')
                          .hasError('required')
                      "
                      class="titleError"
                      id="Civilité requise"
                      style="display: flex"
                    >
                      {{ "labels.users.addNew.requiredTitle" | translate }}
                    </mat-error>
                  </mat-form-field>

                  <div class="form-group">
                    <mat-form-field
                      id="{{
                        isUsersAdminPage
                          ? 'ADMIN-CONSULT-USER-FIRSTNAME'
                          : 'CONSULT-USER-FIRSTNAME'
                      }}"
                      class="first__name"
                      appearance="outline"
                    >
                      <mat-label aria-label="entrez le prénom"
                        >{{ "labels.users.userDetails.firstName" | translate }}
                      </mat-label>
                      <input
                        id="{{
                          isUsersAdminPage
                            ? 'ADMIN-CONSULT-USER-FIRSTNAME'
                            : 'CONSULT-USER-FIRSTNAME'
                        }}"
                        matInput
                        [disabled]="isOrganizationEmpty"
                        placeholder=""
                        name="firstName"
                        type="text"
                        (keyup)="onFirstNameChanged($event)"
                        formControlName="firstNameFormControl"
                        [errorStateMatcher]="matcher"
                      />
                      <mat-error
                        id="Prénom requis"
                        *ngIf="
                          updatedUserForm
                            .get('firstNameFormControl')
                            .hasError('required')
                        "
                      >
                        {{
                          "labels.users.addNew.requiredFirstName" | translate
                        }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <mat-form-field
                    id="{{
                      isUsersAdminPage
                        ? 'ADMIN-CONSULT-USER-LASTNAME'
                        : 'CONSULT-USER-LASTNAME'
                    }}"
                    class="last__name"
                    appearance="outline"
                  >
                    <mat-label aria-label="entrez le nom"
                      >{{ "labels.users.userDetails.lastName" | translate }}
                    </mat-label>

                    <input
                      id="{{
                        isUsersAdminPage
                          ? 'ADMIN-CONSULT-USER-LASTNAME'
                          : 'CONSULT-USER-LASTNAME'
                      }}"
                      matInput
                      placeholder=""
                      name="lastName"
                      [disabled]="isOrganizationEmpty"
                      type="text"
                      (keyup)="onLastNameChanged($event)"
                      formControlName="lastNameFormControl"
                      [errorStateMatcher]="matcher"
                    />
                    <mat-error
                      id="Nom requis"
                      *ngIf="
                        updatedUserForm
                          .get('lastNameFormControl')
                          .hasError('required')
                      "
                    >
                      {{ "labels.users.addNew.requiredLastName" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="function">
                  <mat-form-field
                    id="{{
                      isUsersAdminPage
                        ? 'ADMIN-CONSULT-USER-FONCTION'
                        : 'CONSULT-USER-FONCTION'
                    }}"
                    class="function-field"
                    appearance="outline"
                  >
                    <mat-label aria-label="entrez la fonction"
                      >{{ "labels.users.userDetails.function" | translate }}
                    </mat-label>
                    <input
                      id="{{
                        isUsersAdminPage
                          ? 'ADMIN-CONSULT-USER-FONCTION'
                          : 'CONSULT-USER-FONCTION'
                      }}"
                      [disabled]="isOrganizationEmpty"
                      formControlName="functionFormControl"
                      (keyup)="onFunctionChanged($event)"
                      name="function"
                      matInput
                    />
                    <mat-error
                      *ngIf="
                        updatedUserForm
                          .get('functionFormControl')
                          .hasError('required')
                      "
                    >
                      {{ "labels.users.addNew.requiredFunction" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="phone-details">
                  <mat-form-field
                    id="{{
                      isUsersAdminPage
                        ? 'ADMIN-CONSULT-USER-TEL-PRINCIPALE'
                        : 'CONSULT-USER-TEL-PRINCIPALE'
                    }}"
                    class="phone-field"
                    appearance="outline"
                  >
                    <mat-label aria-label="entrez votre numéro de téléphone">
                      {{
                        "labels.users.userDetails.phoneNumber" | translate
                      }}</mat-label
                    >
                    <input
                      id="{{
                        isUsersAdminPage
                          ? 'ADMIN-CONSULT-USER-TEL-PRINCIPALE'
                          : 'CONSULT-USER-TEL-PRINCIPALE'
                      }}"
                      (keyup)="onPhoneNumberChanged($event)"
                      matInput
                      [disabled]="isOrganizationEmpty"
                      placeholder=""
                      name="phoneNumber"
                      type="tel"
                      formControlName="phoneNumberFormControl"
                      [errorStateMatcher]="matcher"
                    />
                    <mat-icon matSuffix>phone</mat-icon>
                    <mat-error
                      *ngIf="
                        updatedUserForm
                          .get('phoneNumberFormControl')
                          .hasError('pattern')
                      "
                    >
                      {{ "labels.users.addNew.validPhoneNumber" | translate }}
                    </mat-error>
                    <mat-error
                      *ngIf="
                        updatedUserForm
                          .get('phoneNumberFormControl')
                          .hasError('required')
                      "
                      id="N° de téléphone requis"
                      class="titleError"
                      style="display: flex"
                      class="error-accessMode"
                    >
                      {{
                        "labels.users.addNew.requiredPhoneNumber" | translate
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    id="{{
                      isUsersAdminPage
                        ? 'ADMIN-CONSULT-USER-TEL-SECOND'
                        : 'CONSULT-USER-TEL-SECOND'
                    }}"
                    class="phone-field mobile"
                    appearance="outline"
                  >
                    <mat-label
                      aria-label="entrez votre numéro de téléphone secondaire"
                    >
                      {{
                        "labels.users.userDetails.mobilePhoneNumber" | translate
                      }}</mat-label
                    >
                    <input
                      id="{{
                        isUsersAdminPage
                          ? 'ADMIN-CONSULT-USER-TEL-SECOND'
                          : 'CONSULT-USER-TEL-SECOND'
                      }}"
                      (keyup)="onMobileNumberChanged($event)"
                      matInput
                      placeholder=""
                      name="mobilephone"
                      [disabled]="isOrganizationEmpty"
                      type="tel"
                      formControlName="mobilePhoneNumberFormControl"
                      [errorStateMatcher]="matcher"
                    />
                    <mat-icon matSuffix>phone</mat-icon>
                    <mat-error
                      *ngIf="
                        updatedUserForm
                          .get('mobilePhoneNumberFormControl')
                          .hasError('required')
                      "
                    >
                      {{
                        "labels.users.addNew.requiredPhoneNumber" | translate
                      }}
                    </mat-error>
                    <mat-error
                      *ngIf="
                        !updatedUserForm
                          .get('mobilePhoneNumberFormControl')
                          .hasError('required') &&
                        updatedUserForm
                          .get('mobilePhoneNumberFormControl')
                          .hasError('pattern')
                      "
                    >
                      {{ "labels.users.addNew.validPhoneNumber" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div
              *ngIf="isPersonalInformationsModifying"
              class="button-wrapper saveButton"
            >
              <div>
                <button
                  id="{{
                    isUsersAdminPage
                      ? 'ADMIN-CONSULT-USER-BT-SAVE'
                      : 'CONSULT-USER-BT-SAVE'
                  }}"
                  type="submit"
                  aria-label="Enregistrer les informations"
                  [disabled]="
                    !updatedUserForm.valid ||
                    isRolesListEmpthy ||
                    isProcessesListEmpthy ||
                    isOrganizationEmpty
                  "
                  role="button"
                  class="saveInformations main-button bold"
                  mat-stroked-button
                >
                  <i class="fa fa-save fa-lg" aria-hidden="true"></i
                  ><span class="button-label">
                    {{
                      "labels.users.privateInformations.saveModifications"
                        | translate
                    }}</span
                  >
                </button>
              </div>

              <button
                id="{{
                  isUsersAdminPage
                    ? 'ADMIN-CONSULT-USER-BT-ANNULER'
                    : 'CONSULT-USER-BT-ANNULER'
                }}"
                aria-label="annuler"
                role="button"
                type="button"
                [disabled]="isOrganizationEmpty"
                mat-stroked-button
                class="cancelButton main-button bold"
                (click)="onCancelUpdating('PersonalInformations')"
              >
                <span class="button-label">{{
                  "labels.users.permissions.cancel" | translate
                }}</span>
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </section>
      <section class="section__attachments">
        <mat-card class="rf-container-fluid">
          <mat-card-title
            id="{{
              isUsersAdminPage
                ? 'ADMIN-CONSULT-USER-TITRE-SECTION-ROLES'
                : 'CONSULT-USER-TITRE-SECTION-ROLES'
            }}"
            class="bold title"
            >{{ "labels.users.permissions.title" | translate }}
          </mat-card-title>
          <mat-card-content class="rf-grid-row">
            <div class="form-content">
              <div class="type-group">
                <mat-form-field
                  id="{{
                    isUsersAdminPage
                      ? 'ADMIN-CONSULT-USER-TYPE-ACTEUR'
                      : 'CONSULT-USER-TYPE-ACTOR'
                  }}"
                  class="user-type"
                  appearance="fill"
                >
                  <mat-label aria-label="entrez le type utilisateur">
                    {{
                      "labels.users.userDetails.userType" | translate
                    }}</mat-label
                  >
                  <mat-select
                    [disabled]="isOrganizationEmpty"
                    formControlName="userTypeFormControl"
                    (selectionChange)="onUserTypeChanged($event)"
                  >
                    <mat-option
                      [disabled]="isOrganizationEmpty"
                      *ngFor="let userType of userTypeList"
                      [value]="userType"
                    >
                      {{ userType }}</mat-option
                    >
                  </mat-select>
                  <mat-error
                    id="Type d'acteur requis"
                    *ngIf="
                      updatedUserForm
                        .get('phoneNumberFormControl')
                        .hasError('required')
                    "
                  >
                    {{
                      "labels.users.userDetails.userTypeRequired" | translate
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="roleMargin">
                <mat-form-field
                  id="{{
                    isUsersAdminPage
                      ? 'ADMIN-CONSULT-USER-ROLES'
                      : 'CONSULT-USER-ROLES'
                  }}"
                  class="process-permission-chip-list chips-width"
                >
                  <mat-label aria-label="entrez les roles utilisateur"
                    >{{ "labels.users.userDetails.roles" | translate }}
                  </mat-label>
                  <mat-chip-list
                    #chipListRoles
                    aria-label="La selection des habilitations"
                  >
                    <mat-chip
                      *ngFor="let role of userRoles"
                      [selectable]="selectable"
                      (removed)="removeRole(role)"
                      [disabled]="isOrganizationEmpty"
                    >
                      {{ role }}
                      <mat-icon matChipRemove *ngIf="!isDefaultRole(role)"
                        >cancel</mat-icon
                      >
                    </mat-chip>
                    <input
                      id="{{
                        isUsersAdminPage
                          ? 'ADMIN-CONSULT-USER-ROLES'
                          : 'CONSULT-USER-ROLES'
                      }}"
                      placeholder="{{
                        'labels.users.addNew.rolePlaceholder' | translate
                      }}"
                      #roleInput
                      [disabled]="isOrganizationEmpty"
                      [formControl]="roleFormControl"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipListRoles"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (focusout)="cleanRoleAndProcessPermissionsInpusField()"
                    />
                  </mat-chip-list>
                  <mat-error>
                    {{ "labels.users.userDetails.required" | translate }}
                  </mat-error>

                  <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="selectedRole($event)"
                  >
                    <mat-option *ngIf="isEmptyUserRoleList()"></mat-option>
                    <ng-container *ngFor="let role of filteredRoles | async">
                      <ng-container *ngIf="userRoles">
                        <mat-option
                          *ngIf="!userRoles.includes(role)"
                          [value]="role"
                        >
                          {{ role }}
                        </mat-option>
                      </ng-container>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div *ngIf="isUserAgent">
                <mat-form-field
                  id="{{
                    isUsersAdminPage
                      ? 'ADMIN-CONSULT-USER-HABILITATIONS'
                      : 'CONSULT-USER-HABILITATIONS'
                  }}"
                  class="process-permission-chip-list chips-width"
                >
                  <mat-label aria-label="entrez les types de démarches">
                    {{
                      "labels.users.addNew.processPermission" | translate
                    }}</mat-label
                  >
                  <mat-chip-list
                    #chipListProcesses
                    aria-label="La selection des habilitations"
                  >
                    <mat-chip
                      *ngFor="let process of processPermissions"
                      [selectable]="selectable"
                      [removable]="removable"
                      (removed)="remove(process)"
                      [disabled]="isOrganizationEmpty"
                    >
                      {{ process }}
                      <mat-icon matChipRemove *ngIf="removable"
                        >cancel</mat-icon
                      >
                    </mat-chip>
                    <input
                      id="{{
                        isUsersAdminPage
                          ? 'ADMIN-CONSULT-USER-HABILITATIONS'
                          : 'CONSULT-USER-HABILITATIONS'
                      }}"
                      placeholder="{{
                        'labels.users.addNew.processPermisionPlaceholder'
                          | translate
                      }}"
                      #processInput
                      [formControl]="processPermissionFormControl"
                      [matAutocomplete]="auto"
                      [disabled]="isOrganizationEmpty"
                      [matChipInputFor]="chipListProcesses"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (focusout)="cleanRoleAndProcessPermissionsInpusField()"
                    />
                  </mat-chip-list>
                  <mat-error>
                    {{ "labels.users.userDetails.required" | translate }}
                  </mat-error>
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="selected($event)"
                  >
                    <mat-option
                      *ngIf="
                        !processPermissions || isEmptyProcessPermissionsList()
                      "
                    ></mat-option>
                    <ng-container
                      *ngFor="let process of filteredProcessPermissions | async"
                    >
                      <mat-option
                        *ngIf="
                          processPermissions &&
                          !processPermissions.includes(process)
                        "
                        [value]="process"
                      >
                        {{ process }}
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>

            <div
              *ngIf="isRolesAndAccessModifying"
              class="button-wrapper saveButton"
            >
              <button
                id="{{
                  isUsersAdminPage
                    ? 'ADMIN-CONSULT-USER-BT-SAVE'
                    : 'CONSULT-USER-BT-SAVE'
                }}"
                type="submit"
                aria-label="Enregistrer les informations"
                [disabled]="
                  !updatedUserForm.valid ||
                  isRolesListEmpthy ||
                  isProcessesListEmpthy ||
                  isOrganizationEmpty
                "
                role="button"
                class="saveInformations main-button bold"
                mat-stroked-button
              >
                <i class="fa fa-save fa-lg" aria-hidden="true"></i
                ><span class="button-label">
                  {{
                    "labels.users.privateInformations.saveModifications"
                      | translate
                  }}</span
                >
              </button>

              <button
                id="{{
                  isUsersAdminPage
                    ? 'ADMIN-CONSULT-USER-BT-ANNULER'
                    : 'CONSULT-USER-BT-ANNULER'
                }}"
                aria-label="annuler"
                role="button"
                type="button"
                mat-stroked-button
                class="cancelButton main-button bold"
                (click)="onCancelUpdating('RolesAndAccess')"
                [disabled]="isOrganizationEmpty"
              >
                <span class="button-label">{{
                  "labels.users.permissions.cancel" | translate
                }}</span>
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </section>
    </section>
  </form>
</div>
