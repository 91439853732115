import { chaineEnBooleen } from 'src/app/shared/utils/utility-functions';

//This file can be replaced during build by using the `fileReplacements` array.
export const environment = {
  production:
    window['env'] && window['env']['production']
      ? window['env']['production']
      : false,
  apiUrl:
    window['env'] && window['env']['apiUrl']
      ? window['env']['apiUrl']
      : 'default',
  authUrl:
    window['env'] && window['env']['authUrl']
      ? window['env']['authUrl']
      : 'default',
  clientId:
    window['env'] && window['env']['clientId']
      ? window['env']['clientId']
      : 'default',
  issuer:
    window['env'] && window['env']['issuer']
      ? window['env']['issuer']
      : 'default',
  strictDiscoveryDocumentValidation:
    window['env'] &&
    window['env'][chaineEnBooleen('strictDiscoveryDocumentValidation')]
      ? window['env'][chaineEnBooleen('strictDiscoveryDocumentValidation')]
      : false,
  userinfoEndpoint:
    window['env'] && window['env']['userinfoEndpoint']
      ? window['env']['userinfoEndpoint']
      : 'default',
  scope:
    window['env'] && window['env']['scope']
      ? window['env']['scope']
      : 'default',
  oidc:
    window['env'] && window['env'][chaineEnBooleen('oidc')]
      ? window['env'][chaineEnBooleen('oidc')]
      : true,
  datapassUrl:
    window['env'] && window['env']['datapassUrl']
      ? window['env']['datapassUrl']
      : 'default',
  supervisionActivityUrl:
    window['env'] && window['env']['supervisionActivityUrl']
      ? window['env']['supervisionActivityUrl']
      : 'default',
  supervisionStatsUrl:
    window['env'] && window['env']['supervisionStatsUrl']
      ? window['env']['supervisionStatsUrl']
      : 'default',
  supervisionTechnicalUrl:
    window['env'] && window['env']['supervisionTechnicalUrl']
      ? window['env']['supervisionTechnicalUrl']
      : 'default',
  technicalDocUrl:
    window['env'] && window['env']['technicalDocUrl']
      ? window['env']['technicalDocUrl']
      : 'default',
  functionalDocUrl:
    window['env'] && window['env']['functionalDocUrl']
      ? window['env']['functionalDocUrl']
      : 'default',
  idleTimeInSeconds:
    window['env'] && window['env']['idleTimeInSeconds']
      ? window['env']['idleTimeInSeconds']
      : 'default',
  updatePasswordTryCount:
    window['env'] && window['env']['updatePasswordTryCount']
      ? window['env']['updatePasswordTryCount']
      : 'default',
  emailsCharLimit:
    window['env'] && window['env']['emailsCharLimit']
      ? window['env']['emailsCharLimit']
      : 'default',
  maxOrganization:
    window['env'] && window['env']['maxOrganization']
      ? window['env']['maxOrganization']
      : 'default',
  cguUrl:
    window['env'] && window['env']['cguUrl']
      ? window['env']['cguUrl']
      : 'default',
  accessibiliteUrl:
    window['env'] && window['env']['accessibiliteUrl']
      ? window['env']['accessibiliteUrl']
      : 'default',
  maxResult:
    window['env'] && window['env']['maxResult']
      ? window['env']['maxResult']
      : 10,
};
export const environment_endpoints = {
  api: {
    portal: environment.apiUrl + '/portail-nhube',
    subfolders: environment.apiUrl + '/portail-nhube/subfolders',
    subfoldersAdmin: environment.apiUrl + '/portail-nhube/admin/subfolders',
    users: environment.apiUrl + '/portail-nhube/users',
    usersAdmin: environment.apiUrl + '/portail-nhube/admin/users',
    myAccount: environment.apiUrl + '/portail_nhube/myaccount',
    history: environment.apiUrl + '/portail-nhube/subfolders/history',
    subscriptions: environment.apiUrl + '/portail-nhube/subscriptions',
    subscription_history:
      environment.apiUrl + '/portail-nhube/subscription/history',
    subscriptionsAdmin:
      environment.apiUrl + '/portail-nhube/admin/subscriptions',
    subscription_historyAdmin:
      environment.apiUrl + '/portail-nhube/admin/subscription/history',
    processes: environment.apiUrl + '/portail-nhube/processes',
    processesAdmin: environment.apiUrl + '/portail-nhube/admin/processes',
    organizations: environment.apiUrl + '/portail-nhube/organizations',
    organizationsAdmin:
      environment.apiUrl + '/portail-nhube/admin/organizations',
    admin: environment.apiUrl + '/portail-nhube/admin',
    notificationsAdmin:
      environment.apiUrl + '/portail-nhube/admin/notifications',
  },
};
