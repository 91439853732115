<div class="user-deletion-container">
  <mat-card-content>
    <p>
      {{ dialogBoxMessage }}
    </p>
  </mat-card-content>
  <div class="buttons-container">
    <button
      aria-label="annuler la suppression"
      role="button"
      class="cancel-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="cancel"
    >
      {{ "labels.users.deleteUserDialogBox.cancelButton" | translate }}
    </button>
    <button
      aria-label="confirmer la suppression"
      role="button"
      class="confirm-button"
      mat-button
      mat-dialog-close
      mat-dialog-close="confirm"
    >
      {{ "labels.users.deleteUserDialogBox.confirmButton" | translate }}
    </button>
  </div>
</div>
