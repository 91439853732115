export interface UserRoleLabels {
  code: string;
  name: string;
}

export interface UserRole {
  display: string;
  value: string;
}
export const userRolesList: UserRoleLabels[] = [
  { code: 'admin', name: 'Admin' },
  { code: 'AdminTD', name: 'Administration des télédossiers' },
  { code: 'AdminAbonnements', name: 'Administration des abonnements' },
  { code: 'AdminUtilisateurs', name: 'Administration des utilisateurs' },
  { code: 'AdminReferentiel', name: 'Administration référentiel' },
  { code: 'Parametrage', name: 'Administration contenu institutionnel' },
  { code: 'GestionUtilisateurs', name: 'Gestion des utilisateurs' },
  { code: 'ConsultationUtilisateurs', name: 'Consultation des utilisateurs' },
  { code: 'StatistiquesSupervision', name: 'Statistiques et supervision' },
  { code: 'GestionAbonnements', name: 'Gestion des abonnements' },
  { code: 'ConsultationAbonnements', name: 'Consultation des abonnements' },
  { code: 'GestionTD', name: 'Gestion des télédossiers' },
  { code: 'ConsultationTD', name: 'Consultation des télédossiers' },
];

export const userRolesListMaxBussinessAdmin: UserRoleLabels[] = [
  { code: 'AdminTD', name: 'Administration des télédossiers' },
  { code: 'AdminAbonnements', name: 'Administration des abonnements' },
  { code: 'AdminUtilisateurs', name: 'Administration des utilisateurs' },
  { code: 'ConsultationTD', name: 'Consultation des télédossiers' },
];

export const userRolesListMaxNationalAdmin: UserRoleLabels[] = [
  { code: 'AdminTD', name: 'Administration des télédossiers' },
  { code: 'AdminAbonnements', name: 'Administration des abonnements' },
  { code: 'AdminUtilisateurs', name: 'Administration des utilisateurs' },
  { code: 'AdminReferentiel', name: 'Administration référentiel' },
  // { code: 'Parametrage', name: 'Administration contenu institutionnel' },
  // { code: 'StatistiquesSupervision', name: 'Statistiques et supervision' },
  { code: 'GestionTD', name: 'Gestion des télédossiers' },
  // { code: 'ConsultationTD', name: 'Consultation des télédossiers' },
];

export const userRolesListMaxTechDel: UserRoleLabels[] = [
  { code: 'AdminAbonnements', name: 'Administration des abonnements' },
];
export const userRolesListAgentAndLocalAdm: UserRoleLabels[] = [
  { code: 'GestionUtilisateurs', name: 'Gestion des utilisateurs' },
  { code: 'ConsultationUtilisateurs', name: 'Consultation des utilisateurs' },
  { code: 'GestionAbonnements', name: 'Gestion des abonnements' },
  { code: 'ConsultationAbonnements', name: 'Consultation des abonnements' },
  { code: 'GestionTD', name: 'Gestion des télédossiers' },
  { code: 'ConsultationTD', name: 'Consultation des télédossiers' },
];

export const userRolesListAdminLocal: UserRoleLabels[] = [
  { code: 'GestionUtilisateurs', name: 'Gestion des utilisateurs' },
  { code: 'ConsultationUtilisateurs', name: 'Consultation des utilisateurs' },
  { code: 'GestionAbonnements', name: 'Gestion des abonnements' },
  { code: 'ConsultationAbonnements', name: 'Consultation des abonnements' },
  { code: 'GestionTD', name: 'Gestion des télédossiers' },
  { code: 'ConsultationTD', name: 'Consultation des télédossiers' },
];

export const userRolesListAgent: UserRoleLabels[] = [
  { code: 'ConsultationUtilisateurs', name: 'Consultation des utilisateurs' },
  { code: 'ConsultationAbonnements', name: 'Consultation des abonnements' },
  { code: 'GestionTD', name: 'Gestion des télédossiers' },
];

export const userRolesListBusinessAdmin: UserRoleLabels[] = [
  { code: 'AdminTD', name: 'Administration des télédossiers' },
  { code: 'AdminAbonnements', name: 'Administration des abonnements' },
  { code: 'AdminUtilisateurs', name: 'Administration des utilisateurs' },
  { code: 'GestionUtilisateurs', name: 'Gestion des utilisateurs' },
  { code: 'ConsultationUtilisateurs', name: 'Consultation des utilisateurs' },
  { code: 'GestionAbonnements', name: 'Gestion des abonnements' },
  { code: 'ConsultationAbonnements', name: 'Consultation des abonnements' },
  { code: 'GestionTD', name: 'Gestion des télédossiers' },
  { code: 'ConsultationTD', name: 'Consultation des télédossiers' },
];

export const admMetDefaultRolesList: UserRoleLabels[] = [
  { code: 'AdminTD', name: 'Administration des télédossiers' },
  { code: 'AdminAbonnements', name: 'Administration des abonnements' },
  { code: 'AdminUtilisateurs', name: 'Administration des utilisateurs' },
  { code: 'ConsultationTD', name: 'Consultation des télédossiers' },
];

export const admNatDefaultRolesList: UserRoleLabels[] = [
  { code: 'AdminTD', name: 'Administration des télédossiers' },
  { code: 'AdminAbonnements', name: 'Administration des abonnements' },
  { code: 'AdminUtilisateurs', name: 'Administration des utilisateurs' },
  { code: 'AdminReferentiel', name: 'Administration référentiel' },
  // { code: 'Parametrage', name: 'Administration contenu institutionnel' },
  // { code: 'StatistiquesSupervision', name: 'Statistiques et supervision' },
  { code: 'GestionTD', name: 'Gestion des télédossiers' },
  // { code: 'ConsultationTD', name: 'Consultation des télédossiers' },
];
export const admLocDefaultRolesList: UserRoleLabels[] = [
  { code: 'GestionUtilisateurs', name: 'Gestion des utilisateurs' },
  { code: 'GestionAbonnements', name: 'Gestion des abonnements' },
  { code: 'GestionTD', name: 'Gestion des télédossiers' },
];

export const agtDefaultRolesList: UserRoleLabels[] = [
  { code: 'GestionTD', name: 'Gestion des télédossiers' },
];

export const delTecDefaultRolesList: UserRoleLabels[] = [
  { code: 'AdminAbonnements', name: 'Administration des abonnements' },
];

export const invDefaultRolesList: UserRoleLabels[] = [
  { code: 'ConsultationTD', name: 'Consultation des télédossiers' },
];
