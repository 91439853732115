import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { OrganizationsResponse } from 'src/app/shared/models/organization';
import { Process, ProcessResult } from 'src/app/shared/models/process';
import { User } from 'src/app/shared/models/user';
import { environment_endpoints } from 'src/environments/environment';
import { AbstractService } from '../../subfolders/services/abstract.service';
import { UserRole } from '../models/userRole';
import { UsersFilters } from '../models/usersFilters';
import { UsersResult } from '../models/usersResult';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends AbstractService {
  private headers: HttpHeaders;
  private token = '';
  private urlPortal = environment_endpoints.api.portal;
  private urlUsers = environment_endpoints.api.users;
  private urlUsersAdmin = environment_endpoints.api.usersAdmin;
  private urlAdmin = environment_endpoints.api.admin;

  /**
   * Construteur du service
   *
   * @param httpClient client utilisé pour exécuter des requêtes
   */
  constructor(
    private httpClient: HttpClient,
    private oauthService: OAuthService,
    private router: Router
  ) {
    super();
    this.token = this.oauthService.getIdToken();
    this.headers = new HttpHeaders()
      .append('Authorization', 'Bearer ' + this.token)
      .append('AccessToken', this.oauthService.getAccessToken());
  }

  /**
   * Récupère la liste des users
   */
  public getUsers(): Promise<void | User[]> {
    return this.httpClient
      .get<User[]>(this.urlUsers)
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  public getUsersRoles(): Promise<void | UserRole[]> {
    return this.httpClient
      .get<UserRole[]>(`${this.urlPortal}/groups`, { headers: this.headers })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  public getUsersRolesAdministration(): Promise<void | UserRole[]> {
    return this.httpClient
      .get<UserRole[]>(`${this.urlAdmin}/groups`, { headers: this.headers })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  public getOrganization(): Promise<void | OrganizationsResponse> {
    return this.httpClient
      .get<OrganizationsResponse>(`${this.urlAdmin}/organizations`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Retrieves processes information according to organization.
   *
   * @param orgType - Organization type
   * @param siret - Organization siret
   * @param branchCode - Organization branch code
   * @returns response from endpoint with a list of processes information.
   */
  public async getProcesses(
    orgType: string = null,
    siret: string = null,
    branchCode: string = null
  ): Promise<void | Process[]> {
    let organizationData = '';
    if (orgType != null && siret != null && branchCode != null) {
      organizationData = this.createOrganizationFilterUrl(
        orgType,
        siret,
        branchCode
      );
    }
    return this.httpClient
      .get<Process[]>(
        `${this.urlPortal}/processes/subscribed?${organizationData}`,
        {
          headers: this.headers,
        }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Prepares organization data to be sent to the backend in order to retrieve processes information.
   *
   * @param orgType - Organization type
   * @param siret - Organization siret
   * @param branchCode - Organization branch code
   * @returns string to be sent to the backend
   */
  public createOrganizationFilterUrl(
    orgType: string,
    siret: string,
    branchCode: string
  ): string {
    let url = '';
    const regex = /[\[\]']|"/gm;
    const subst = ``;
    if (orgType != null && siret != null && branchCode != null) {
      url = `type=${orgType}&siret=${siret}&branchCode=${branchCode}`;
      url = url.replace(regex, subst);
    }
    return url;
  }

  /**
   * Retrieves processes information according to organization for admin.
   *
   * @param orgType - Organization type
   * @param siret - Organization siret
   * @param branchCode - Organization branch code
   * @returns response from endpoint with a list of processes information.
   */
  public getProcessesAdmin(
    orgType: string = null,
    siret: string = null,
    branchCode: string = null
  ): Observable<ProcessResult> {
    let organizationData = '';
    if (orgType != null && siret != null && branchCode != null) {
      organizationData = this.createOrganizationFilterUrl(
        orgType,
        siret,
        branchCode
      );
    }
    return this.httpClient.get<ProcessResult>(
      `${this.urlAdmin}/processes/subscribed?${organizationData}`,
      {
        headers: this.headers,
      }
    );
  }

  public getUserEmailUsedState(email: string): Promise<void | boolean> {
    return this.httpClient
      .get<boolean>(`${this.urlUsers}/emailUsed/${email}`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  public getUserEmailUsedStateAdminScreen(
    email: string
  ): Promise<void | boolean> {
    return this.httpClient
      .get<boolean>(`${this.urlUsersAdmin}/emailUsed/${email}`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Retrieves user data from specific user id.
   *
   * @param id - user id to retrieve data from
   */
  public async getUserById(id: string): Promise<void | User> {
    return this.httpClient
      .get<User>(`${this.urlUsers}/${id}`, { headers: this.headers })
      .toPromise()
      .then((response: User) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Retrieves user data from specific user id for administration.
   *
   * @param id - user id to retrieve data from
   */
  public async getUserByIdAdministration(id: string): Promise<void | User> {
    return this.httpClient
      .get<User>(`${this.urlAdmin}/users/${id}`, { headers: this.headers })
      .toPromise()
      .then((response: User) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Updates user information.
   *
   * @param userId - user id to update
   * @param user - new user information
   */
  public async updateUserInformations(
    userId: number,
    user: User
  ): Promise<void | User> {
    return this.httpClient
      .put<User>(`${this.urlUsers}/${userId}`, user, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Updates user information for administration.
   *
   * @param userId - user id to update
   * @param user - new user information
   */
  public async updateUserInformationsAdministration(
    userId: number,
    user: User
  ): Promise<void | User> {
    return this.httpClient
      .put<User>(`${this.urlAdmin}/users/${userId}`, user, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Updates user information.
   *
   * @param userId - user id to update
   * @param user - new user information
   */
  public async unlockUser(userId: number, user: User): Promise<void | User> {
    return this.httpClient
      .put<User>(`${this.urlUsers}/unlock/${userId}`, user, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Updates user information for administration.
   *
   * @param userId - user id to update
   * @param user - new user information
   */
  public async unlockUserAdmin(
    userId: number,
    user: User
  ): Promise<void | User> {
    return this.httpClient
      .put<User>(`${this.urlAdmin}/users/unlock/${userId}`, user, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Patch user information for administration.
   *
   * @param userId - user id to update
   * @param user - new user information
   */
  public patchUserAdminInformations(
    userId: number,
    data: any
  ): Observable<User> {
    return this.httpClient.patch<User>(
      `${this.urlAdmin}/users/${userId}`,
      data,
      {
        headers: this.headers,
      }
    );
  }

  /**
   * Patch user information.
   *
   * @param userId - user id to update
   * @param user - new user information
   */
  public patchUserInformations(userId: number, data: any): Observable<User> {
    return this.httpClient.patch<User>(`${this.urlUsers}/${userId}`, data, {
      headers: this.headers,
    });
  }

  /**
   * Récupère la liste des utilisateurs par query params.
   */
  public getUsersByFilters(url: string): Promise<void | UsersResult> {
    return this.httpClient
      .get<UsersResult>(`${this.urlUsers}?${url}`, { headers: this.headers })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récupère la liste des utilisateurs par query params pour l'administrateur.
   */
  public getUsersByFiltersAdministration(
    url: string
  ): Promise<void | UsersResult> {
    return this.httpClient
      .get<UsersResult>(`${this.urlAdmin}/users?${url}`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récupère la liste des emails par query params pour l'administrateur.
   */
  public getUsersEmailsByFiltersAdministration(
    url: string
  ): Promise<void | string[]> {
    return this.httpClient
      .get<string[]>(`${this.urlAdmin}/users/emails?${url}`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récupère la liste des emails par query params pour l'administrateur.
   */
  public getUsersEmailsByFilters(url: string): Promise<void | string[]> {
    return this.httpClient
      .get<string[]>(`${this.urlUsers}/emails?${url}`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récupère la liste des utilisateurs par query params.
   */
  public getUsersByOrganization(url: string): Promise<void | UsersResult> {
    return this.httpClient
      .get<UsersResult>(`${this.urlUsers}/organization?${url}`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récupère la liste des utilisateurs par query params pour l'administrateur.
   */
  public getUsersByOrganizationAdministration(
    url: string
  ): Promise<void | UsersResult> {
    return this.httpClient
      .get<UsersResult>(`${this.urlAdmin}/users/organization?${url}`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Récupère la liste des utilisateurs par query params pour l'administrateur pour Export.
   */
  public getUsersByFiltersAdministrationExport(
    url: string
  ): Promise<void | UsersResult> {
    return this.httpClient
      .get<UsersResult>(`${this.urlAdmin}` + `/users/exportUsers?${url}`, {
        headers: this.headers,
      })
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Faire une demande de creation de compte d'un utilisateur.
   * @param user l'objet user
   */
  public createNewUser(user: User): Promise<void | User> {
    return this.httpClient
      .post<User>(`${this.urlUsers}`, user, { headers: this.headers })
      .toPromise()
      .then((response: User) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Faire une demande de creation de compte d'un utilisateur pour l'administrateur.
   * @param user l'objet user
   */
  public createNewUserAdministration(user: User): Promise<void | User> {
    return this.httpClient
      .post<User>(`${this.urlAdmin}/users`, user, { headers: this.headers })
      .toPromise()
      .then((response: User) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Faire une demande de creation de compte d'un utilisateur pour l'administrateur.
   * @param user l'objet user
   */
  public deleteUser(userId: number, sendMail: boolean): Promise<void | User> {
    return this.httpClient
      .delete<User>(
        `${this.urlUsers}/delete/` + (sendMail ? 'mail/' : '') + `${userId}`,
        {
          headers: this.headers,
        }
      )
      .toPromise()
      .then((response: User) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Faire une demande de creation de compte d'un utilisateur pour l'administrateur.
   * @param user l'objet user
   */
  public deleteUserAdmin(
    userId: number,
    sendMail: boolean
  ): Promise<void | User> {
    return this.httpClient
      .delete<User>(
        `${this.urlUsersAdmin}/delete/` +
          (sendMail ? 'mail/' : '') +
          `${userId}`,
        {
          headers: this.headers,
        }
      )
      .toPromise()
      .then((response: User) => {
        return response;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  public createImportUserAdministration(file: File): Promise<void | any> {
    const body: FormData = new FormData();
    body.append('file', file);

    return this.httpClient
      .post<any>(`${this.urlAdmin}/users/uploaduserxls`, body, {
        headers: this.headers,
        responseType: 'blob' as 'json',
      })
      .toPromise()
      .then((response: any) => {
        return new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
      });
  }

  /**
   * Récupère les filtres depuis l'URL.
   */
  public getFilters(params: ParamMap): UsersFilters {
    const filters: UsersFilters = new UsersFilters();
    filters.pageSize = +params.get('pageSize');
    filters.pageNumber = +params.get('pageNumber');
    filters.processPermission = params.get('processPermission');
    filters.userRoles = params.get('userRoles');
    filters.userType = params.get('userType');
    filters.searched = params.get('searched');
    filters.sortAttribute = params.get('sortAttribute');
    filters.direction = params.get('direction');
    filters.siret = params.get('siret');
    filters.branchCode = params.get('branchCode');
    filters.type = params.get('type');
    return filters;
  }

  /**
   * Retrieves current user type.
   *
   * @returns current user type
   */
  public getCurrentUserType(): string {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims.user_type;
  }
}
