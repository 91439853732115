import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root',
})
export class ExcelDownloaderService {
  public downloadExcel(excel: string, name: string): void {
    const blob = new Blob([excel], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, name, { autoBom: true });
  }
}