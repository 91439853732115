import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class SupervisionGuard implements CanLoad {
  constructor(private router: Router, private oauthService: OAuthService) {}
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const isAdmNat = this.isAdminNat();
    if (!isAdmNat) {
      this.router.navigate(['/subscriptions']);
    }
    return isAdmNat;
  }

  private isAdminNat() {
    const claims: any = this.oauthService.getIdentityClaims();
    if (claims) {
      return claims.user_type === 'ADM_NAT';
    }
    return false;
  }
}
