<main
  tabindex="0"
  aria-label="page qui liste des utilisateurs"
  class="users-page-content"
>
  <app-users-banner
    [processList]="processesList"
    [isUsersAdminPage]="isUsersAdminPage"
    [isConnectedUserBusinessAdmin]="isConnectedUserBusinessAdmin"
    [connectedUserUserType]="connectedUserUserType"
    [isLoading]="isLoading"
  ></app-users-banner>
  <app-users-filters
    aria-label="section des filtres"
    tabindex="0"
    [dataSource]="dataSource"
    [usersResult]="usersResult"
    [userRolesList]="userRolesList"
    [isUsersAdminPage]="isUsersAdminPage"
    [isUsersPage]="isUsersPage"
    [processList]="processesList"
    [isLoading]="isLoading"
  >
  </app-users-filters>

  <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
  <ng-container *ngIf="shouldDisplayUsersTable">
    <div class="max-100-results" *ngIf="displayPaginationMessage">
      {{ "labels.usersAdmin.paginationMessage" | translate }}
    </div>
    <app-users-table
      tabindex="0"
      aria-label="section liste resultats des utilisateurs"
      [usersResult]="usersResult"
      [isUsersAdminPage]="isUsersAdminPage"
      [isConnectedUserBusinessAdmin]="isConnectedUserBusinessAdmin"
      [organizationsList]="organizationsList"
      (export)="export()"
      [Loading]="Loading"
      [clicked]="clicked"
      [displayPagination]="displayPagination()"
    >
    </app-users-table>
  </ng-container>
</main>
