export class Organization {
  type?: string;
  name: string;
  code?: string;
  siret?: string;
  branchCode?: string;
  country?: string;
  postalCode?: string;
  territory?: string;
  email?: string;
  phoneNumber?: string;
  active?: boolean;
  creationTimestamp?: Date;
  updateTimestamp?: Date;
  clientId?: string;
  clientSecret?: string;
  datapass?: string;
}

export interface OrganizationsResponse {
  organizations: Array<Organization>;
  totalOrganization: number;
}

export class OrganizationFilter {
  type?: string;
  siret: string;
  branchCode?: string;
  name?: string;
  maxResult: number;
}
