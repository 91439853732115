import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AbstractService } from 'src/app/features/subfolders/services/abstract.service';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService extends AbstractService {
  claims: any;

  constructor(private oAuthService: OAuthService) {
    super();
    this.claims = this.oAuthService.getIdentityClaims();
  }

  public getUserPagination(): string {
    if (
      ![undefined, null].includes(this.claims) &&
      this.claims.pagination &&
      this.claims.pagination != 0
    ) {
      return this.claims.pagination;
    } else {
      return '10';
    }
  }

  public getUserIncludeXML(): boolean {
    if (![undefined, null].includes(this.claims) && this.claims.include_xml) {
      return this.claims.include_xml;
    } else {
      return false;
    }
  }
}
