<div class="page-title-area">
  <div class="left-side">
    <i
      id="{{
        isUsersAdminPage ? 'ADMIN-LIST-USER-IC-ECR' : 'LIST-USER-IC-ECR'
      }}"
      class="fas fa-users-cog"
    ></i>
    <div class="title-description">
      <h6>
        <div
          id="{{
            isUsersAdminPage
              ? 'ADMIN-LIST-USER-TITRE-ECR'
              : 'LIST-USER-TITRE-ECR'
          }}"
          tabindex="0"
          aria-label="utilisateurs"
          class="title"
        >
          {{
            isUsersAdminPage
              ? ("labels.usersAdmin.header.title" | translate)
              : ("labels.users.header.title" | translate)
          }}
        </div>
      </h6>
      <div
        id="{{
          isUsersAdminPage
            ? 'ADMIN-LIST-USER-SOUS-TITRE-ECR'
            : 'LIST-USER-SOUS-TITRE-ECR'
        }}"
        tabindex="0"
        aria-label="Gestion des comptes, rôles et habilitations des utilisateurs de votre service instructeur."
      >
        {{
          isUsersAdminPage
            ? ("labels.usersAdmin.header.titleDescription" | translate)
            : ("labels.users.header.titleDescription" | translate)
        }}
      </div>
    </div>
  </div>
  <div *ngIf="!isUsersAdminPage" class="right-side">
    <button
      id="{{
        isUsersAdminPage
          ? 'ADMIN-LIST-USER-BT-NOUVEAU-USER'
          : 'LIST-USER-BT-NOUVEAU-USER'
      }}"
      *ngxPermissionsOnly="['GestionUtilisateurs']"
      tabindex="0"
      aria-label="Ajouter un nouveau utilisateur"
      (click)="onAddNewUser()"
      class="add-user-button"
      mat-flat-button
    >
      {{ "labels.users.header.addNewUserBt" | translate }}
    </button>
  </div>
  <!-- Bouton d'ajout des utilisateurs en masse -->
  <div *ngIf="isUsersAdminPage" class="right-side right-button">
    <button
      *ngxPermissionsOnly="['AdminUtilisateurs']"
      (click)="fileInput.click(); $event.stopPropagation()"
      class="add-user-button"
      mat-flat-button
      id="ADMIN-LIST*USER-BT-IMPORT"
    >
      {{ "labels.users.header.addImportUsers" | translate }}
    </button>
    <input
      tabindex="0"
      id="uploadFileId"
      hidden
      type="file"
      enctype="multipart/form-data"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      #fileInput
      (change)="
        uploadFile($event, $event.target.files, $event.target);
        $event.stopPropagation()
      "
    />
  </div>

  <div *ngIf="isUsersAdminPage" class="right-side">
    <button
      id="ADMIN-LIST-USER-BT-NOUVEAU-USER"
      *ngxPermissionsOnly="['AdminUtilisateurs']"
      tabindex="0"
      aria-label="Ajouter un nouveau utilisateur"
      (click)="onAddNewUserAdmin()"
      class="add-user-button"
      mat-flat-button
    >
      {{ "labels.users.header.addNewUserBt" | translate }}
    </button>
  </div>
</div>
