import { Injectable } from '@angular/core';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';

/**
 * Service parent qui gère génériquement les exceptions.
 */
@Injectable({
  providedIn: 'root',
})
export abstract class AbstractService {
  constructor() {
    // Intentional empty constructor
  }

  /**
   * Traite l'erreur spécifiée, si l'erreur est nulle, une erreur générique sera envoyée.
   *
   * @param error - L'erreur à afficher
   */
  public handleError(error: any): void {
    if (!error) {
      throw new ApplicationException('Generic exception', '500', error);
    }
    if (error && error.message == null) {
      throw new ApplicationException('Generic exception', '500', error);
    }
    if (error && error.error && error.error.message) {
      // On extract le code fonctionnel de l'erreur
      const extract = error.error.message.match('.* : ([A-Z_]+_\\d+) - (.*)');
      if (extract && extract[1] && extract[2]) {
        const code = extract[1];
        const message = extract[2];
        throw new ApplicationException(
          message,
          error.error.returnCode,
          error,
          code
        );
      } else {
        throw new ApplicationException(
          error.error.message,
          error.error.returnCode,
          error
        );
      }
    }
  }
}
