import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { Organization } from 'src/app/shared/models/organization';
import { SearchDto } from 'src/app/shared/models/search';
import { environment_endpoints } from 'src/environments/environment';
import { OrganizationMigrationInfo } from '../models/organization-migration-info.model';
import { OrganizationResponse } from '../models/organization-response.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationAdminHttpService {
  urlAdminOrganizations: string = environment_endpoints.api.organizationsAdmin;

  private headers: HttpHeaders;
  private token = '';

  constructor(
    private httpClient: HttpClient,
    private oauthService: OAuthService
  ) {
    this.token = this.oauthService.getIdToken();
    this.headers = new HttpHeaders().append(
      'Authorization',
      'Bearer ' + this.token
    );
  }

  addOrganisation(organisation: Organization): Observable<Organization> {
    return this.httpClient.post<Organization>(
      this.urlAdminOrganizations,
      organisation,
      { headers: this.headers }
    );
  }

  getAllOrganizations(): Observable<OrganizationResponse> {
    return this.httpClient.get<OrganizationResponse>(
      this.urlAdminOrganizations,
      { headers: this.headers }
    );
  }

  getOrganizations(params: HttpParams): Observable<OrganizationResponse> {
    return this.httpClient.get<OrganizationResponse>(
      this.urlAdminOrganizations + '/filtered',
      { headers: this.headers, params }
    );
  }

  getOrganization(id: string): Observable<Organization> {
    return this.httpClient.get<Organization>(
      this.urlAdminOrganizations + '/' + id,
      {
        headers: this.headers,
      }
    );
  }

  updateOrganization(
    id: string,
    organisation: Organization
  ): Observable<Organization> {
    return this.httpClient.put<Organization>(
      this.urlAdminOrganizations + '/' + id,
      organisation,
      { headers: this.headers }
    );
  }

  renewOrganizationCredentials(id: string): Observable<Organization> {
    return this.httpClient.get<Organization>(
      this.urlAdminOrganizations + '/' + id + '/credentials',
      { headers: this.headers }
    );
  }

  migrateOrganization(
    source: Organization,
    target: Organization
  ): Observable<OrganizationMigrationInfo> {
    return this.httpClient.post<OrganizationMigrationInfo>(
      this.urlAdminOrganizations + '/migrate',
      {
        organizationSource: source,
        organizationTarget: target,
      },
      { headers: this.headers }
    );
  }

  /**
   * Search for organizations
   */
  search(
    search: SearchDto,
    maxResult: number
  ): Observable<OrganizationResponse> {
    let params = new HttpParams();
    params = params.set('maxResult', maxResult);

    return this.httpClient.post<OrganizationResponse>(
      `${this.urlAdminOrganizations}/search`,
      search,
      { headers: this.headers, params: params }
    );
  }
}
