import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class CsvDownloaderService {
  public download(csv: string, nameFile : string): void {
    const blob = new Blob(['\ufeff', csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, nameFile, { autoBom: true });
  }
}
