<mat-card class="rf-container-fluid update-org-container">
  <mat-card-title class="bold title-dialog"
    >{{ "labels.users.updateUserOrganization.title" | translate }}
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="updatedUserOrganizationForm" (submit)="submitForm()">
      <mat-form-field
        class="user-organization"
        appearance="fill"
        *ngIf="isUsersAdminPage"
      >
        <mat-label aria-label="Choisir une organisation">
          {{
            "labels.users.updateUserOrganization.organisationLabel" | translate
          }}
        </mat-label>
        <input
          type="text"
          matInput
          [formControl]="userOrganizationFormControl"
          [matAutocomplete]="auto"
        />
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn.bind(this)"
          (optionSelected)="onUserOrganizationChanged($event)"
        >
          <mat-option
            [disabled]="isOrganizationEmpty"
            *ngFor="let organization of filteredOptions | async"
            [value]="organization"
            matTooltip="{{
              organization.branchCode + ' - ' + organization.name
            }}"
            [matTooltipShowDelay]="300"
            matTooltipClass="organization-name-update-user-tooltip"
            matTooltipPosition="after"
          >
            {{ organization.branchCode }} - {{ organization.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error
          *ngIf="
            updatedUserOrganizationForm
              .get('userOrganizationFormControl')
              .hasError('required')
          "
        >
          {{
            "labels.users.updateUserOrganization.userOrganizationRequired"
              | translate
          }}
        </mat-error>
      </mat-form-field>
      <mat-dialog-actions class="right-side">
        <button
          aria-label="annuler la mise à jour"
          role="button"
          class="cancel-button"
          mat-raised-button
          mat-dialog-close
          mat-dialog-close="cancel"
        >
          {{ "labels.users.updateUserOrganization.cancelButton" | translate }}
        </button>
        <button
          aria-label="confirmer la mise à jour"
          role="button"
          class="confirm-button"
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!updatedUserOrganizationForm.valid"
        >
          {{ "labels.users.updateUserOrganization.confirmButton" | translate }}
        </button>
      </mat-dialog-actions>
    </form>
  </mat-card-content>
</mat-card>
