import { HttpParams } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { OrganizationResponse } from 'src/app/features/organization-admin/models/organization-response.model';
import { OrganizationAdminHttpService } from 'src/app/features/organization-admin/services/organizationAdminHttp.service';
import { OrganizationHttpService } from 'src/app/features/subscription/services/organizationHttp.service';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';
import {
  Organization,
  OrganizationFilter,
} from 'src/app/shared/models/organization';
import { Process } from 'src/app/shared/models/process';
import {
  SearchCriteria,
  SearchDto,
  SearchOperation,
} from 'src/app/shared/models/search';
import { Direction, SortAttribute } from 'src/app/shared/models/sort-attribute';
import { TextService } from 'src/app/shared/service/text.service';
import {
  userRolesList,
  userRolesListAgentAndLocalAdm,
  userRolesListBusinessAdmin,
} from '../../models/userRole';
import { UsersFilters } from '../../models/usersFilters';
import {
  userTypeList,
  userTypeListAdministration,
  userTypeListAdministrationForBusinessAdmUser,
  userTypeListForAgtAndAdmLoc,
  userTypeListForTechDel,
} from '../../models/userType';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-users-filters',
  templateUrl: './users-filters.component.html',
  styleUrls: ['./users-filters.component.scss'],
  providers: [TextService],
  encapsulation: ViewEncapsulation.None,
})
export class UsersFiltersComponent implements OnInit, OnChanges {
  @Input() dataSource: any;
  @Input() isUsersPage: boolean;
  @Input() isUsersAdminPage: boolean;
  @Input() processList: Process[] = [];
  @Input() userRolesList: string[];
  @Input() usersResult: any;
  @Input() isLoading = true;
  userRolesByConnectedUserType = [];
  buttonColor = '#EEF1F9';
  isMoreFiltersClicked = false;
  processes = new FormControl();
  organizationFormControl = new FormControl();
  userTypesFormControl = new FormControl();
  userRolesFormControl = new FormControl();
  isTableView = false;
  isListView = true;
  userTypes = userTypeList;
  userRoles = [];

  userResultNumber = 0;

  urlView = 'list';
  selectedProcesses;
  selectedUserRoles;
  selectedUserTypes;
  value: string;
  urlProcesses: string;
  moreOrLessFilters = 'Plus de filtres';
  sortAttributes: SortAttribute[] = [
    { value: 'updateTimestamp', viewValue: 'Date de modification' },
    { value: 'transmissionTimestamp', viewValue: 'Date de transmission' },
  ];
  selected = this.sortAttributes[1].value;
  isFiltered = false;
  startSubmittedDate: Date;
  endSubmittedDate: Date;
  formStartDate = new FormControl(new Date());
  formEndDate = new FormControl(new Date());
  parsedStartDate: number;
  parsedEndDate: number;
  formatedStartDate: string;
  selectable = true;
  removable = true;
  formatedEndDate: string;
  isStartDateChosen = false;
  isEndDateChosen = false;
  isPeriodChosed: boolean;
  isFilterResetClicked = false;
  filters: UsersFilters = new UsersFilters();
  temporaryValue = false;
  isAdvancedFiltersShowed = false;
  searchedUserName = '';
  isFilteredByProcess = false;
  isFilteredByRole = false;
  isFilteredByUserType = false;
  isFilteredBySearchedName = false;
  filteredOptions: Observable<any[]>;
  isFilteredByOrganization = false;
  shouldSetFilters = true;
  userTypesList: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private oauthService: OAuthService,
    private organizationService: OrganizationAdminHttpService
  ) {}

  ngOnInit(): void {
    this.initiateOrganizationsInFilter();
    this.userTypesList = this.initiateUserTypeListValuesByConnectedUser().map(
      (a) => a.name
    );
    this.userRoles =
      this.initiateUserRolesFilterListValuesByConnectedUserType();
    this.selectedProcesses = new Array<string>();
    this.setFiltersOnUI();
    this.checkPageContent();
    this.shouldSetFilters = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      if (
        !changes.isLoading.currentValue &&
        !changes.isLoading.isFirstChange() &&
        this.shouldSetFilters
      ) {
        this.setFiltersOnUI();
      }
      if (!changes.isLoading.isFirstChange()) {
        this.shouldSetFilters = false;
      }
    }
    if (this.usersResult) {
      this.userResultNumber = this.usersResult.total;
    }
    if (changes.organizationsList) {
      this.initiateOrganizationsInFilter();
      this.setFiltersOnUI();
    }
    this.processList.sort((a, b) => a.code.localeCompare(b.code));
  }

  public initiateOrganizationsInFilter(): void {
    this.filteredOptions = this.organizationFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      map((value) => (typeof value === 'string' ? value : value.name)),
      switchMap((value: string) => {
        const criterias: SearchCriteria[] = [
          {
            filterKey: 'name',
            operation: SearchOperation.CONTAINS,
            value: value,
          },
        ];

        const searchDto: SearchDto = {
          searchCriteriaList: criterias,
          dataOption: 'all',
        };

        return this.organizationService.search(searchDto, environment.maxResult);
      }),
      map((value) => value.organizations)
    );
  }

  public checkPageContent(): void {
    this.isUsersAdminPage = this.router.url.includes('/users/admin');
    this.isUsersPage = !this.isUsersAdminPage;
  }

  /**
   * Set on UI searched user, user type and more filters from user initial choices.
   *
   * @param filters - User chosen filters
   * @param params - URL with filters data
   */
  public setSearchedUserAndProcessMoreFiltersFromParams(
    filters: UsersFilters,
    params: ParamMap
  ): void {
    this.isAdvancedFiltersShowed = this.isMoreFiltersClicked;
    if (params.get('searched') != null) {
      this.searchedUserName = params.get('searched');
      this.isFilteredBySearchedName = true;
    } else {
      this.isFilteredBySearchedName = false;
      this.searchedUserName = '';
    }
    this.setSelectionOnProcessesFromUrl(filters.processPermission);
    if (params.get('processPermission') != null) {
      const decodedPermission = this.decodeProcessPermission(
        JSON.parse(params.get('processPermission'))
      );
      this.processes.setValue(decodedPermission);
      this.isFilteredByProcess = true;
    } else {
      this.isFilteredByProcess = false;
      this.processes.setValue(null);
    }
    if (
      (this.isFilteredByProcess && this.isUsersAdminPage) ||
      this.isFilteredBySearchedName
    ) {
      this.isAdvancedFiltersShowed = true;
    } else {
      this.isAdvancedFiltersShowed = false;
    }
    this.showOrHideAdvancedFiltersFromUrl();
  }

  public setUserRoleFilterFromParams(params: ParamMap): void {
    if (params.get('userRoles') != null) {
      const decodedRole = this.decodeRole(JSON.parse(params.get('userRoles')));
      this.userRolesFormControl.setValue(decodedRole);
      this.isFilteredByRole = true;
    } else {
      this.isFilteredByRole = false;
      this.userRolesFormControl.setValue(null);
    }
  }

  /**
   * Retrieves process name from process code.
   *
   * @param encodedRole - Role code
   * @returns Role name
   */
  public decodeRole(roleCode: string): string {
    let decodedRole = '';
    userRolesList.forEach((role) => {
      if (roleCode === role.code) {
        decodedRole = role.name;
      }
    });
    return decodedRole;
  }

  /**
   * Set on UI process filter from user initial choice.
   *
   * @param filters - User chosen filter on process
   * @param params - URL with filter data
   */
  public setUserTypeFilterFromParams(params: ParamMap): void {
    if (params.get('userType') != null) {
      const decodedUserType = this.decodeSelectedUserTypes(
        JSON.parse(params.get('userType'))
      );
      this.userTypesFormControl.setValue(decodedUserType);
      this.isFilteredByUserType = true;
    } else {
      this.isFilteredByUserType = false;
      this.userTypesFormControl.setValue(null);
    }
  }

  /**
   * Set on UI organization filter from user initial choice.
   *
   * @param params - URL with filter data
   */
  public setOrganizationFilterFromParams(params: ParamMap): void {
    if (
      params.get('siret') != null &&
      params.get('branchCode') != null &&
      params.get('type') != null
    ) {
      const decodedOrganizationName = params.get('name');
      this.organizationFormControl.setValue(decodedOrganizationName);
      const claims: any = this.oauthService.getIdentityClaims();
      if (
        this.getUserType() === 'ADM_MET' &&
        params.get('siret') === claims.organization_siret &&
        params.get('branchCode') === claims.organization_branch_code &&
        params.get('type') === claims.organization_type
      ) {
        this.isFilteredByOrganization = false;
      } else {
        this.isFilteredByOrganization = true;
      }
    } else {
      this.isFilteredByOrganization = false;
      this.organizationFormControl.setValue('');
    }
  }

  /**
   * Retrieves process name from process code.
   *
   * @param encodedProcess - Process code
   * @returns Process name
   */
  public decodeProcessPermission(encodedProcess: string): string {
    let decodedPermission = '';
    this.processList.forEach((process) => {
      if (encodedProcess === process.code) {
        decodedPermission = process.name;
      }
    });
    return decodedPermission;
  }

  /**
   * Retrieves user type name from user type code.
   *
   * @param userType - User type code
   * @returns User type name
   */
  public decodeSelectedUserTypes(userType: string): string {
    let decodedValues = '';
    this.userTypes.forEach((type) => {
      if (userType === type.code) {
        decodedValues = type.name;
      }
    });
    return decodedValues;
  }

  public clearSearchField(): void {
    this.searchedUserName = '';
    const path = this.isUsersAdminPage ? '/users/admin/' : '/users';
    this.router.navigate([path], {
      relativeTo: this.route,
      queryParams: {
        searched: this.searchedUserName,
        pageNumber: '0',
      },
      queryParamsHandling: 'merge',
    });
  }

  public showOrHideAdvancedFiltersFromUrl(): void {
    this.changeMoreFiltersLabel();
  }

  public onProcessSelected(event: any): void {
    let processValue: any;
    let encodedProcessValue = '';
    this.selectedProcesses = {
      value: event.value,
      text: event.source.triggerValue,
    };
    encodedProcessValue = this.encodeProcessPermissions(this.selectedProcesses);

    processValue =
      encodedProcessValue.length !== 0
        ? JSON.stringify(encodedProcessValue)
        : null;
    if (processValue !== null) {
      this.isFilteredByProcess = true;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        processPermission: processValue,
        pageNumber: '0',
      },
      queryParamsHandling: 'merge',
    });
  }

  public encodeProcessPermissions(selectedPermission): string {
    let encodedPermission = '';
    this.processList.forEach((process) => {
      if (selectedPermission.value === process.name) {
        encodedPermission = process.code;
      }
    });

    return encodedPermission;
  }

  public onOrganizationSelected(event: any): void {
    const organization = event.option.value;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        siret: organization.siret,
        branchCode: organization.branchCode,
        type: organization.type,
        name: organization.name,
        pageNumber: '0',
      },
      queryParamsHandling: 'merge',
    });
    this.isFilteredByOrganization = true;
  }

  displayFn(value) {
    return typeof value === 'string' ? value : value.name;
  }

  public onUserTypeSelected(event: any): void {
    let userTypeValue: any;
    this.selectedUserTypes = {
      value: event.value,
      text: event.source.triggerValue,
    };
    const encodedValues = this.encodeSelectedUserTypes(this.selectedUserTypes);
    userTypeValue =
      encodedValues.length !== 0 ? JSON.stringify(encodedValues) : null;
    if (userTypeValue !== null) {
      this.isFilteredByUserType = true;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        userType: userTypeValue,
        pageNumber: '0',
      },
      queryParamsHandling: 'merge',
    });
  }

  public onUserRoleSelected(event: any): void {
    let userRoleValue: any;
    this.selectedUserRoles = {
      value: event.value,
      text: event.source.triggerValue,
    };
    const encodedValues = this.encodeSelectedUserRoles(this.selectedUserRoles);
    userRoleValue =
      encodedValues.length !== 0 ? JSON.stringify(encodedValues) : null;
    if (userRoleValue !== null) {
      this.isFilteredByRole = true;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        userRoles: userRoleValue,
        pageNumber: '0',
      },
      queryParamsHandling: 'merge',
    });
  }

  public encodeSelectedUserTypes(selectedUserTypes): string {
    let encodedValues = '';
    this.userTypes.forEach((type) => {
      if (selectedUserTypes.value === type.name) {
        encodedValues = type.code;
      }
    });
    return encodedValues;
  }

  public encodeSelectedUserRoles(selectedUserRole): string {
    let encodedValues = '';
    this.userRoles.forEach((selection) => {
      if (selectedUserRole.value === selection.name) {
        encodedValues = selection.code;
      }
    });
    return encodedValues;
  }

  public onMoreFiltersClicked(): void {
    this.isMoreFiltersClicked = !this.isMoreFiltersClicked;
    this.isAdvancedFiltersShowed = this.isMoreFiltersClicked;
    this.changeMoreFiltersLabel();
  }

  public changeMoreFiltersLabel(): void {
    this.moreOrLessFilters = this.isAdvancedFiltersShowed
      ? ' Moins de filtres'
      : ' Plus de filtres';
  }

  public resetFilters(): void {
    this.isFilterResetClicked = true;
    this.isFilteredByProcess = false;
    this.isFilteredByRole = false;
    this.isFilteredByUserType = false;
    this.isFilteredBySearchedName = false;
    this.isFilteredByOrganization = false;
    this.searchedUserName = '';
    this.selectedProcesses = [];
    this.selectedUserTypes = [];
    this.isMoreFiltersClicked = false;
    this.organizationFormControl.setValue('');
    this.userTypesFormControl.setValue(null);
    this.userRolesFormControl.setValue(null);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        searched: null,
        processPermission: null,
        userRoles: null,
        userType: null,
        siret: null,
        branchCode: null,
        type: null,
        name: null,
        pageNumber: '0',
      },
      queryParamsHandling: 'merge',
    });
  }

  public setSelectionOnProcessesFromUrl(process: string): void {
    if (process) {
      this.selectedProcesses.value = JSON.parse(process);
      this.processes.setValue(this.selectedProcesses.value);
      this.isFiltered = true;
    } else {
      this.processes.setValue(null);
      this.isFiltered = false;
    }
  }

  public onSortAttributeSelected(value: string): void {
    this.selected = value;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        sortAttribute: value,
        direction: Direction.DESC,
      },
      queryParamsHandling: 'merge',
    });
  }

  public onElementSubmitted(value: string): void {
    this.searchedUserName = value.toUpperCase();
    this.onSearch(value.toUpperCase());
  }

  public onSearch(searchedUserName: string): void {
    if (searchedUserName) {
      this.isFilteredBySearchedName = true;
      const path = this.isUsersAdminPage ? '/users/admin/' : '/users';
      this.router.navigate([path], {
        relativeTo: this.route,
        queryParams: {
          searched: searchedUserName.toUpperCase(),
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  public replaceOrResetSearchInput(): void {
    this.router.events.subscribe(() => {
      this.route.queryParamMap.subscribe((params) => {
        if (params.get('searched') != null) {
          this.searchedUserName = params.get('searched').toUpperCase();
        } else {
          this.searchedUserName = null;
        }
      });
    });
  }

  public initiateUserTypeListValuesByConnectedUser(): any[] {
    const userType = this.getUserType();
    switch (userType) {
      case 'ADM_NAT': {
        return userTypeListAdministration;
      }
      case 'ADM_MET': {
        return userTypeListAdministrationForBusinessAdmUser;
      }
      case 'ADM_LOC': {
        return userTypeListForAgtAndAdmLoc;
      }
      case 'DEL_TEC': {
        return userTypeListForTechDel;
      }
      case 'AGT': {
        return userTypeListForAgtAndAdmLoc;
      }
      default: {
        return [];
      }
    }
  }

  public initiateUserRolesFilterListValuesByConnectedUserType(): any[] {
    const claims: any = this.oauthService.getIdentityClaims();
    switch (claims.user_type) {
      case 'ADM_NAT': {
        return userRolesList;
      }
      case 'ADM_MET': {
        return userRolesListBusinessAdmin;
      }
      case 'ADM_LOC': {
        return userRolesListAgentAndLocalAdm;
      }
      case 'DEL_TEC': {
        return userRolesListAgentAndLocalAdm;
      }
      case 'AGT': {
        return userRolesListAgentAndLocalAdm;
      }
      default: {
        return [];
      }
    }
  }

  /**
   * Set all filters on UI.
   */
  public setFiltersOnUI(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.forceBusinessAdminOrganizationFilter(params);
      this.setSearchedUserAndProcessMoreFiltersFromParams(this.filters, params);
      this.setUserTypeFilterFromParams(params);
      this.setOrganizationFilterFromParams(params);
      this.setUserRoleFilterFromParams(params);
    });
  }

  //TODO CREATE A SERVICE FOR OAUTH RETRIEVES INFO FROM JWT TOKEN
  /**
   * Retrieves current user type.
   *
   * @returns current user type
   */
  private getUserType(): string {
    const claims: any = this.oauthService.getIdentityClaims();
    return claims.user_type;
  }

  /**
   * Makes business admin to use own organization on organization filter.
   * Used on page initialization and on filter reset.
   */
  private forceBusinessAdminOrganizationFilter(params: ParamMap): void {
    if (
      this.getUserType() === 'ADM_MET' &&
      params.get('branchCode') === null &&
      params.get('siret') === null &&
      params.get('type') === null &&
      params.get('processPermission') === null &&
      params.get('userType') === null &&
      params.get('searched') === null &&
      params.get('userRoles') === null &&
      params.get('name') === null
    ) {
      const claims: any = this.oauthService.getIdentityClaims();
      const siret = claims.organization_siret;
      const orgType = claims.organization_type;
      const branchCode = claims.organization_branch_code;

      const params = new HttpParams()
        .set('type', orgType)
        .set('siret', siret)
        .set('branchCode', branchCode);

      this.organizationService.getOrganizations(params).subscribe(
        (org: OrganizationResponse) => {
          if (org && org.organizations) {
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                searched: null,
                processPermission: null,
                userRoles: null,
                userType: null,
                siret,
                branchCode,
                type: orgType,
                pageNumber: '0',
                name: org.organizations[0].name,
              },
              queryParamsHandling: 'merge',
            });
          }
        },
        (err) => {
          throw new ApplicationException(err);
        }
      );
    }
  }
}
