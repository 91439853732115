<ng-container>
  <div class="mat-elevation-z8">
    <div class="subtitle-area">
      <div
        id="{{
          isUsersAdminPage
            ? 'ADMIN-LIST-USER-TITRE-LISTE'
            : 'LIST-USER-TITRE-LISTE'
        }}"
        class="subtitle"
      >
        {{ "labels.users.list.subtitle" | translate }}
      </div>
      <button
        *ngIf="isUsersAdminPage"
        id="ADMIN-LIST-USER-BT-EXPORT"
        (click)="export.emit(); clicked = true"
        class="export-button"
        [disabled]="clicked"
        mat-flat-button
      >
        <i
          class="fas fa-file-export"
          *ngIf="Loading === 'start'"
          aria-hidden="true"
        ></i>
        <i
          aria-hidden="true"
          class="fas fa-spinner fa-spin"
          *ngIf="Loading === 'loading'"
        ></i>
        <i
          class="fa fa-check"
          *ngIf="Loading === 'success'"
          aria-hidden="true"
        ></i>
        <i
          class="fas fa-times"
          *ngIf="Loading === 'error'"
          aria-hidden="true"
        ></i>
        {{ "labels.users.list.exportBt" | translate }}
      </button>
    </div>
    <div *ngIf="!usersResult || usersResult.total < 1" class="no-results">
      {{ "labels.subfolder.listView.noResults" | translate }}
    </div>

    <table *ngIf="totalUsersNumber > 0" mat-table [dataSource]="users">
      <ng-container tabindex="0" matColumnDef="Actif">
        <th
          id="{{
            isUsersAdminPage
              ? 'ADMIN-LIST-USER-TABLE-LIBELLE-COL'
              : 'LIST-USER-TABLE-LIBELLE-COL'
          }}"
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          {{ "labels.users.list.active" | translate }}
        </th>
        <td
          id="{{
            isUsersAdminPage ? 'ADMIN-LIST-USER-STATUT' : 'LIST-USER-STATUT'
          }}"
          tabindex="-1"
          mat-cell
          *matCellDef="let user"
          class="greycolor"
        >
          {{ user.activatedValue }}
        </td>
      </ng-container>

      <ng-container
        *ngIf="isUsersAdminPage"
        tabindex="0"
        matColumnDef="Organisation"
      >
        <th
          id="ADMIN-LIST-USER-TABLE-LIBELLE-COL"
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          {{ "labels.users.list.organisation" | translate }}
        </th>
        <td tabindex="-1" mat-cell *matCellDef="let user" class="greycolor">
          <ng-container *ngIf="hasOrganization(user)">
            {{ user.organization.branchCode }} -
            {{ user.organization.name }} ({{ user.organization.type }})
          </ng-container>
          <ng-container *ngIf="!hasOrganization(user)"> - </ng-container>
        </td>
      </ng-container>

      <ng-container tabindex="0" matColumnDef="Nom de famille">
        <th
          id="{{
            isUsersAdminPage
              ? 'ADMIN-LIST-USER-TABLE-LIBELLE-COL'
              : 'LIST-USER-TABLE-LIBELLE-COL'
          }}"
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          {{ "labels.users.list.lastName" | translate }}
        </th>
        <td
          id="{{ isUsersAdminPage ? 'ADMIN-LIST-USER-NOM' : 'LIST-USER-NOM' }}"
          tabindex="0"
          mat-cell
          *matCellDef="let user"
        >
          <span class="last-name"> {{ user.lastName }}</span>
        </td>
      </ng-container>

      <ng-container tabindex="0" matColumnDef="Prénom">
        <th
          id="{{
            isUsersAdminPage
              ? 'ADMIN-LIST-USER-TABLE-LIBELLE-COL'
              : 'LIST-USER-TABLE-LIBELLE-COL'
          }}"
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          {{ "labels.users.list.firstName" | translate }}
        </th>
        <td
          id="{{
            isUsersAdminPage ? 'ADMIN-LIST-USER-PRENOM' : 'LIST-USER-PRENOM'
          }}"
          tabindex="0"
          class="first-name"
          mat-cell
          *matCellDef="let user"
        >
          {{ user.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Type d'acteur">
        <th
          id="{{
            isUsersAdminPage
              ? 'ADMIN-LIST-USER-TABLE-LIBELLE-COL'
              : 'LIST-USER-TABLE-LIBELLE-COL'
          }}"
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          {{ "labels.users.list.actorType" | translate }}
        </th>
        <td
          id="{{
            isUsersAdminPage ? 'ADMIN-LIST-USER-ACTEUR' : 'LIST-USER-ACTEUR'
          }}"
          tabindex="0"
          mat-cell
          *matCellDef="let user"
        >
          {{ user.userType }}
          <div
            id="{{
              isUsersAdminPage
                ? 'ADMIN-LIST-USER-DATAPASS'
                : 'LIST-USER-DATAPASS'
            }}"
            *ngIf="user.datapassAccount"
          >
            {{ "labels.users.list.dataPass" | translate }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Rôles">
        <th
          id="{{
            isUsersAdminPage
              ? 'ADMIN-LIST-USER-TABLE-LIBELLE-COL'
              : 'LIST-USER-TABLE-LIBELLE-COL'
          }}"
          class="text-center"
          mat-header-cell
          *matHeaderCellDef
        >
          {{ "labels.users.list.roles" | translate }}
        </th>
        <td
          id="{{
            isUsersAdminPage ? 'ADMIN-LIST-USER-ROLES' : 'LIST-USER-ROLES'
          }}"
          mat-cell
          *matCellDef="let user"
        >
          <div
            *ngIf="user.userRoles.length <= 6 && user.userRoles"
            class="user-role"
          >
            <ng-container *ngFor="let role of user.userRoles">
              <div tabindex="0">• {{ decodeUserRoles(role.display) }}</div>
            </ng-container>
          </div>
          <div
            tabindex="0"
            *ngIf="user.userRoles.length > 6 && user.userRoles"
            class="user-role"
          >
            <ng-container *ngFor="let role of user.userRoles; let i = index">
              <div tabindex="0" *ngIf="i < user.shownRolesLimit">
                • {{ decodeUserRoles(role.display) }}
              </div>
            </ng-container>
            <div *ngIf="user.userRoles.length > 6" class="toggle-see-more">
              <div
                tabindex="0"
                role="button"
                (click)="seeMoreRolesToggle(user)"
                *ngIf="user.shownRolesLimit <= 6"
                class="see-more"
              >
                <div class="points">...</div>
                <div class="see-more-text">
                  {{ "labels.users.list.seeAll" | translate }}
                </div>
              </div>
              <div
                id="{{
                  isUsersAdminPage
                    ? 'ADMIN-LIST-USER-BT-VOIR-TOUT'
                    : 'LIST-USER-BT-VOIR-TOUT'
                }}"
                tabindex="0"
                role="button"
                (click)="seeMoreRolesToggle(user)"
                *ngIf="user.shownRolesLimit > 6"
                class="see-more"
              >
                <div class="see-more-text">
                  {{ "labels.users.list.seeLess" | translate }}
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Habilitations">
        <th
          id="{{
            isUsersAdminPage
              ? 'ADMIN-LIST-USER-TABLE-LIBELLE-COL'
              : 'LIST-USER-TABLE-LIBELLE-COL'
          }}"
          class="text"
          mat-header-cell
          *matHeaderCellDef
        >
          {{ "labels.users.list.processPermissions" | translate }}
        </th>
        <td
          id="{{
            isUsersAdminPage
              ? 'ADMIN-LIST-USER-HABILITATIONS'
              : 'LIST-USER-HABILITATIONS'
          }}"
          mat-cell
          *matCellDef="let user"
        >
          <div
            tabindex="0"
            *ngIf="user.processPermissions.length < 1"
            class="process-permision no-permissions"
          >
            {{ "labels.users.header.noPermissions" | translate }}
          </div>
          <div
            *ngIf="
              user.processPermissions.length <= 6 && user.processPermissions
            "
            class="process-permision"
          >
            <ng-container *ngFor="let process of user.processPermissions">
              <div tabindex="0">• {{ process }}</div>
            </ng-container>
          </div>
          <div
            tabindex="0"
            *ngIf="
              user.processPermissions.length > 6 && user.processPermissions
            "
            class="process-permision"
          >
            <ng-container
              *ngFor="let process of user.processPermissions; let i = index"
            >
              <div tabindex="0" *ngIf="i < user.shownProcessesLimit">
                ○ {{ process }}
              </div>
            </ng-container>
            <div
              *ngIf="user.processPermissions.length > 6"
              class="toggle-see-more"
            >
              <div
                tabindex="0"
                role="button"
                (click)="seeMoreProcessesToggle(user)"
                *ngIf="user.shownProcessesLimit < 6"
                class="see-more"
              >
                <div class="points">...</div>
                <div class="see-more-text">
                  {{ "labels.users.list.seeAll" | translate }}
                </div>
              </div>
              <div
                id="{{
                  isUsersAdminPage
                    ? 'ADMIN-LIST-USER-BT-VOIR-TOUT'
                    : 'LIST-USER-BT-VOIR-TOUT'
                }}"
                tabindex="0"
                role="button"
                (click)="seeMoreProcessesToggle(user)"
                *ngIf="user.shownProcessesLimit > 6"
                class="see-more"
              >
                <div class="see-more-text">
                  {{ "labels.users.list.seeLess" | translate }}
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="hi">
        <th
          id="table-space"
          class="text"
          mat-header-cell
          *matHeaderCellDef
        ></th>
        <td
          tabindex="0"
          class="edit-pen"
          mat-cell
          *matCellDef="let user; let index = i"
        >
          <i
            aria-hidden="true"
            id="{{
              isUsersAdminPage
                ? 'ADMIN-LIST-USER-BT-MODIF'
                : 'LIST-USER-BT-MODIF'
            }}"
            *ngxPermissionsOnly="['GestionUtilisateurs', 'AdminUtilisateurs']"
            class="fas fa-pen"
            (click)="openUserDetails(user)"
          ></i>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    *ngIf="displayPagination"
    tabindex="0"
    aria-label="pagination"
    id="{{ isUsersAdminPage ? 'users-admin-paginator' : 'users-paginator' }}"
    (page)="handlePageEvent($event)"
    [length]="totalUsersNumber"
    [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
  >
  </mat-paginator>
</ng-container>
