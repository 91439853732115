<div class="filters-container-users">
  <div class="filters">
    <div class="basic-filters-area">
      <div class="left-side">
        <div class="left-side-filters">
          <mat-form-field
            *ngIf="isUsersAdminPage"
            class="organizations-full-width"
          >
            <mat-label tabindex="0">{{
              "labels.users.filters.organizationFilter" | translate
            }}</mat-label>
            <input
              type="text"
              matInput
              [formControl]="organizationFormControl"
              [matAutocomplete]="auto"
            />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayFn.bind(this)"
              (optionSelected)="onOrganizationSelected($event)"
            >
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
                matTooltip="{{ option.branchCode + ' - ' + option.name }}"
                [matTooltipShowDelay]="300"
                matTooltipClass="organization-name-filter-tooltip"
                matTooltipPosition="after"
              >
                {{ option.branchCode }} - {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field
            id="{{
              isUsersAdminPage
                ? 'ADMIN-LIST-USER-FILTRE-ACTEUR'
                : 'LIST-USER-FILTRE-ACTEUR'
            }}"
            class="uset-type-input"
            appearance="fill"
          >
            <mat-label tabindex="0">{{
              "labels.users.filters.typeFilter" | translate
            }}</mat-label>
            <mat-select
              [formControl]="userTypesFormControl"
              (selectionChange)="onUserTypeSelected($event)"
            >
              <mat-option
                role="option"
                *ngFor="let type of userTypesList"
                [value]="type"
                >{{ type }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field
            id="{{
              isUsersAdminPage
                ? 'ADMIN-LIST-USER-FILTRE-ROLE'
                : 'LIST-USER-FILTRE-ROLE'
            }}"
            class="role-input"
            appearance="fill"
          >
            <mat-label tabindex="0">{{
              "labels.users.filters.roleFilter" | translate
            }}</mat-label>
            <mat-select
              [formControl]="userRolesFormControl"
              (selectionChange)="onUserRoleSelected($event)"
            >
              <mat-option *ngFor="let role of userRoles" [value]="role.name">{{
                role.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            id="{{
              isUsersAdminPage
                ? 'ADMIN-LIST-USER-FILTRE-DEMARCHE'
                : 'LIST-USER-FILTRE-DEMARCHE'
            }}"
            *ngIf="!isUsersAdminPage"
            tabindex="0"
            class="processFilter-input"
            appearance="fill"
          >
            <mat-label tabindex="0">{{
              "labels.users.filters.processFilter" | translate
            }}</mat-label>
            <mat-select
              [formControl]="processes"
              (selectionChange)="onProcessSelected($event)"
            >
              <mat-option
                *ngFor="let process of processList"
                [value]="process.name"
                >{{ process.code }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            id="{{
              isUsersAdminPage
                ? 'ADMIN-LIST-USER-FILTRE-AVANCES'
                : 'LIST-USER-FILTRE-AVANCES'
            }}"
            mat-button
            tabindex="0"
            class="more-filters-bt"
            [ngClass]="{
              clicked: isMoreFiltersClicked || isAdvancedFiltersShowed,
              unclicked: !isMoreFiltersClicked && !isFilterResetClicked
            }"
            (click)="onMoreFiltersClicked()"
          >
            <i class="fa fa-filter"></i>{{ moreOrLessFilters }}
          </button>
          <button
            tabindex="0"
            *ngIf="
              isFilteredByProcess ||
              isFilteredByRole ||
              isFilteredByUserType ||
              isFilteredBySearchedName ||
              isFilteredByOrganization
            "
            mat-button
            class="reset-users-filters-bt"
            (click)="resetFilters()"
          >
            <i class="fa fa-times"></i>
            {{ "labels.subfolder.filters.resetFilters" | translate }}
          </button>
        </div>
        <div
          tabindex="0"
          class="results-number"
          [ngClass]="{
            'advanced-filters-clicked': isMoreFiltersClicked,
            'advanced-filters-unclicked': !isMoreFiltersClicked
          }"
          id="{{
            isUsersAdminPage
              ? 'ADMIN-LIST-USER-NB-RESULT'
              : 'LIST-USER-NB-RESULT'
          }}"
        >
          <span class="number-of-results">{{ userResultNumber }}</span>
          <span *ngIf="userResultNumber > 1">{{
            "labels.users.filters.results" | translate
          }}</span>
          <span *ngIf="userResultNumber <= 1">{{
            "labels.users.filters.result" | translate
          }}</span>
        </div>
      </div>
    </div>
    <div
      tabindex="0"
      class="advenced-filters-area"
      *ngIf="isAdvancedFiltersShowed"
    >
      <mat-form-field
        *ngIf="isUsersAdminPage"
        tabindex="0"
        class="processFilter-input"
        appearance="fill"
      >
        <mat-label tabindex="0">{{
          "labels.users.filters.processFilter" | translate
        }}</mat-label>
        <mat-select
          [formControl]="processes"
          (selectionChange)="onProcessSelected($event)"
        >
          <mat-option *ngFor="let process of processList" [value]="process.name"
            >{{ process.code }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        id="{{
          isUsersAdminPage
            ? 'ADMIN-LIST-USER-FILTRE-NOM'
            : 'LIST-USER-FILTRE-NOM'
        }}"
        tabindex="0"
        class="user-example-form-field"
      >
        <mat-label>{{
          "labels.users.filters.searchPlaceholder" | translate
        }}</mat-label>
        <input
          id="{{
            isUsersAdminPage
              ? 'ADMIN-LIST-USER-FILTRE-NOM'
              : 'LIST-USER-FILTRE-NOM'
          }}"
          matInput
          type="text"
          [(ngModel)]="searchedUserName"
          (keydown.enter)="onElementSubmitted($event.target.value)"
        />
        <button
          class="clean-input"
          mat-button
          *ngIf="searchedUserName"
          matSuffix
          mat-icon-button
          aria-label="Supprimer la recherche"
          (click)="clearSearchField()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button
          class="search-icon"
          mat-button
          *ngIf="searchedUserName"
          matSuffix
          mat-icon-button
          aria-label="Rechercher"
          (click)="onSearch(searchedUserName)"
        >
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>
