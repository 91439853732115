import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationException } from 'src/app/shared/exceptions/application.exception';
import { Subfolder } from '../../../../shared/models/subfolder';
import { SubfoldersFilters } from '../../models/subfoldersFilters';
import { SubfoldersService } from '../../services/subfolders.service';
import { formatDate } from '@angular/common';
import { SubFolderResult } from '../../models/subfolder-result';
import { NgxPermissionsService } from 'ngx-permissions';
import { SubfoldersCount } from '../../models/subfoldersCount';
import { PreferencesService } from 'src/app/shared/service/preferences.service';
import { SubfoldersSharedService } from '../../services/subfolders-sharedService.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GetAllSubFolders } from 'src/app/features/users/modals/get-all-subfolders-dialog-box/get-all-subfolders.component';

@Component({
  selector: 'app-subfolders-page',
  templateUrl: './subfolders-page.component.html',
  styleUrls: ['./subfolders-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubfoldersPageComponent implements OnInit, AfterViewInit {
  public view: string;
  subfolders: Subfolder[] = [];
  filters: SubfoldersFilters = new SubfoldersFilters();
  dataSource: any;
  url = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  subfoldersNumberResult: number;
  hasResults: boolean;
  subsResult: SubFolderResult;
  isAllSelected = false;
  isAllSelectedActionBar = false;
  allSubsResult: SubFolderResult;
  allSubfolders: Subfolder[];
  filtersWithoutPaginationLimit: SubfoldersFilters = new SubfoldersFilters();
  isSelectionCleaned = false;
  newStatusSubfolderCount: SubfoldersCount;
  oldStatusSubfolderCount: SubfoldersCount;
  totalSubfolders: number;
  lastFilterUrl: string;
  selectAllSubfolderCall: Subscription;
  operationCancel: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private subfoldersService: SubfoldersService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private preferencesService: PreferencesService,
    private subfoldersSharedService: SubfoldersSharedService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.checkAdminTD();
    this.getResultsByQueryParams();
    this.subfoldersSharedService
      .getAllSubfolders()
      .subscribe((allSubfolders) => {
        this.allSubfolders = allSubfolders;
      });
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
    this.changeDetectorRef.detectChanges();
  }

  public getResultsByQueryParams(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.url = '';
      this.view = params.get('view');
      this.filters = this.subfoldersService.getFilters(params);
      this.url = this.createUrlStringFromFilters(this.filters, this.url);
      this.fetchSubFoldersByFilters(this.url);
    });
  }

  public getAllResultsByQueryParamsWithoutPaginationLimit(
    maxResults: number
  ): void {
    const subscription = this.route.queryParamMap.subscribe((params) => {
      this.url = '';
      this.view = params.get('view');
      this.filters = this.subfoldersService.getFilters(params);
      this.filtersWithoutPaginationLimit = JSON.parse(
        JSON.stringify(this.filters)
      );

      this.filtersWithoutPaginationLimit.pageNumber = 0;
      this.filtersWithoutPaginationLimit.pageSize = maxResults;
      let tempUrl = this.createUrlStringFromFilters(
        this.filtersWithoutPaginationLimit,
        this.url
      );
      if (this.lastFilterUrl != tempUrl || this.operationCancel) {
        this.lastFilterUrl = tempUrl;
        this.fetchAllSubFoldersWithNoPaginationLimit(tempUrl);
      }
    });

    subscription.unsubscribe();
  }

  public createUrlStringFromFilters(
    filters: SubfoldersFilters,
    url: string
  ): string {
    if (url) {
      url += '&';
    }
    if (filters.pageSize === 0) {
      filters.pageSize = +this.preferencesService.getUserPagination();
    }
    Object.keys(filters).forEach((el) => {
      if (filters[el]) {
        if (el === 'processes') {
          url += this.getProcessesFromQuery(filters[el]);
        } else {
          url += `&${el}=${filters[el]}`;
        }
      }
    });
    return url;
  }

  public selectAllSubfolders(isAllSubfoldersSelected: boolean): void {
    setTimeout(() => {
      this.isAllSelected = isAllSubfoldersSelected;
      if (this.isAllSelected) {
        this.getAllResultsByQueryParamsWithoutPaginationLimit(
          this.totalSubfolders
        );
      }
    }, 0);
  }

  public uncheckSelectAllCheckBox(): void {
    this.isAllSelected = false;
  }
  /**
   * Récupere les sous dossiers avec des filtres
   *TODO : replace "url" with "filter" object and send it to back to get results ;
   */
  public async fetchSubFoldersByFilters(url): Promise<void> {
    await this.subfoldersService
      .getSubFoldersByFilters(url)
      .then((subFoldersResult: SubFolderResult) => {
        if (subFoldersResult) {
          this.subsResult = subFoldersResult;
          this.subfolders = subFoldersResult.subfolders;
          this.subfoldersNumberResult = this.subfolders.length;
          this.hasResults = this.subfoldersNumberResult !== 0;
          if (this.hasResults) {
            this.formatDates(this.subfolders);
          }
          this.dataSource = new MatTableDataSource(this.subfolders);
          this.totalSubfolders = subFoldersResult.total;
        }
        return subFoldersResult;
      })
      .catch((error) => {
        throw new ApplicationException(error);
      });
  }

  public fetchAllSubFoldersWithNoPaginationLimit(url): void {
    this.operationCancel = false;
    this.subfoldersSharedService.setDisableSelectAllCheckBox(true);
    this.subfoldersSharedService.setAllSubfolders([]);
    const dialogRef = this.dialog.open(GetAllSubFolders, {
      width: '400px',
      disableClose: true,
      autoFocus: false,
    });
    let sub = this.subfoldersService
      .getSubFoldersByFiltersAsObservable(url)
      .subscribe(
        (allSubsResult: SubFolderResult) => {
          if (allSubsResult) {
            this.allSubsResult = allSubsResult;
            this.allSubfolders = allSubsResult.subfolders;
            this.subfoldersSharedService.setAllSubfolders(this.allSubfolders);
          }
          this.subfoldersSharedService.setDisableSelectAllCheckBox(false);
          dialogRef.close();
          return allSubsResult;
        },
        (error) => {
          dialogRef.close();
          this.subfoldersSharedService.setDisableSelectAllCheckBox(false);
          throw new ApplicationException(error);
        }
      );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'cancel') {
        this.subfoldersSharedService.setAllSubfolders([]);
        this.subfoldersSharedService.setDisableSelectAllCheckBox(false);
        this.isAllSelected = false;
        this.operationCancel = true;
        sub.unsubscribe();
      }
    });
  }

  public cleanSelection(): void {
    this.isSelectionCleaned = true;
  }

  /**
   *  Recuperer les démarches à partir des filtres
   */
  public getProcessesFromQuery(processes: string): string {
    let processesUrlText = '';
    const selectedItemsArray = JSON.parse(processes);
    selectedItemsArray.forEach((process) => {
      process = encodeURI(process);
      processesUrlText += '&process=' + process;
    });
    return processesUrlText;
  }

  public formatDates(subfolders: Subfolder[]): void {
    subfolders.forEach((oneSub) => {
      if (oneSub.transmissionTimestamp) {
        oneSub.submitteddate = formatDate(
          new Date(oneSub.transmissionTimestamp),
          'dd/MM/yyyy',
          'fr-FR'
        );
        oneSub.submittedtime = formatDate(
          new Date(oneSub.transmissionTimestamp),
          'HH:mm',
          'fr-FR'
        );
      }
      if (oneSub.updateTimestamp) {
        oneSub.updatedate = formatDate(
          new Date(oneSub.updateTimestamp),
          'dd/MM/yyyy',
          'fr-FR'
        );
        oneSub.updatetime = formatDate(
          new Date(oneSub.updateTimestamp),
          'HH:mm',
          'fr-FR'
        );
      }
    });
  }

  /**
   * Checks that user has roles 'GestionTD' or 'ConsultationTD' to access to this page.
   * Otherwise, he gets redirected.
   */
  private checkAdminTD(): void {
    const allowedPermissions: string[] = ['GestionTD', 'ConsultationTD'];
    this.permissionsService.hasPermission(allowedPermissions).then((result) => {
      if (!result) {
        this.router.navigate(['/supervision/activity']);
      }
    });
  }

  /**
   * Retrieves new subfolders count according to status update.
   *
   * @param subfolderCount - number of subfolders updated on new status
   * @param isNewStatus - update on new or old status
   */
  public getNewSubfolderCount(
    subfolderCount: SubfoldersCount,
    isNewStatus: boolean
  ): void {
    if (isNewStatus) {
      this.newStatusSubfolderCount = subfolderCount;
    } else {
      this.oldStatusSubfolderCount = subfolderCount;
    }
  }
}
