import { UserRole } from 'src/app/features/users/models/userRole';
import { Organization } from './organization';

export class User {
  userID: number;
  login?: string;
  title?: string;
  function?: string;
  lastName: string;
  firstName: string;
  email?: string;
  organization?: Organization;
  activated?: boolean;
  accountLocked?: boolean;
  activatedValue?: string;
  userType?: string;
  userRoles?: UserRole[];
  application?: number;
  processPermissions?: string[];
  datapassAccount?: boolean;
  isDatapassAccountValue?: string;
  showMoreRoles?: boolean;
  showMoreProcesses?: boolean;
  shownRolesLimit?: number;
  shownProcessesLimit?: number;
  password?: string;
  newPassword?: string;
  phoneNumber?: string;
  mobilePhoneNumber?: string;
  homePage?: string;
  pagination?: string;
  includeXML?: boolean;
  creationTimestamp?: string;
  updateTimestamp?: string;
}
