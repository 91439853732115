import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { SubfoldersService } from '../../services/subfolders.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { SubfoldersFilters } from '../../models/subfoldersFilters';
import * as DatesConstants from 'src/app/features/subfolders/constants/dates.constants';
import {
  statusesList,
  SubfolderStatus,
} from '../../../../shared/models/subfolder';
import {
  Direction,
  SortAttribute,
} from '../../../../shared/models/sort-attribute';
import { SubFolderResult } from '../../models/subfolder-result';
import { Process } from 'src/app/shared/models/process';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { PreferencesService } from 'src/app/shared/service/preferences.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SubfoldersSharedService } from '../../services/subfolders-sharedService.service';
import { takeWhile } from 'rxjs/operators';
import { FetchProcessService } from '../../services/fetch-process.service';

export const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'l, LT',
  },
  display: {
    dateInput: 'l, LT',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-subfolders-filters',
  templateUrl: './subfolders-filters.component.html',
  styleUrls: ['./subfolders-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT }],
})
export class SubfoldersFiltersComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Output() selectedOrUnselectedAllSubfolders: EventEmitter<boolean> =
    new EventEmitter();
  @Input() dataSource: SubFolderResult;
  @Input() isAllSelectedActionBar: boolean;
  @Input() isSelectionCleaned: boolean;
  @Input() isAllSelected: boolean;
  @ViewChild('matRef') matRef: MatSelect;

  processList: Process[] = null;
  isLoading = false;
  isOpen = false;

  disableCheckBox: boolean;
  statuses: SubfolderStatus[] = statusesList;
  buttonColor = '#EEF1F9';
  isMoreFiltersClicked: boolean;
  processes = new FormControl();
  statusesFormControl = new FormControl();
  isTableView = false;
  isListView = true;
  urlView = 'list';
  selectedProcesses = {
    value: [],
    text: '',
  };
  value: string;
  urlProcesses: string;
  moreOrLessFilters = 'Plus de filtres';
  sortAttributes: SortAttribute[] = [
    { value: 'updateTimestamp', viewValue: 'Date de modification' },
    { value: 'transmissionTimestamp', viewValue: 'Date de transmission' },
  ];
  selected = this.sortAttributes[1].value;
  isFiltered = false;
  startSubmittedDate: Date;
  endSubmittedDate: Date;
  formStartDate: FormControl = new FormControl(null);
  formEndDate: FormControl = new FormControl(null);
  formatedStartDate: string;
  selectable = true;
  removable = true;
  formatedEndDate: string;
  isStartDateChosen = false;
  isEndDateChosen = false;
  isPeriodChosed: boolean;
  isFilterResetClicked = false;
  filters: SubfoldersFilters = new SubfoldersFilters();
  temporaryValue = false;
  isAdvancedFiltersShowed = false;
  isAllDeselected: boolean;
  decodedPermissions = [];
  isFirstLoad = true;
  isAlive = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private subfoldersService: SubfoldersService,
    private preferencesService: PreferencesService,
    private translate: TranslateService,
    private oauthService: OAuthService,
    private subfoldersSharedService: SubfoldersSharedService,
    private fetchProcessService: FetchProcessService
  ) {}

  ngOnInit(): void {
    this.subfoldersSharedService
      .getDisableSelectAllCheckBox()
      .subscribe((value) => (this.disableCheckBox = value));

    this.fetchProcessService.processes
      .pipe(takeWhile(() => this.isAlive))
      .subscribe((process) => {
        if (process && this.isLoading) {
          this.processList = process === null ? [] : process;
          this.processes.setValue(null);
          this.isLoading = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.route.queryParamMap.subscribe((params) => {
      this.filters = this.subfoldersService.getFilters(params);
      if (this.filters.status === null) {
        this.initiateDefaultStatus();
      }
      this.setFiltersFromParams(this.filters, params);
    });
    this.isMoreFiltersClicked = false;
    if (
      (this.filtersChanged(changes) || this.selectionHasBeenCleaned(changes)) &&
      this.isAllSelected
    ) {
      this.selectDeselectAllToggle();
    }
    if (changes.isSelectionCleaned && changes.isSelectionCleaned.currentValue) {
      if (this.isAllSelected) {
        this.selectDeselectAllToggle();
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

  openChanged(isOpen: boolean) {
    /**
     * Execute la requête de récupération des process une seul fois
     * lors du clique sur le filtre
     */
    if ([undefined, null].includes(this.processList)) {
      this.isOpen = isOpen;
      this.isLoading = isOpen;
      if (isOpen) {
        this.processes.reset();
        this.fetchProcessService.fetchProcessForSubfolderFilter();
      }
    }
  }

  public userHasRoleSubfolderManagement(): boolean {
    const claims: any = this.oauthService.getIdentityClaims();

    if (!claims) {
      return false;
    }

    return claims.groups.indexOf('GestionTD') !== -1;
  }

  private filtersChanged(changes: SimpleChanges): boolean {
    if (!this.isFirstLoad) {
      return (
        changes.dataSource &&
        (changes.dataSource.currentValue.filters.status ||
          changes.dataSource.currentValue.filters.searchedSubfolder ||
          changes.dataSource.currentValue.filters.process ||
          changes.dataSource.currentValue.filters.endSubmittedDate ||
          changes.dataSource.currentValue.filters.startSubmittedDate ||
          changes.dataSource.currentValue.filters.status !==
            changes.dataSource.previousValue.filters.status)
      );
    } else {
      this.isFirstLoad = false;
      return false;
    }
  }
  private selectionHasBeenCleaned(changes: SimpleChanges): boolean {
    return (
      changes.isSelectionCleaned && changes.isSelectionCleaned.currentValue
    );
  }
  public setFiltersFromParams(
    filters: SubfoldersFilters,
    params: ParamMap
  ): void {
    this.setFocusOnViewIcon(params.get('view'));
    this.setSelectionOnProcessesFromUrl(filters.processes);
    this.setSelectionStatusFromUrl(filters.status);
    this.setSelectionOfSubmittedDatesFromUrl(filters);
    this.isAdvancedFiltersShowed = this.isMoreFiltersClicked;
  }

  public selectDeselectAllToggle(): void {
    this.isAllSelected = !this.isAllSelected;
    this.selectedOrUnselectedAllSubfolders.emit(this.isAllSelected);
  }

  public initiateDefaultStatus(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        status: '2',
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }
  public onStatusSelected(event: any): void {
    const chosenStatus = event.value.id;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        status: chosenStatus,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  public onProcessSelected(event: any): void {
    let processValue: any;
    let encodedProcessValue;
    this.selectedProcesses = {
      value: event.value,
      text: event.source.triggerValue,
    };
    encodedProcessValue = this.encodeProcessPermissions(this.selectedProcesses);
    processValue =
      encodedProcessValue.length !== 0
        ? JSON.stringify(encodedProcessValue)
        : null;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        process: processValue,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  clear() {
    this.matRef.options.forEach((data: MatOption) => data.deselect());
  }

  public clearProcessFied(event: any) {
    event.stopPropagation();
    this.matRef.close();
    this.clear();
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        process: null,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  public clearStartSubmittedDateFied(event: any) {
    event.stopPropagation();
    this.formStartDate.setValue(null);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        startSubmittedDate: null,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  public clearEndSubmittedDateFied(event: any) {
    event.stopPropagation();
    this.formEndDate.setValue(null);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        endSubmittedDate: null,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  public encodeProcessPermissions(selectedPermission): string[] {
    let encodedPermissions = [];
    if (this.processList != null) {
      encodedPermissions = this.processList
        .filter((process) => selectedPermission.value.includes(process.name))
        .map((process) => process.code);
    }
    return encodedPermissions;
  }

  public decodeProcessPermissions(encodedProcesses: string[]): string[] {
    this.decodedPermissions = [];
    encodedProcesses.forEach((element) => {
      if (this.processList != null) {
        this.processList.forEach((process) => {
          if (element === process.code) {
            this.decodedPermissions.push(process.name);
          }
        });
      }
    });
    return this.decodedPermissions;
  }

  public onMoreFiltersClicked(): void {
    this.isMoreFiltersClicked = !this.isMoreFiltersClicked;
    this.isAdvancedFiltersShowed = this.isMoreFiltersClicked;
    this.changeMoreFiltersLabel();
  }

  public changeMoreFiltersLabel(): void {
    const bt = document.getElementById('RECH-FILTRES-AVANCES');
    this.moreOrLessFilters = this.isAdvancedFiltersShowed
      ? ' Moins de filtres'
      : ' Plus de filtres';
    const label = this.isAdvancedFiltersShowed
      ? ' Afficher moins de filtres'
      : ' Afficher plus de filtres';
    bt.setAttribute('aria-label', label);
  }

  public changeView(chosenView): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        view: chosenView,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
    this.setFocusOnViewIcon(chosenView);
  }

  public setFocusOnViewIcon(chosenView): void {
    if (!chosenView) {
      return;
    }
    if (chosenView === 'list') {
      this.isListView = true;
      this.isTableView = false;
    } else {
      this.isListView = false;
      this.isTableView = true;
    }
  }

  public resetFilters(): void {
    this.isFilterResetClicked = true;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        process: null,
        startSubmittedDate: null,
        endSubmittedDate: null,
        requester: null,
        number: null,
        pageNumber: null,
        pageSize: this.preferencesService.getUserPagination(),
        searchedSubfolder: null,
      },
      queryParamsHandling: 'merge',
    });
    this.resetDates();
  }

  public resetProcesses(): void {
    this.decodedPermissions = [];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        process: null,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  public resetDates(): void {
    this.isStartDateChosen = false;
    this.isEndDateChosen = false;
    this.formStartDate.setValue(null);
    this.formEndDate.setValue(null);
  }

  public getStartDate(type: string, event: MatDatepickerInputEvent<any>): void {
    if (event.value && event.value._isAMomentObject) {
      const insertedDate = event.value;
      const parsedStartDate = Date.parse(insertedDate.toString());
      this.formatedStartDate = this.formatConvertedDate(parsedStartDate);
      this.isStartDateChosen = true;
      this.isPeriodChosed = this.isStartDateChosen && this.isEndDateChosen;
      this.setStartDateToParams(parsedStartDate);
    } else if (type === 'change') {
      this.removeStartDate();
    }
  }

  public getEndDate(type: string, event: MatDatepickerInputEvent<any>): void {
    if (event.value && event.value._isAMomentObject) {
      const insertedDate = event.value;
      const parsedEndDate = Date.parse(insertedDate.toString());
      this.formatedEndDate = this.formatConvertedDate(parsedEndDate);
      this.isEndDateChosen = true;
      this.isPeriodChosed = this.isStartDateChosen && this.isEndDateChosen;
      this.setEndDateToParams(parsedEndDate);
    } else if (type === 'change') {
      this.removeEndDate();
    }
  }

  public formatConvertedDate(dateISO8601Format): string {
    const convertedDate = new Date(dateISO8601Format);
    return formatDate(
      new Date(convertedDate),
      DatesConstants.DATE_DAY_MONTH_YEAR_HOUR_MINUTE,
      DatesConstants.DATE_LOCAL_TIME
    );
  }

  public setStartDateToParams(parsedStartDate): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        startSubmittedDate: parsedStartDate,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  public setEndDateToParams(parsedEndDate): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        endSubmittedDate: parsedEndDate,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }
  public removeStartDate(): void {
    this.isStartDateChosen = false;
    this.isPeriodChosed = this.isStartDateChosen && this.isEndDateChosen;
    this.formatedStartDate = formatDate(
      Date.now(),
      DatesConstants.DATE_DAY_MONTH_YEAR,
      DatesConstants.DATE_LOCAL_TIME
    );
    this.formStartDate.setValue(null);
    this.removeStartDateFromParams();
  }

  public removeEndDate(): void {
    this.isEndDateChosen = false;
    this.isPeriodChosed = this.isStartDateChosen && this.isEndDateChosen;
    this.formatedEndDate = formatDate(
      Date.now(),
      DatesConstants.DATE_DAY_MONTH_YEAR,
      DatesConstants.DATE_LOCAL_TIME
    );
    this.formEndDate.setValue(null);
    this.removeEndDateFromParams();
  }

  public removeEndDateFromParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        endSubmittedDate: null,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  public removeStartDateFromParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        startSubmittedDate: null,
        pageNumber: 0,
      },
      queryParamsHandling: 'merge',
    });
  }

  public setSelectionOfSubmittedDatesFromUrl(filters: SubfoldersFilters): void {
    if (filters.startSubmittedDate) {
      this.formatedStartDate = this.formatConvertedDate(
        +filters.startSubmittedDate
      );
      this.isStartDateChosen = true;
      this.formStartDate.setValue(new Date(+filters.startSubmittedDate));
    } else {
      this.isStartDateChosen = false;
      this.formStartDate.setValue(null);
    }
    if (filters.endSubmittedDate) {
      this.formatedEndDate = this.formatConvertedDate(
        +filters.endSubmittedDate
      );
      this.isEndDateChosen = true;
      this.formEndDate.setValue(new Date(+filters.endSubmittedDate));
    } else {
      this.isEndDateChosen = false;
      this.formEndDate.setValue(null);
    }
    this.isPeriodChosed = this.isStartDateChosen && this.isEndDateChosen;
    if (filters.sortAttribute) {
      this.selected = filters.sortAttribute;
    }
  }

  public setSelectionOnProcessesFromUrl(processes): void {
    if (processes) {
      const parsedPermission = [];
      JSON.parse(processes).forEach((element) => {
        parsedPermission.push(element);
      });
      this.selectedProcesses.value =
        this.decodeProcessPermissions(parsedPermission);
      this.processes.setValue(this.selectedProcesses.value);
      this.isFiltered = true;
    } else {
      this.processes.setValue(null);
      this.isFiltered = false;
    }
  }

  public setSelectionStatusFromUrl(urlStatus): void {
    if (urlStatus) {
      const chosenStatus = statusesList.find(
        (status) => status.id === +urlStatus
      );
      this.statusesFormControl.setValue(chosenStatus);
    } else {
      this.statusesFormControl.setValue(null);
    }
  }

  public onSortAttributeSelected(value: string): void {
    this.selected = value;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        sortAttribute: value,
        direction: Direction.DESC,
      },
      queryParamsHandling: 'merge',
    });
  }
}
